import classNames from 'classnames';
import { IUploaderContext, Progress, UploaderContext } from 'context';
import { ACTIVE, COMPLETE, ERROR } from 'models/UploadQueue';
import React, { useContext } from 'react';

interface UploaderProgressProps {
  scheduleItemId: number;
}
const UploaderProgress: React.FC<UploaderProgressProps> = ({
  scheduleItemId,
}: UploaderProgressProps) => {
  const { progress } = useContext<IUploaderContext>(UploaderContext);
  if (!progress) return null;

  const { state, total, sent } = (progress[scheduleItemId] ?? {}) as Progress;

  if (!state) {
    return null;
  }

  const className = classNames('UploaderProgress', {
    'UploaderProgress--active': state === ACTIVE,
    'UploaderProgress--error': state === ERROR,
    'UploaderProgress--complete': state === COMPLETE,
  });

  const width = `${(100 * sent) / total}%`;

  return (
    <div className={className}>
      <div className="UploaderProgress__Bar" />
      <div className="UploaderProgress__Fill" style={{ width }} />
    </div>
  );
};

export default UploaderProgress;
