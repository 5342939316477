import LoggedInLayout from 'components/LoggedInLayout';
import LoggedOutLayout from 'components/LoggedOutLayout';
import { AppContext, IAppContext } from 'context';
import React, { useContext } from 'react';

const Routing = () => {
  const { isLoggedIn } = useContext<IAppContext>(AppContext);
  if (isLoggedIn) {
    return <LoggedInLayout />;
  } else {
    return <LoggedOutLayout />;
  }
};

export default Routing;
