import { getScheduleItems } from 'api';
import { addOrUpdateById, removeById } from 'api/util';
import { showErrorMsg, showInfoMsg } from 'components/core/AlertMessages';
import Button from 'components/core/Button';
import IconButton from 'components/core/IconButton';
import Page from 'components/core/Page';
import Schedule from 'components/Schedule';
import { AppContext, IAppContext } from 'context';
import emptyStateImagePath from 'images/schedule-empty-state.png';
import BandsintownModal from 'modals/BandsintownModal';
import ScheduleItemModal from 'modals/ScheduleItemModal';
import UploadScheduleCSVModal from 'modals/UploadScheduleCSVModal';
import { ScheduleItem, ValidScheduleItem } from 'models/ScheduleItem';
import React, { useContext, useEffect, useState } from 'react';
import { WithBlankSlate } from '../components/BlankSlate';
import { triggerDownload } from 'utils';

const SchedulePage: React.FC = () => {
  const [schedule, setSchedule] = useState<ScheduleItem[]>();
  const [editingItem, setEditingItem] = useState<ScheduleItem>();
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [bandsintownModalOpen, setBandsintownModalOpen] =
    useState<boolean>(false);
  const [uploadScheduleCSVModalOpen, setUploadScheduleCSVModalOpen] =
    useState<boolean>(false);

  const { currentEvent, user } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    if (currentEvent) {
      loadScheduleItems();
    }
  }, [currentEvent]);

  const onClickScheduleItem = (item: ScheduleItem) => {
    setEditingItem(item);
    setCreateNew(false);
  };

  const loadScheduleItems = () => {
    if (currentEvent?.locator) {
      const success = (scheduleItems: ScheduleItem[]) => {
        setSchedule(sortedSchedule(scheduleItems as ValidScheduleItem[]));
      };

      const failure = () => {
        showErrorMsg(
          'Failed to load schedule items. Please, try again or reload the page',
        );
      };
      getScheduleItems(currentEvent.locator, success, failure);
    }
  };

  const showBandsintownModal = () => {
    setBandsintownModalOpen(true);
  };

  const closeBandsintownModal = (reload: boolean) => {
    setBandsintownModalOpen(false);
    if (reload) {
      loadScheduleItems();
    }
  };

  const showUploadScheduleCSVModal = () => {
    setUploadScheduleCSVModalOpen(true);
  };

  const closeUploadScheduleCSVModal = (reload?: boolean) => {
    setUploadScheduleCSVModalOpen(false);
    if (reload) {
      loadScheduleItems();
    }
  };

  const showAddScheduleItem = () => {
    setEditingItem({});
    setCreateNew(true);
  };

  const cancelAddScheduleItem = () => {
    setEditingItem(undefined);
  };

  const scheduleItemAdded = (item: ScheduleItem) => {
    setSchedule(sortedSchedule(addOrUpdateById(schedule, item)));
  };

  const scheduleItemRemoved = (item: ScheduleItem) => {
    setSchedule(removeById(schedule, item));
    showInfoMsg(`Deleted "${item.location}"`);
  };

  const sortedSchedule = (schedule: ValidScheduleItem[]) => {
    schedule.sort((a: ValidScheduleItem, b: ValidScheduleItem) => {
      let diff =
        new Date(a.startsAt).valueOf() - new Date(b.startsAt).valueOf();
      if (diff === 0) {
        diff = a.location!.localeCompare(b.location ?? '');
      }
      return diff;
    });
    return schedule;
  };

  const downloadCSV = (e: React.MouseEvent) => {
    e.preventDefault();
    triggerDownload(
      `${process.env.API_HOST}/events/${currentEvent?.locator}/schedule.csv`,
    );
  };

  return (
    <Page className="SchedulePage">
      <h1 className="Page__Title">Schedule</h1>

      <WithBlankSlate
        title="Your schedule is empty."
        collection={schedule}
        imagePath={emptyStateImagePath}
        extraContent={
          <>
            <div className="SchedulePage__ImportButtons">
              <IconButton
                icon="sync"
                caption="BandsInTown Sync"
                onClick={showBandsintownModal}
              />
              {user?.superUser && (
                <IconButton
                  icon="download"
                  caption="Upload CSV"
                  onClick={showUploadScheduleCSVModal}
                />
              )}
            </div>

            <p>or</p>

            <Button
              primary
              caption="Add a schedule item"
              onClick={showAddScheduleItem}
            />
          </>
        }
      >
        <div className="Page__Header">
          <IconButton
            className="SchedulePage__SyncButton"
            icon="sync"
            caption="Bandsintown sync"
            onClick={showBandsintownModal}
          />
          <IconButton
            icon="download"
            caption="Download CSV"
            href="#"
            onClick={downloadCSV}
            disabled={(schedule?.length ?? 0) > 0}
          />
          {user?.superUser && (
            <IconButton
              icon="upload"
              caption="Upload CSV"
              onClick={showUploadScheduleCSVModal}
            />
          )}
          <span className="FlexSpacer" />
          <Button
            primary
            caption="Add a schedule item"
            onClick={showAddScheduleItem}
          />
        </div>

        <Schedule
          schedule={schedule as ScheduleItem[]}
          onClick={onClickScheduleItem}
          itemAdded={scheduleItemAdded}
        />
      </WithBlankSlate>

      <ScheduleItemModal
        item={editingItem}
        createNew={createNew}
        onClose={cancelAddScheduleItem}
        itemAdded={scheduleItemAdded}
        itemRemoved={scheduleItemRemoved}
      />

      <BandsintownModal
        open={bandsintownModalOpen}
        locator={currentEvent?.locator}
        onClose={closeBandsintownModal}
      />

      <UploadScheduleCSVModal
        open={uploadScheduleCSVModalOpen}
        locator={currentEvent?.locator}
        onClose={closeUploadScheduleCSVModal}
      />
    </Page>
  );
};

export default SchedulePage;
