import classNames from 'classnames';
import bellOnIconPath from 'images/icons/bell-on.svg';
import bellIconPath from 'images/icons/bell.svg';
import createNewIconPath from 'images/icons/create-new-icon.svg';
import downloadIconPath from 'images/icons/download.svg';
import syncIconPath from 'images/icons/sync.svg';
import uploadIconPath from 'images/icons/upload.svg';
import React from 'react';
import './style.scss';

const pathForIcon = (icon) => {
  switch (icon) {
    case 'bell':
      return bellIconPath;
    case 'bell-on':
      return bellOnIconPath;
    case 'create-new':
      return createNewIconPath;
    case 'sync':
      return syncIconPath;
    case 'download':
      return downloadIconPath;
    case 'upload':
      return uploadIconPath;
    default:
      return '';
  }
};

interface IconButtonProps {
  caption?: string;
  className?: string;
  disabled?: boolean;
  highlight?: boolean;
  href?: string;
  icon: any;
  onClick?: (e: React.MouseEvent) => void;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  caption,
  highlight = false,
  disabled = false,
  onClick,
  href,
  className,
}: IconButtonProps) => {
  const clsname = classNames(className, 'IconButton', {
    'IconButton--highlight': highlight,
  });
  const iconPath = pathForIcon(icon);
  if (!href || href == '') {
    return (
      <button disabled={disabled} className={clsname} onClick={onClick}>
        {iconPath && (
          <img
            className="IconButton__Icon"
            src={iconPath}
            role="presentation"
          />
        )}
        {caption}
      </button>
    );
  }
  return (
    <a href={href} className={clsname} onClick={onClick}>
      {iconPath && (
        <img className="IconButton__Icon" src={iconPath} role="presentation" />
      )}
      {caption}
    </a>
  );
};

export default IconButton;
