import React, { useContext, useState } from 'react';
import { uploadScheduleCSV } from 'api';
import { showErrorMsg, showInfoMsg } from 'components/core/AlertMessages';
import Button from 'components/core/Button';
import FileInput from 'components/core/FileInput';
import Form from 'components/core/Form';
import Modal, { ModalButtons } from 'components/core/Modal';
import { AppContext, IAppContext } from 'context';
import { triggerDownload } from 'utils';

interface UploadScheduleCSVModalProps {
  open?: boolean;
  locator?: string;
  onClose?: (reload?: boolean) => void;
}
const UploadScheduleCSVModal: React.FC<UploadScheduleCSVModalProps> = ({
  locator,
  open,
  onClose,
}: UploadScheduleCSVModalProps) => {
  const [errors, setErrors] = useState<any>([]);
  const [scheduleCSV, setScheduleCSV] = useState<any>();
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);

  const { currentEvent } = useContext<IAppContext>(AppContext);

  const save = async () => {
    const response = await uploadScheduleCSV(
      currentEvent?.locator,
      scheduleCSV,
    );

    if (response.success()) {
      showInfoMsg('CSV successfully uploaded!');
      closedModal();
    } else {
      showErrorMsg('There was an error uploading your CSV', 5000);
      setErrors(response.json.errors);
      setSaveEnabled(false);
    }
  };

  const closedModal = () => {
    setErrors([]);
    setScheduleCSV(null);
    setSaveEnabled(false);
    onClose?.(true);
  };

  const downloadTemplate = (e: React.MouseEvent) => {
    e.preventDefault();
    triggerDownload(
      `${process.env.API_HOST}/events/${currentEvent?.locator}/schedule.csv?template=true`,
    );
  };

  const updatedCSV = (file) => {
    setScheduleCSV(file);
    setSaveEnabled(true);
  };

  const csvPreview = () => {
    if (scheduleCSV) {
      return (
        <span className="Input__FileInputFileName">{scheduleCSV.name}</span>
      );
    } else {
      return false;
    }
  };

  if (!currentEvent) return null;

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Modal open={open} title="Upload Schedule CSV" onClose={closedModal}>
      <Form
        className="UploadScheduleCSVForm UploadScheduleCSVModal"
        errors={errors}
      >
        {hasErrors ? (
          <p className="UploadScheduleCSVModal__Instructions">
            <span className="ErrorMessage__Message">
              Your CSV headers must match our template exactly.
            </span>
          </p>
        ) : null}

        {!hasErrors ? (
          <p className="UploadScheduleCSVModal__Instructions">
            Be sure your CSV matches the template below.
          </p>
        ) : null}

        <p className="UploadScheduleCSVModal__Instructions">
          Download{' '}
          <a href="" onClick={downloadTemplate}>
            our CSV template here
          </a>
          .
        </p>

        <FileInput
          name="csv"
          caption="Drag a CSV file here or"
          buttonCaption="Select file"
          accept="text/csv"
          preview={csvPreview()}
          encodeBase64={false}
          onChange={updatedCSV}
          outline={true}
          inlineCaption={true}
        />

        <ModalButtons>
          <Button
            caption="Upload"
            enabled={saveEnabled}
            onClick={save}
            primary
          />
        </ModalButtons>
      </Form>
    </Modal>
  );
};

export default UploadScheduleCSVModal;
