import DropTarget from 'components/DropTarget';
import { IUploaderContext, UploaderContext } from 'context';
import React, { useContext } from 'react';

interface UploadHandlerDropTargetProps {
  className?: string;
  locator?: string;
  scheduleItemId: number;
  multiple?: boolean;
  children?: JSX.Element | JSX.Element[];
}
const UploadHandlerDropTarget: React.FC<UploadHandlerDropTargetProps> = ({
  className = 'UploadHandlerDropTarget',
  locator,
  children,
  scheduleItemId,
  ...props
}: UploadHandlerDropTargetProps) => {
  const { uploadFile } = useContext<IUploaderContext>(UploaderContext);
  return (
    <DropTarget
      className={className}
      uploadFile={(file) => uploadFile(locator, scheduleItemId, file)}
      {...props}
    >
      {children}
    </DropTarget>
  );
};

export default UploadHandlerDropTarget;
