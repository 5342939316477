import {
  getScheduleItem,
  getTicketPurchase,
  getTicketPurchaseActivity,
} from 'api';
import { formatDate } from 'api/util';
import Activity from 'components/Activity';
import { Breadcrumb } from 'components/core/Breadcrumb';
import Page from 'components/core/Page';
import PageHeader from 'components/core/PageHeader';
import PageLoadingIndicator from 'components/core/PageLoadingIndicator';
import Note from 'components/Note';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext, IAppContext } from 'context';
import { ScheduleItem } from 'models/ScheduleItem';
import TicketPurchase, { TicketPurchaseActivity } from 'models/TicketPurchase';
import TicketNoteForm from 'components/TicketNoteForm';

const TicketNotesPage: React.FC = () => {
  const [scheduleItem, setScheduleItem] = useState<ScheduleItem>();
  const [ticketPurchase, setTicketPurchase] = useState<TicketPurchase>();
  const [activities, setActivities] = useState<TicketPurchaseActivity[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const { locator, scheduleItemId, ticketPurchaseId } = useParams();

  const ticketsPath = `/event/${locator}/ticketPurchases`;
  const siPath = `/event/${locator}/ticketPurchases/${scheduleItemId}`;
  const purchasePath = `${siPath}/${ticketPurchaseId}`;

  const { currentEvent } = useContext<IAppContext>(AppContext);
  const loadTicketPurchase = async () => {
    if (!currentEvent) return;

    const [scheduleItem, activity, ticketPurchase] = await Promise.all([
      getScheduleItem(locator ?? '', scheduleItemId ?? 0),
      getTicketPurchaseActivity(
        currentEvent.locator,
        scheduleItemId,
        ticketPurchaseId,
      ),
      getTicketPurchase(currentEvent.locator, scheduleItemId, ticketPurchaseId),
    ]);

    setTicketPurchase(ticketPurchase as TicketPurchase);
    setScheduleItem(scheduleItem as ScheduleItem);
    setLoading(false);
    setActivities(activity as TicketPurchaseActivity[]);
  };

  useEffect(() => {
    if (!currentEvent) return;

    loadTicketPurchase();
  }, [currentEvent, locator, scheduleItemId, ticketPurchaseId]);

  const currentScheduleItem = scheduleItem;
  if (!currentScheduleItem) return <PageLoadingIndicator />;

  const scheduleItemDesc = [
    currentScheduleItem?.location,
    formatDate(currentScheduleItem?.startsAt),
  ].join(' - ');
  const ticketName = [ticketPurchase?.firstName, ticketPurchase?.lastName].join(
    ' ',
  );
  const notes = ticketPurchase?.notes.reverse();

  return (
    <>
      {loading || !ticketPurchase ? (
        <PageLoadingIndicator />
      ) : (
        <Page>
          <PageHeader>
            <Breadcrumb to={ticketsPath} title="Ticket data" />
            <Breadcrumb to={siPath} title={scheduleItemDesc} />
            <Link to={purchasePath}>{ticketName}</Link>
          </PageHeader>

          <section className="TicketNotesPage__BodySection">
            <TicketNoteForm noteAdded={loadTicketPurchase} />
          </section>

          <section className="TicketNotesPage__BodySection">
            <h3 className="TicketNotesPage__BodySectionHeader">Notes</h3>

            {notes?.map((n) => <Note note={n} key={n.id} />)}
          </section>

          <section className="TicketNotesPage__BodySection">
            <h3 className="TicketNotesPage__BodySectionHeader">Activity</h3>
            {activities?.map((act) => (
              <Activity
                activity={act}
                key={`${act.timestamp.valueOf()}-${act.performedBy.id}`}
              />
            ))}
          </section>
        </Page>
      )}
    </>
  );
};

export default TicketNotesPage;
