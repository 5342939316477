import {
  getDailyDownloadsMetrics,
  getDailyFanMetrics,
  getDailyProfitMetrics,
  getDailySalesMetrics,
} from 'api';
import {
  moneyFormatter,
  numberFormatter,
  parseDate,
  shortDateEpochFormatter,
} from 'api/util';
import DashboardCard from 'components/DashboardCard';
import DashboardEmptyCard from 'components/DashboardEmptyCard';
import { AppContext, IAppContext } from 'context';
import { DashboardQueryProps } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import './style.scss';

const simpleMoneyFormatter = (value: number) => (value / 100).toLocaleString();

type FetchFn = (
  locator: string | undefined,
  query: string | undefined,
  success: (result: { total: any; values: any }) => void,
  failure?: () => void,
) => any;

export interface ChartProps extends DashboardQueryProps {
  data?: any;
  dataKey: string;
  title: string;
  // total: string;
  formatter: (value: number) => string;
  axisFormatter?: (value: number) => string;
}

export const FansChart: React.FC<DashboardQueryProps> = ({
  locator,
  query,
  dateRange,
}: DashboardQueryProps) => (
  <DailyChart
    title="Fans"
    fetchFn={getDailyFanMetrics}
    jsonKey="dailyCount"
    dataKey="Fans"
    locator={locator}
    query={query}
    dateRange={dateRange}
    formatter={numberFormatter}
  />
);

export const DownloadsChart: React.FC<DashboardQueryProps> = ({
  locator,
  query,
  dateRange,
}: DashboardQueryProps) => (
  <DailyChart
    title="Downloads"
    fetchFn={getDailyDownloadsMetrics}
    jsonKey="dailyCount"
    dataKey="Downloads"
    locator={locator}
    query={query}
    dateRange={dateRange}
    formatter={numberFormatter}
  />
);

export const SalesChart: React.FC<DashboardQueryProps> = ({
  locator,
  query,
  dateRange,
}: DashboardQueryProps) => (
  <DailyChart
    title="Sales"
    fetchFn={getDailySalesMetrics}
    jsonKey="amount"
    dataKey="Sales"
    locator={locator}
    query={query}
    dateRange={dateRange}
    formatter={moneyFormatter}
    axisFormatter={simpleMoneyFormatter}
  />
);

export const ProfitChart: React.FC<DashboardQueryProps> = ({
  locator,
  query,
  dateRange,
}: DashboardQueryProps) => (
  <DailyChart
    title="Profit"
    fetchFn={getDailyProfitMetrics}
    jsonKey="amount"
    dataKey="Sales"
    locator={locator}
    query={query}
    dateRange={dateRange}
    formatter={moneyFormatter}
    axisFormatter={simpleMoneyFormatter}
  />
);

interface DailyChartProps extends ChartProps {
  fetchFn: FetchFn;
  jsonKey: string;
  total?: any;
  values?: any;
}
const DailyChart: React.FC<DailyChartProps> = ({
  locator,
  query = '',
  fetchFn,
  dateRange,
  dataKey,
  jsonKey,
  title,
  total,
  values,
  formatter,
  axisFormatter,
}: DailyChartProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [myTotal, setMyTotal] = useState<any>(total);
  const [myValues, setMyValues] = useState<any>(values);

  const { currentEvent } = useContext<IAppContext>(AppContext);
  useEffect(() => {
    setLoading(true);
    fetchFn(locator, query, parseResponse);
  }, [query, currentEvent]);

  const parseResponse = ({ total, values }) => {
    const newValues = values
      .map((i) => ({
        dt: parseDate(i.period).getTime(),
        [dataKey]: i[jsonKey],
      }))
      .sort((a, b) => a.dt - b.dt);
    setMyTotal(total);
    setMyValues(newValues);
    setLoading(false);
  };

  return (
    <DashboardChart
      title={title}
      total={myTotal}
      dateRange={dateRange}
      data={myValues}
      dataKey={dataKey}
      loading={loading}
      formatter={formatter}
      axisFormatter={axisFormatter}
    />
  );
};

interface DashboardChartProps extends ChartProps {
  total?: any;
  loading?: boolean;
}
const DashboardChart: React.FC<DashboardChartProps> = ({
  title,
  total,
  dateRange,
  data,
  dataKey,
  loading,
  formatter,
  axisFormatter = formatter,
}: DashboardChartProps) => {
  const chart = (
    <ResponsiveContainer width="100%" height={320}>
      <LineChart
        className="DashboardChart__Chart"
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        syncId="DashboardCharts"
      >
        <CartesianGrid stroke="currentColor" />
        <XAxis
          dataKey="dt"
          domain={['dataMin', 'dataMax']}
          interval="preserveStartEnd"
          axisLine={false}
          tickFormatter={shortDateEpochFormatter}
          tickMargin={12}
          tickLine={false}
        />
        <YAxis
          allowDecimals={false}
          axisLine={false}
          tickMargin={5}
          tickLine={false}
          tickFormatter={axisFormatter}
        />
        <Line
          dot={false}
          type="linear"
          dataKey={dataKey}
          stroke="#24bbec"
          strokeWidth="2"
          isAnimationActive={false}
        />
        <Tooltip
          isAnimationActive={false}
          labelFormatter={shortDateEpochFormatter}
          cursor={false}
          separator=": "
          formatter={formatter}
          contentStyle={{
            borderRadius: 6,
            borderColor: '#e1e5e6',
            padding: '5px 10px',
          }}
          labelStyle={{
            fontSize: 10,
            textTransform: 'uppercase',
            letterSpacing: 0.1,
          }}
          itemStyle={{
            fontSize: 14,
            color: '#444b4c',
            padding: 0,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );

  const empty = (
    <>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart
          className="DashboardChart__Chart DashboardChart__Chart--empty"
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <CartesianGrid stroke="currentColor" />
          <XAxis
            dataKey="dt"
            domain={['dataMin', 'dataMax']}
            interval="preserveStartEnd"
            axisLine={false}
            tickFormatter={shortDateEpochFormatter}
            tickMargin={12}
            tickLine={false}
          />
          <YAxis axisLine={false} tickMargin={5} tickLine={false} />
        </LineChart>
      </ResponsiveContainer>
      <DashboardEmptyCard className="DashboardEmptyCard--chart" />
    </>
  );

  const hasData = data?.length;

  return (
    <DashboardCard
      className="DashboardChart"
      loading={loading}
      title={title}
      dateRange={dateRange}
      total={hasData ? formatter(total) : '0'}
    >
      {hasData ? chart : empty}
    </DashboardCard>
  );
};
