import { lowerCaseFirstLetter } from 'api/util';
import classNames from 'classnames';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import FullModal from 'components/core/FullModal';
import ImportPreviewResponse from 'models/ImportPreviewResponse';
import TicketPurchase from 'models/TicketPurchase';
import React from 'react';

interface Props {
  importPreview: ImportPreviewResponse;
  onUpdate: (updated: ImportPreviewResponse) => void;
  onCancel: () => void;
  onConfirm: () => void;
}

const FIELDS = {
  FirstName: 'First name',
  LastName: 'Last name',
  Email: 'Email',
  Quantity: 'Quantity',
  SeatLocation: 'Seat location',
  Section: 'Section',
  Row: 'Row',
  Seat: 'Seat',
  ReceiptID: 'Receipt ID',
  OriginalOrderNumber: 'Original order number',
  Phone: 'Phone',
  Package: 'Package',
  ImportedNotes: 'Notes',
  CustomField: 'Custom field',
};

interface Header {
  headerName: string;
  mappedTo: string;
  isNew: boolean;
}

interface HeaderOption {
  headerName: string;
  isAvailable: boolean;
}

const HeaderMappings: React.FC<Props> = ({
  importPreview,
  onUpdate,
  onCancel,
  onConfirm,
}) => {
  const { headerMappings, newHeaders } = importPreview;
  const examples = examplesForColumns(importPreview);
  const takenHeaders = Object.values(headerMappings).filter(
    (h) => h !== 'CustomField',
  );

  const options: HeaderOption[] = Object.keys(FIELDS).map((header) => ({
    headerName: header,
    isAvailable: !takenHeaders.includes(header),
  }));

  const headers: Header[] = Object.keys(headerMappings).map((headerName) => {
    return {
      headerName,
      mappedTo: headerMappings[headerName],
      isNew: newHeaders.includes(headerName),
    };
  });

  const changedMapping = async (headerName: string, newValue: string) => {
    importPreview.headerMappings[headerName] = newValue;
    onUpdate(importPreview);
  };

  const mappingCols = headers.map((header) => {
    return (
      <HeaderMapping
        header={header}
        examples={examples[header.headerName]}
        key={header.headerName}
        options={options}
        onChange={changedMapping}
      />
    );
  });

  return (
    <FullModal open fillWidth>
      <h1>CSV Import</h1>
      <p>Match CSV headers with your ticket package data.</p>

      <div className="HeaderMappings__Wrapper">
        <div className="HeaderMappings__Container">
          <div className="HeaderMappings">{mappingCols}</div>
        </div>
      </div>

      <ButtonPanel align="center" className="HeaderMapping__ButtonPanel">
        <Button caption="Cancel import" onClick={onCancel} />
        <Button primary caption="Next" onClick={onConfirm} />
      </ButtonPanel>
    </FullModal>
  );
};

interface HeaderMappingProps {
  header: Header;
  examples: string[];
  options: HeaderOption[];
  onChange: (headerName: string, newValue: string) => void;
}

const HeaderMapping: React.FC<HeaderMappingProps> = ({
  header,
  examples,
  options,
  onChange,
}) => {
  const className = classNames('HeaderMapping', {
    'HeaderMapping--isNew': header.isNew,
  });

  const opts = options.map((o) => (
    <option
      value={o.headerName}
      key={o.headerName}
      disabled={header.mappedTo != o.headerName && !o.isAvailable}
    >
      {o.headerName}
    </option>
  ));

  return (
    <div className={className}>
      <div className="HeaderMapping__Header">
        {header.isNew ? 'New' : 'Existing'}
      </div>
      <div className="HeaderMapping__Body">
        <div className="HeaderMapping__Name">{header.headerName}</div>
        <div className="HeaderMapping__Options">
          {' '}
          <select
            onChange={(e) => onChange(header.headerName, e.target.value)}
            value={header.mappedTo}
          >
            {opts}
          </select>
        </div>

        <div className="HeaderMapping__Examples">
          {examples.map((e, idx) => (
            <div className="HeaderMapping__Example" key={idx}>
              {e}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const examplesForColumns = (importPreview: ImportPreviewResponse) => {
  const examples: { [key: string]: string[] } = {};
  const rows = importPreview.updates.map((u) => u.ticketPurchase);
  const purchases = rows.filter((r): r is TicketPurchase => !!r);

  Object.keys(importPreview.headerMappings).forEach((header) => {
    examples[header] = [];

    const mappedTo = importPreview.headerMappings[header];
    const key =
      mappedTo === 'CustomField' ? header : lowerCaseFirstLetter(mappedTo);

    for (let i = 0; examples[header].length < 4 && i < purchases.length; i++) {
      const purchase = purchases[i];
      let example = purchase[key] || purchase.customFields[key] || '';

      if (example.toString().trim() !== '') {
        examples[header].push(example);
      }
    }
  });

  return examples;
};

export default HeaderMappings;
