import { updateScheduleItem } from 'api';
import { formatDate } from 'api/util';
import classNames from 'classnames';
import ToggleSwitch from 'components/core/ToggleSwitch';
import SortableTable from 'components/SortableTable';
import { AppContext, IAppContext } from 'context';
import bandsintownLogo from 'images/icons/bandsintown.png';
import { ScheduleItem } from 'models/ScheduleItem';
import React, { useContext } from 'react';

interface ScheduleRowProps {
  item: ScheduleItem;
  locator: string;
  onClick?: (item: ScheduleItem) => void;
  itemAdded: (item: ScheduleItem) => void;
}
const ScheduleRow: React.FC<ScheduleRowProps> = ({
  item,
  locator,
  onClick,
  itemAdded,
}: ScheduleRowProps) => {
  const isBandsintown = !!item.bandsintownEventId;

  const toggleVisible = () => {
    item.hidden = !item.hidden;

    updateScheduleItem(locator, item, () => itemAdded(item));
  };

  const className = classNames('Schedule__ScheduleRow', {
    'Schedule__ScheduleRow--hidden': item.hidden,
  });

  return (
    <tr className={className} onClick={() => onClick?.(item)}>
      <td className="Schedule__Date">
        {formatDate(item.startsAt)}
        {isBandsintown && (
          <img className="Schedule__BandsintownLogo" src={bandsintownLogo} />
        )}
      </td>
      <td className="Schedule__Time">{item.time}</td>
      <td>{item.location}</td>
      <td>{item.description}</td>
      <td className="Schedule__Hidden" onClick={(e) => e.stopPropagation()}>
        <ToggleSwitch
          checked={!item.hidden}
          testid="item-hidden"
          onChange={toggleVisible}
        />
      </td>
      <td className="right">{item.postCount}</td>
    </tr>
  );
};

interface ScheduleProps {
  schedule: ScheduleItem[];
  onClick?: (e: any) => void;
  itemAdded: (item: ScheduleItem) => void;
}
const Schedule: React.FC<ScheduleProps> = ({
  schedule,
  onClick,
  itemAdded,
}: ScheduleProps) => {
  const { currentEvent } = useContext<IAppContext>(AppContext);

  const mapper = (scheduleItem: ScheduleItem) => (
    <ScheduleRow
      item={scheduleItem}
      key={scheduleItem.id}
      onClick={onClick}
      locator={currentEvent?.locator ?? ''}
      itemAdded={itemAdded}
    />
  );

  return (
    <SortableTable<ScheduleItem>
      headers={[
        'Date',
        'Time',
        'Location',
        'Additional info',
        'Hide/show',
        'Photos',
      ]}
      keys={[
        'startsAt',
        'time',
        'location',
        'description',
        'hidden',
        'postCount',
      ]}
      headerClasses={{ Photos: 'right' }}
      items={schedule}
      className="Schedule Table--clickable"
      mapper={mapper}
    />
  );
};

export default Schedule;
