import { atLeast } from 'context/roles';
import rollbar from 'errorReporting';
import {
  clearPersistedUser,
  loadPersistedUser,
  persistUser,
  setToken,
} from 'localStorage';
import { Event, User } from 'models';
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { impersonate as apiImpersonate, logOut } from '../api';

export interface IAppState {
  allEvents: Event[];
  currentEvent?: Event;
  currentRole: string;
  firstRun: boolean;
  isLoggedIn: boolean;
  user?: User;
}

const defaultState: IAppState = {
  user: undefined,
  isLoggedIn: false,
  currentRole: '',
  currentEvent: undefined,
  allEvents: [],
  firstRun: false,
};

export interface IAppContext extends IAppState {
  addNewEvent: (event: Event) => void;
  clearUser: () => void;
  hasRole: (role: string) => boolean;
  impersonate: (userid?: number, locator?: string) => void;
  setCurrentEvent: (currentEvent: Event) => void;
  setEvents: (allEvents: Event[]) => void;
  setUser: (user: User, impersonating?: boolean, redirectTo?: string) => void;
  updateEvent: (event: Event) => void;
}

const defaultContext = {
  ...defaultState,
  addNewEvent: () => undefined,
  clearUser: () => undefined,
  hasRole: () => false,
  impersonate: () => undefined,
  setCurrentEvent: () => undefined,
  setEvents: () => undefined,
  setUser: () => undefined,
  updateEvent: () => undefined,
};

const persistedUser = loadPersistedUser();

export const AppContext = React.createContext<IAppContext>(defaultContext);
AppContext.displayName = 'Application State';

export function AppProvider({
  user,
  isLoggedIn,
  currentRole,
  currentEvent,
  allEvents,
  firstRun,
  children,
}: Partial<IAppState> & {
  children: JSX.Element | JSX.Element[] | null;
}) {
  const _user: User = user ?? persistedUser?.user;
  const [state, setState] = useState<IAppState>({
    user: _user,
    isLoggedIn: !!(isLoggedIn ?? _user ?? defaultState.isLoggedIn),
    currentRole: currentRole ?? defaultState.currentRole,
    currentEvent: currentEvent ?? defaultState.currentEvent,
    allEvents: allEvents ?? defaultState.allEvents,
    firstRun: firstRun ?? defaultState.firstRun,
  });

  const navigate = useNavigate();
  useEffect(() => {
    onLoad();
  }, [user]);

  const [redirectTo, setRedirectTo] = useState<string | undefined>();

  const setUser = (user: User, impersonating = false, redirectUrl?: string) => {
    if (impersonating) {
      user.impersonating = true;
      user.superUser = true;
    }

    setRedirectTo(redirectUrl);

    persistUser(user);

    setState({
      ...state,
      user,
      isLoggedIn: true,
      currentEvent: undefined,
      allEvents: [],
    });
  };

  const clearUser = () => {
    const success = (_response: any) => {
      clearPersistedUser();
      setState({
        ...state,
        user: undefined,
        isLoggedIn: false,
      });
    };

    const failure = (_error?: any) =>
      window.alert(
        'Failed to log out, please reload the page to solve the problem',
      );

    logOut(success, failure);
  };

  const setEvents = (allEvents: Event[]) =>
    setState({
      ...state,
      allEvents: [...allEvents],
      firstRun: allEvents.length === 0,
    });

  const setCurrentEvent = (currentEvent: any) => {
    setState({ ...state, currentEvent, currentRole: currentEvent.roleType });
  };

  const updateEvent = (event: Event) => {
    setState({
      ...state,
      allEvents: state.allEvents.map((e: any) =>
        e.locator === event.locator ? event : e,
      ),
      currentEvent: event,
      // this.state.currentEvent?.locator === event.locator
      //   ? event
      //   : this.state.currentEvent,
    });
  };

  const addNewEvent = (event: Event) => {
    setState({
      ...state,
      allEvents: state.allEvents?.concat(event),
      firstRun: false,
    });
    setRedirectTo(event.locator);
  };

  const hasRole = (role: string) =>
    !!(state.currentRole && atLeast(role, state.currentRole));

  const impersonate = (userId, locator) => {
    const impersonating = userId !== 0;

    if (userId === 0) {
      locator = '/'; // when resetting, go to default URL
    }
    const success = (response) => {
      setToken(response.token);
      setUser(response.user, impersonating, locator);
    };

    apiImpersonate(userId, success);
  };

  const onLoad = () => {
    const user = state.user || undefined;
    rollbar.configure({
      payload: {
        person: {
          id: user?.id,
          username: `${user?.firstName} ${user?.lastName}`,
          email: user?.email,
        },
      },
    });
  };

  useEffect(() => {
    if (redirectTo) {
      const url = `/event/${redirectTo}`;
      setRedirectTo(undefined);
      navigate(url);
    }
  }, [redirectTo]);

  const contextValue = useMemo(() => {
    return {
      ...state,
      addNewEvent,
      clearUser,
      hasRole,
      impersonate,
      setCurrentEvent,
      setEvents,
      setUser,
      updateEvent,
    };
  }, [state]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}
