import { UploaderContext } from 'context';
import { useContext, useEffect } from 'react';

export const useUploaderUnloadWarning = () => {
  const { progress } = useContext(UploaderContext);

  const onBeforeUnload = (e: Event) => {
    const scheduleItemsWithUploads = Object.entries(progress ?? {}).length;
    if (scheduleItemsWithUploads > 0) {
      e.preventDefault();

      // Setting the return value will cause the browser to display a warning prompt.
      // This property is deprecated, but still necessary in some browsers.
      return ((e.returnValue as any) = '');
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });
};

export default useUploaderUnloadWarning;
