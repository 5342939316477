import { showErrorMsg } from 'components/core/AlertMessages';
import rollbar from 'errorReporting';
import { BillingAttributes, Photographer, ScheduleItem } from 'models';
import { TicketPackage } from 'models/TicketPackage';
import { getToken, clearToken } from 'localStorage';

declare const process: {
  env: { API_HOST: string; GIT_VERSION: string; IMAGE_HOST: string };
};
class ApiResponse {
  response: any;
  json: any;

  constructor(response) {
    this.response = response;
    this.json = null;
  }

  parse = async () => {
    const contentType = this.response.headers.get('Content-Type');
    if (contentType?.startsWith('application/json')) {
      this.json = await this.response.json();
    }
  };

  success = () => this.response.status >= 200 && this.response.status < 300;
}

export const getApiReponse = async (
  path: string,
  options: any = { body: '', method: 'GET' },
) => {
  const reqBody = options.body ? JSON.stringify(options.body) : null;
  const headers = {
    'Content-Type': 'application/json',
    'Client-Rev': process.env.GIT_VERSION,
    Authorization: `Bearer ${getToken()}`,
  };
  return await apiFetch(options.method, path, headers, reqBody);
};

const apiFetch = async (
  method: string,
  path?: string,
  headers?: any,
  body?: any,
) => {
  const requestPath = process.env.API_HOST + path;

  const resp = await fetch(requestPath, {
    method,
    headers,
    body,
    credentials: 'include',
  });
  const result = new ApiResponse(resp);
  await result.parse();
  return result;
};

// Promised wrapper around callApi
const callApiPromised = (path, options = {}) => {
  return new Promise((resolve, reject) => {
    callApi(path, resolve, reject, options);
  });
};

const callApi = async (
  path,
  success,
  failure = defaultFailure,
  options = {},
) => {
  try {
    const resp = await getApiReponse(path, options);
    if (resp.success()) {
      success(resp.json);
    } else {
      failure(resp.json || {});
    }
  } catch (e) {
    rollbar.error(e);
    failure({});
  }
};

const defaultFailure = (error?) => {
  const msg =
    error.reason || 'There was an error. Please try again or reload the page.';

  showErrorMsg(msg);
};

export const logIn = (
  email: string,
  password: string,
  success?: any,
  failure?: any,
) => {
  callApi('/login', (json) => success(json), failure, {
    body: { email, password },
    method: 'POST',
  });
};

export const impersonate = (impersonatedId, success?: any, failure?: any) => {
  callApi('/superuser/impersonate', (json) => success(json), failure, {
    body: { impersonatedId },
    method: 'POST',
  });
};

export const logOut = (success?: any, failure?: any) => {
  callApi('/logout', success, failure, { method: 'DELETE' });
  clearToken();
};

export const resetPasswordInstructions = (
  email: string,
  success?: any,
  failure?: any,
) => {
  callApi('/reset_password', success, failure, {
    body: { email },
    method: 'POST',
  });
};

export const resetPassword = (
  token: string,
  password: string,
  success?: any,
  failure?: any,
) => {
  callApi('/reset_password/' + token, success, failure, {
    body: { password },
    method: 'put',
  });
};

export const signUp = (
  email,
  password,
  firstName,
  lastName,
  success,
  failure,
) => {
  callApi('/signup', (json) => success(json.user, json.token), failure, {
    body: { email, password, firstName, lastName },
    method: 'POST',
  });
};

export const photographerSignUp = (
  locator: string,
  body?: Photographer,
  success?: (json: any) => void,
  failure?: (errorObject: { errors: any }) => void,
) => {
  if (!locator || !body) {
    return failure?.({ errors: 'photographerSignUp() missing parameters' });
  }
  callApi(
    `/events/${locator}/photographer`,
    (json) => success?.(json),
    failure,
    {
      body,
      method: 'POST',
    },
  );
};

export const getEvents = (success?: any, failure?: any) => {
  callApi(`/events`, (json) => success(json.events), failure);
};

export const getScheduleItems = (
  locator: string,
  success?: any,
  failure?: any,
) => {
  callApi(`/events/${locator}`, (json) => success(json.scheduleItems), failure);
};

export const getEventStats = (
  locator: string,
  scheduleItemId?: number,
  success?: any,
  failure?: any,
) => {
  let path = `/events/${locator}/stats`;
  if (scheduleItemId) {
    path = path + `?scheduleItemId=${scheduleItemId}`;
  }

  callApi(path, (json) => success(json.checkInStats), failure);
};

export const getTicketPackages = (
  locator: string,
  success?: any,
  failure?: any,
) => {
  callApi(
    `/events/${locator}/ticketPackages`,
    (json) => success(json.ticketPackages),
    failure,
  );
};

export const getTicketPackage = (
  locator: string,
  id: number,
  success?: any,
  failure?: any,
) => {
  callApi(`/events/${locator}/ticketPackages/${id}`, success, failure);
};

export const deleteTicketPackage = (
  locator: string,
  id: number,
  success?: any,
  failure?: any,
) => {
  callApi(`/events/${locator}/ticketPackages/${id}`, success, failure, {
    method: 'DELETE',
  });
};

export const saveTicketPackage = (
  locator: string,
  ticketPackage: TicketPackage,
) => {
  let method, path;

  if (ticketPackage.id) {
    method = 'PATCH';
    path = `/events/${locator}/ticketPackages/${ticketPackage.id}`;
  } else {
    method = 'POST';
    path = `/events/${locator}/ticketPackages`;
  }

  return callApiPromised(path, { method, body: ticketPackage });
};

export const getTicketPurchases = async (locator, scheduleItemId, success) => {
  await callApi(
    `/events/${locator}/${scheduleItemId}/ticketPurchases`,
    async (resp) => {
      const ticketPurchases = await hydrateCollection(resp);
      success(ticketPurchases);
    },
  );
};

export const getTicketPurchase = async (locator, scheduleItemId, id) => {
  const ticketPurchase = await callApiPromised(
    `/events/${locator}/${scheduleItemId}/ticketPurchases/${id}`,
  );

  await hydrateLinks(ticketPurchase);

  return ticketPurchase;
};

export const getTicketPurchaseActivity = async (
  locator,
  scheduleItemId,
  id,
) => {
  const ticketPurchaseActivity = await callApiPromised(
    `/events/${locator}/${scheduleItemId}/ticketPurchases/${id}/activity`,
  );

  return ticketPurchaseActivity;
};

export const createNote = (
  locator,
  scheduleItemId,
  ticketNote,
  success,
  failure,
) => {
  return callApi(
    `/events/${locator}/${scheduleItemId}/ticketPurchases/${ticketNote.ticketPurchaseId}/notes`,
    success,
    failure,
    {
      body: ticketNote,
      method: 'POST',
    },
  );
};

export const uploadTicketPurchases = async (
  locator,
  scheduleItemId,
  csvFile,
) => {
  const token = localStorage.getItem('TOKEN');
  const headers = {
    'Content-Type': 'text/csv',
    Authorization: `Bearer ${token}`,
  };

  return apiFetch(
    'POST',
    `/events/${locator}/${scheduleItemId}/ticketPurchasesImports`,
    headers,
    csvFile,
  );
};

export const updateTicketPurchasesImportHeaders = async (
  locator,
  scheduleItemId,
  importId,
  csvHeaderMappings,
) => {
  const resp = await getApiReponse(
    `/events/${locator}/${scheduleItemId}/ticketPurchasesImports/${importId}`,
    {
      method: 'PUT',
      body: csvHeaderMappings,
    },
  );

  return resp.json;
};

export const applyTicketPurchasesImport = async (
  locator,
  scheduleItemId,
  importId,
) => {
  const token = localStorage.getItem('TOKEN');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return apiFetch(
    'POST',
    `/events/${locator}/${scheduleItemId}/ticketPurchasesImports/${importId}`,
    headers,
  );
};

export const getFans = (locator, success, failure?) => {
  callApi(`/events/${locator}/fans`, (json) => success(json.fans), failure);
};

export const getTeam = (locator, success, failure?) => {
  callApi(
    `/events/${locator}/users`,
    (json) => success(json.users, json.invitations),
    failure,
  );
};

export const getCurrentUser = (success, failure?) => {
  callApi(`/user`, success, failure);
};

export const saveCurrentUser = (userAttributes, success, failure?) => {
  callApi(`/user`, success, failure, {
    method: 'PUT',
    body: userAttributes,
  });
};

export const changePassword = (password, success, failure?) => {
  callApi(`/user/password`, success, failure, {
    method: 'PUT',
    body: { password },
  });
};

export const getBillingDetails = (locator, success, failure?) => {
  callApi(`/events/${locator}/billing`, success, failure);
};

export const saveBillingDetails = (
  locator: string,
  billingAttributes: BillingAttributes,
  success: (result: any) => void,
  failure?: (errors: any) => void,
) => {
  callApi(`/events/${locator}/billing`, success, failure, {
    method: 'PUT',
    body: billingAttributes,
  });
};

export const getPhotoBoothSettings = (locator, success, failure?) => {
  callApi(`/events/${locator}/photobooth`, success, failure);
};

export const savePhotoBoothSettings = (
  locator,
  photoboothAttributes,
  success,
  failure?,
) => {
  callApi(`/events/${locator}/photobooth`, success, failure, {
    method: 'PUT',
    body: photoboothAttributes,
  });
};

export const getBandsintownSettings = (locator, success, failure?) => {
  callApi(`/events/${locator}/bandsintown`, success, failure);
};

export const saveBandsintownSettings = (
  locator,
  artist,
  startsAt,
  success,
  failure?,
) => {
  callApi(`/events/${locator}/bandsintown`, success, failure, {
    method: 'PUT',
    body: {
      bandsintownSettings: {
        artist,
        startsAt,
      },
    },
  });
};

export const inviteTeamMember = (
  locator: string,
  email?: string,
  roleType?: string,
  success?: any,
  failure?: any,
) => {
  callApi(`/events/${locator}/invite`, success, failure, {
    method: 'POST',
    body: { email, roleType },
  });
};

export const removeTeamMember = (
  locator,
  memberId,
  success?: any,
  failure?: any,
) => {
  callApi(`/events/${locator}/users/${memberId}`, success, failure, {
    method: 'DELETE',
  });
};

export const getInvitation = (token, success, failure?: any) => {
  callApi(`/join/${token}`, (json) => success(json), failure);
};

export const deleteInvitation = (
  token: string,
  success?: any,
  failure?: any,
) => {
  callApi(`/join/${token}`, success, failure, {
    method: 'DELETE',
  });
};

export const acceptInvitation = (
  token,
  email,
  password,
  firstName,
  lastName,
  success,
  failure,
) => {
  callApi(`/join/${token}`, (json) => success(json.user), failure, {
    method: 'POST',
    body: { email, password, firstName, lastName },
  });
};

export const getPosts = (locator, scheduleItemId, success, failure?: any) => {
  callApi(
    `/events/${locator}/${scheduleItemId}/images`,
    (json) => success(json.posts),
    failure,
  );
};

export const deletePosts = (
  locator,
  scheduleItemId,
  postIds,
  success,
  failure?,
) => {
  callApi(
    `/events/${locator}/${scheduleItemId}/images`,
    (json) => success(json),
    failure,
    {
      method: 'DELETE',
      body: { postIds },
    },
  );
};

export const getDailyFanMetrics = (locator, query, success, failure) => {
  callApi(`/events/${locator}/metrics/fans?` + query, success, failure);
};

export const getDailyDownloadsMetrics = (locator, query, success, failure) => {
  callApi(`/events/${locator}/metrics/downloads?` + query, success, failure);
};

export const getDailySalesMetrics = (locator, query, success, failure) => {
  callApi(`/events/${locator}/metrics/sales?` + query, success, failure);
};

export const getDailyProfitMetrics = (locator, query, success, failure) => {
  callApi(`/events/${locator}/metrics/profit?` + query, success, failure);
};

export const getProductByProfitMetrics = (locator, query, success, failure) => {
  callApi(
    `/events/${locator}/metrics/productsByProfit?` + query,
    success,
    failure,
  );
};

export const getProductByVolumeMetrics = (locator, query, success, failure) => {
  callApi(
    `/events/${locator}/metrics/productsByVolume?` + query,
    success,
    failure,
  );
};

export const getSalesByLocation = (locator, query, success, failure) => {
  callApi(
    `/events/${locator}/metrics/salesByLocation?` + query,
    success,
    failure,
  );
};

export const getSalesByWeekdayHour = (locator, query, success, failure?) => {
  callApi(
    `/events/${locator}/metrics/salesByWeekdayHour?` + query,
    success,
    failure,
  );
};

export const getDownloadsByWeekdayHour = (
  locator,
  query,
  success,
  failure?,
) => {
  callApi(
    `/events/${locator}/metrics/downloadsByWeekdayHour?` + query,
    success,
    failure,
  );
};

export const getDownloadsByLocation = (locator, query, success, failure) => {
  callApi(
    `/events/${locator}/metrics/downloadsByLocation?` + query,
    (result) => {
      const formattedDownloads = result.downloads.map((d) => {
        return { name: d.location, amount: d.count };
      });

      success({ values: formattedDownloads.slice(0, 10) });
    },
    failure,
  );
};

export const createEvent = (eventAttributes, success, failure) => {
  callApi(`/events`, success, failure, {
    method: 'POST',
    body: eventAttributes,
  });
};

export const updateEvent = (
  currentLocator,
  eventAttributes?: any,
  success?: any,
  failure?: any,
) => {
  callApi(`/events/${currentLocator}`, success, failure, {
    method: 'PUT',
    body: eventAttributes,
  });
};

export const getScheduleItem = (
  locator: string,
  scheduleItemId: string | number,
  success?: any,
  failure?: any,
) => {
  return new Promise((resolve, reject) => {
    callApi(
      `/events/${locator}/${scheduleItemId}`,
      (json) => {
        success ? success(json.scheduleItem) : resolve(json.scheduleItem);
      },
      (error) => {
        failure ? failure(error) : reject(error);
      },
      {
        method: 'GET',
      },
    );
  });
};

export const createScheduleItem = (
  locator: string,
  scheduleItem: ScheduleItem,
  success?: any,
  failure?: any,
) => {
  callApi(`/events/${locator}`, success, failure, {
    method: 'POST',
    body: scheduleItem,
  });
};

export const updateScheduleItem = (
  locator: string,
  scheduleItem: ScheduleItem,
  success?: any,
  failure?: any,
) => {
  callApi(`/events/${locator}/${scheduleItem.id}`, success, failure, {
    method: 'PUT',
    body: scheduleItem,
  });
};

export const deleteScheduleItem = (
  locator,
  scheduleItemId,
  success?: any,
  failure?: any,
) => {
  callApi(`/events/${locator}/${scheduleItemId}`, success, failure, {
    method: 'DELETE',
  });
};

export const notifyWaitingFans = (locator, scheduleItemId, success) => {
  callApi(
    `/events/${locator}/${scheduleItemId}/notify_waiting_fans`,
    success,
    defaultFailure,
    {
      method: 'POST',
    },
  );
};

export const uploadFile = (
  locator,
  scheduleItemId,
  file,
  progress,
  success?: any,
  failure?: any,
) => {
  const req = new XMLHttpRequest();

  const onLoad = (e) => {
    if (req.status >= 200 && req.status < 300) {
      const { post, scheduleItem } = req.response || {};
      success(post, scheduleItem);
    } else {
      const body = req.response;
      failure(req.status, body ? body.reason : 'Unknown');
    }
  };
  const onFail = (e) => failure(500, 'Unexpected error');
  const onProgress = (e) => progress(e.loaded, e.total);

  req.addEventListener('load', onLoad);
  req.addEventListener('error', onFail);
  req.addEventListener('timeout', onFail);
  req.upload.addEventListener('progress', onProgress);

  req.open(
    'POST',
    `${process.env.API_HOST}/events/${locator}/${scheduleItemId}/images`,
  );
  const token = localStorage.getItem('TOKEN');
  req.setRequestHeader('Authorization', `Bearer ${token}`);

  req.timeout = 1000 * 60 * 5; // 5 minute total limit for each upload
  req.responseType = 'json';

  req.send(file);
};

export const uploadScheduleCSV = async (locator, csvFile) => {
  const token = localStorage.getItem('TOKEN');
  const headers = {
    'Content-Type': 'text/csv',
    Authorization: `Bearer ${token}`,
  };

  return await apiFetch(
    'POST',
    `/events/${locator}/schedule.csv`,
    headers,
    csvFile,
  );
};

// Admin Alerts

export const getAdminAlerts = (success?: any, failure?: any) => {
  callApi(`/alerts`, (json) => success(json.alerts), failure);
};

export const readAdminAlert = (id, success?: any, failure?: any) => {
  callApi(`/alerts/${id}/read`, (json) => success(json.alert), failure, {
    method: 'PUT',
  });
};

export const imagePath = (digest, size, options) => {
  let path = `${process.env.IMAGE_HOST}/resized/${digest}?`;

  if (typeof size === 'number') {
    size = { w: size, h: size };
  }

  if (size['w']) {
    path = `${path}&w=${size['w']}`;
  }

  if (size['h']) {
    path = `${path}&h=${size['h']}`;
  }

  if (options?.fit) {
    path = `${path}&m=fit`;
  }

  if (options?.format) {
    path = `${path}&format=${options?.format}`;
  }

  if (options?.animated) {
    path = `${path}&animated=${options?.animated}`;
  }

  return path;
};

export const originalPath = (digest) => {
  return `${process.env.IMAGE_HOST}/originals/${digest}`;
};

export const downloadsPath = (locator, scheduleItemID, postIds) => {
  const postParams = postIds.map((postId) => `postId=${postId}`).join('&');
  return `${process.env.API_HOST}/events/${locator}/${scheduleItemID}/download?${postParams}`;
};

// === Link Hydration ===

// hydrateCollection calls hydrateLink on a collection of links.
async function hydrateCollection(links) {
  return Promise.all(links.map((link) => hydrateLink(link)));
}

// hydrateLink fetches an individual link and hydrates any links
// or collections in the response.
async function hydrateLink(link) {
  const href = link.link.replace(/^\/api\//, '/');
  const result = await callApiPromised(href);
  await hydrateLinks(result);
  return result;
}

// hydrateLinks hydrates all the links in a model recursively. It looks
// for properties ending in Link or Links and fetches and updates the linked
// models.
const hydrateLinks = async (model) => {
  const keys = Object.keys(model);
  const modelLinks = keys.filter((k) => k.endsWith('Link'));
  const collectionLinks = keys.filter((k) => k.endsWith('Links'));

  const hydrations = {};

  modelLinks.forEach((attr) => (hydrations[attr] = hydrateLink(model[attr])));

  collectionLinks.forEach(
    (attr) => (hydrations[attr] = hydrateCollection(model[attr])),
  );

  await Promise.all(Object.values(hydrations));

  Object.entries(hydrations).map(async ([link, promise]) => {
    const attr = link.replace(/(s)?Links$/, 's').replace(/Link$/, '');
    model[attr] = await promise;
  });
};
