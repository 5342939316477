import { getEvents } from 'api';
import { AppContext, IAppContext } from 'context';
import { atLeast } from 'context/roles';
import { Event } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

interface EventEx extends Event {
  roleType?: string;
}

const EventPicker: React.FC = () => {
  const [loaded, setLoaded] = useState(false);

  const {
    user,
    currentEvent,
    setCurrentEvent,
    allEvents,
    setEvents,
    clearUser,
  } = useContext<IAppContext>(AppContext);
  const navigate = useNavigate();

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (loaded) {
      const locatorFromUrl = getLocatorFromUrl();
      if (locatorFromUrl && !setCurrentEventFromUrl(locatorFromUrl)) {
        if (allEvents.length > 0) {
          setCurrentEvent(allEvents[0]);
          redirectTo('/event/' + allEvents[0].locator);
        }
      }
    }
  }, [location, loaded]);

  useEffect(() => {
    setLoaded(false);
    loadEvents();
  }, [user]);

  const optionForEvent = (e: Event) => ({
    value: e.locator,
    label: `${e.name} (${e.locator})`,
  });

  const loadEvents = () => {
    const success = (events: Event[]) => {
      setEvents(events);
      setLoaded(true);
    };

    const failure = () => {
      clearUser();
      redirectTo('/');
    };

    getEvents(success, failure);
  };

  const setCurrentEventFromUrl = (locator?: string) => {
    const urlEvent = allEvents?.find((e: Event) => e.locator === locator);
    if (urlEvent) {
      setCurrentEvent(urlEvent);
      return true;
    } else {
      redirectTo('/');
      return false;
    }
  };

  const getLocatorFromUrl = () => {
    const match = path.match(/\/event\/([a-zA-Z0-9-]+)/);
    return match?.[1] ?? null;
  };

  const redirectTo = (location) => {
    navigate(location);
  };

  const redirectToEvent = (e: any) => {
    const event: EventEx | undefined = allEvents?.find(
      (ev) => ev.locator === e.value,
    );

    if (event) {
      setCurrentEvent(event);
      if (atLeast('a', event.roleType)) {
        redirectTo(`/event/${e.value}`);
      } else {
        redirectTo(`/event/${e.value}/uploader`);
      }
    }
  };

  if (!currentEvent?.locator) {
    return null;
  }

  if ((allEvents?.length ?? 0) < 1) return null;

  const newCurrentEvent =
    allEvents?.find((e) => e.locator === currentEvent.locator) ||
    allEvents?.[0];

  if (!newCurrentEvent) {
    return null;
  }

  const options = allEvents?.map(optionForEvent);
  const current = optionForEvent(newCurrentEvent);

  options?.sort((a: any, b: any) => a.label.localeCompare(b.label));

  const styles = {
    singleValue: (provided) => ({ ...provided, color: 'inherit' }),
  };

  return (
    <Select
      className="Header__EventPicker"
      classNamePrefix="Header__EventPicker"
      options={options}
      value={current}
      styles={styles}
      onChange={redirectToEvent}
    />
  );
};

export default EventPicker;
