import Page from 'components/core/Page';
import EditEventSettingsForm from 'components/EditEventSettingsForm';
import React from 'react';
import EventSettingsHeader from './EventSettingsHeader';

const EventSettingsPage = () => {
  return (
    <Page>
      <div className="EventSettingsPage">
        <EventSettingsHeader />
        <div className="Tabs__Content">
          <EditEventSettingsForm />
        </div>
      </div>
    </Page>
  );
};

export default EventSettingsPage;
