import { uploadFile } from 'api';
import { PubSub } from 'context/util';
import UploadQueue from 'models/UploadQueue';
import React from 'react';

export type Progress = { state: any; total: any; sent: any };

export interface IUploaderState {
  onItemComplete: PubSub;
  progress?: Progress | null;
  children?: JSX.Element | JSX.Element[] | null;
}

const defaultState: IUploaderState = {
  progress: null,
  onItemComplete: new PubSub(),
  children: undefined,
};

export interface IUploaderContext extends IUploaderState {
  uploadFile: (
    locator: string | undefined,
    scheduleItemId: number,
    file: any,
  ) => void;
  clearErrors: (scheduleItemId?: any) => void;
  retryFailed: (scheduleItemId?: any) => void;
}

const defaultContext = {
  ...defaultState,
  onItemComplete: new PubSub(),
  uploadFile: () => undefined,
  clearErrors: () => undefined,
  retryFailed: () => undefined,
};

export const UploaderContext =
  React.createContext<IUploaderContext>(defaultContext);
UploaderContext.displayName = 'Uploader Context';

export class UploaderProvider extends React.PureComponent<IUploaderContext> {
  queue = new UploadQueue(this, uploadFile);

  uploadFile = (
    locator: string | undefined,
    scheduleItemId: number,
    file: any,
  ) => {
    this.queue.add(locator, scheduleItemId, file);
  };

  notifyItemComplete = (post: any, scheduleItem: any) => {
    return this.state.onItemComplete.emit({ post, scheduleItem });
  };

  updateProgress = (progress: Progress) => this.setState({ progress });

  state = {
    ...defaultState,
    uploadFile: this.uploadFile,
    clearErrors: this.queue.clearErrors,
    retryFailed: this.queue.retryFailed,
  };

  render() {
    return (
      <UploaderContext.Provider value={this.state}>
        {this.props.children}
      </UploaderContext.Provider>
    );
  }
}
