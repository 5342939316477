import { useEffect } from 'react';

interface UnloadProps {
  when: () => boolean;
  message: string;
}

export const useUnload = ({ message, when }: UnloadProps) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };
    if (when?.()) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when, message]);
};
