import React from 'react';

interface WithBlankSlateProps {
  children?: JSX.Element | JSX.Element[];
  collection?: any;
  extraContent?: any;
  imagePath?: string;
  message?: JSX.Element | string;
  suppress?: boolean;
  title?: string;
}

export const WithBlankSlate = ({
  collection,
  title,
  message,
  extraContent,
  children,
  imagePath,
  suppress,
}: WithBlankSlateProps): JSX.Element | null => {
  if (suppress) {
    return <>{children}</>;
  }

  if (!Array.isArray(collection)) {
    return null;
  } else if (collection.length === 0) {
    return (
      <BlankSlate
        title={title}
        message={message}
        extraContent={extraContent}
        imagePath={imagePath}
      />
    );
  } else {
    return <>{children}</>;
  }
};

interface BlankSlateProps {
  extraContent?: any;
  imagePath?: string;
  message?: JSX.Element | string;
  title?: string;
}
export const BlankSlate: React.FC<BlankSlateProps> = ({
  title,
  message,
  extraContent,
  imagePath,
}: BlankSlateProps): JSX.Element => (
  <div className="BlankSlate Panel">
    {imagePath && <img src={imagePath} className="BlankSlate__Image" />}
    <h1 className="BlankSlate__Title">{title}</h1>
    <p className="BlankSlate__Message">{message}</p>
    {extraContent}
  </div>
);
