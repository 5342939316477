import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import FileTraverser from './FileTraverser';
import './style.scss';

/* in order to support dropping multiple files, set "multiple={true}"  in the props for this component */

interface DropTargetProps {
  accept?: string;
  className?: string;
  multiple?: boolean;
  style?: any;
  children?: JSX.Element | JSX.Element[] | null;
  onError?: (errorMessage: string) => void;
  uploadFile?: (file: any) => void;
}
const DropTarget: React.FC<DropTargetProps> = ({
  accept,
  className,
  multiple,
  style,
  children,
  onError,
  uploadFile,
}: DropTargetProps) => {
  const [error, setError] = useState<string>('');
  const [hover, setHover] = useState<boolean>(false);

  const el = useRef(null);

  const onDragEnter = async (event: React.DragEvent<HTMLDivElement>) => {
    const items = event.dataTransfer?.items;
    if (!items) return;
    if (!multiple) {
      if (items?.length > 1) {
        setHover(true);
        setError('Too many files.');
        return { hover, error };
      }
      const mimeType = items[0].type;
      if (!isValidType(mimeType)) {
        setHover(true);
        setError('File type not allowed.');
        return { hover, error };
      }
    }
    setHover(true);
    setError('');
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.target === el.current) {
      setHover(false);
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const items = e.dataTransfer?.items;
    if (items) {
      if (!multiple) {
        if (e.dataTransfer?.items.length > 1) {
          onError?.('Only one file allowed.');
          throw new Error('Only one file allowed.');
        }
      }
      for (let item of e.dataTransfer.items) {
        if (!isValidType(item.type)) {
          onError?.('Invalid file type.');
          setHover(false);
          return;
        }
      }
      const files = await filesFromTransfer(e.dataTransfer);
      (files as []).forEach((file) => uploadFile?.(file));
    }
    setHover(false);
  };

  const isValidType = (mimeType) => {
    if (accept) {
      const acceptedTypes = accept.split(',').map((t) => t.trim());
      if (acceptedTypes.indexOf(mimeType) === -1) {
        return false;
      }
    }
    return true;
  };

  const filesFromTransfer = async (dataTransfer: DataTransfer) => {
    const fileTraversor = new FileTraverser(dataTransfer);
    return await fileTraversor.files();
  };

  const cls = classNames(className, 'DropTarget', {
    'DropTarget--hover': hover,
  });

  return (
    <div
      ref={el}
      className={cls}
      style={style}
      onDragEnter={(e) => onDragEnter(e)}
      onDragOver={(e) => onDragOver(e)}
      onDragLeave={(e) => onDragLeave(e)}
      onDrop={(e) => onDrop(e)}
    >
      {children}
      {hover &&
        (error ? (
          <div className="DropTarget__DropArea DropTarget__DropArea--invalid-files">
            <div className="DropTarget__Caption">
              <span>{error}</span>
            </div>
          </div>
        ) : (
          <div className="DropTarget__DropArea">
            <div className="DropTarget__Caption">
              <span>Drop here to upload {error} 123</span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DropTarget;
