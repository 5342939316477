import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import Input from 'components/core/Input';
import ErrorBar from 'components/core/ErrorBar';
import { resetPasswordInstructions } from 'api';
import Copyright from './Copyright';
import FooteLinks from './FooterLinks';

const NewResetPasswordForm: React.FC = () => {
  const EMAIL_REGEXP = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

  const [email, setEmail] = useState<string>('');
  const [formEnabled, setFormEnabled] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    updateButtonStatus();
  }, [email]);

  const updateEmailField = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const updateButtonStatus = () => {
    const emailMatches = RegExp(EMAIL_REGEXP).exec(email);
    setFormEnabled(emailMatches !== null);
  };

  const handleResetRequest = (e) => {
    e.preventDefault();
    setError(null);
    setSubmitting(true);

    const success = () => {
      setSubmitting(false);
      setRequestSent(true);
    };

    const failure = (json) => {
      setError(json.reason || 'Something went wrong');
      setSubmitting(false);
    };

    resetPasswordInstructions(email, success, failure);
  };

  return (
    <>
      {requestSent ? (
        <div className="FullPageForm" data-testid="instructions-sent">
          <h1 className="FullPageForm__Title">Instructions sent</h1>
          <p className="FullPageForm__Description">
            We've sent you instructions to reset your password, please check
            your email.
          </p>

          <footer className="FullPageForm__Footer">
            <p>
              <Link to="/login">Go back to the login page</Link>
            </p>
            <FooteLinks />
            <Copyright />
          </footer>
        </div>
      ) : (
        <form className="FullPageForm" onSubmit={handleResetRequest}>
          <h1 className="FullPageForm__Title">Reset your password</h1>
          <p className="FullPageForm__Description">
            Don't have a StagePilot VIP account?{' '}
            <Link to="/signup">Sign up now.</Link>
          </p>
          <ErrorBar error={error} />
          <Input
            type="email"
            name="email"
            testid="email"
            caption="Email"
            value={email}
            onChange={updateEmailField}
            autoFocus
            fullWidth
          />
          <div className="ResetPasswordForm__Buttons">
            <ButtonPanel>
              <Button
                primary
                caption="Reset password"
                type="submit"
                busyMessage={submitting ? 'Sending instructions...' : undefined}
                enabled={formEnabled}
              />
            </ButtonPanel>
          </div>
          <footer className="FullPageForm__Footer">
            <p>
              Never mind, <Link to="/login">go back to the login page</Link>
            </p>
            <span className="FullPageForm__Footer--right">
              Privacy Policy | Terms and Conditions
            </span>
            <Copyright />
          </footer>
        </form>
      )}
    </>
  );
};

export default NewResetPasswordForm;
