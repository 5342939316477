export class PubSub {
  subscribers = [];

  subscribe = (fn) => {
    this.subscribers = this.subscribers.concat(fn);
  };

  unsubscribe = (fn) => {
    this.subscribers = this.subscribers.filter((s) => s !== fn);
  };

  emit = (event) => {
    this.subscribers.map((s) => s(event));
  };
}
