export interface BillingAttributes {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;

  // newW9?: any; // for API
  newW9?: { file: string; data: any };
  w9Filename?: any;
  w9Digest?: any;
}

export const emptyBillingAttributes: BillingAttributes = {
  firstName: '',
  lastName: '',
  email: '',
  organization: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
};
