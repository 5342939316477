import { deleteInvitation, getTeam, removeTeamMember } from 'api';
import { IfAdmin } from 'components/core/Authorization';
import Button from 'components/core/Button';
import Page from 'components/core/Page';
import { TeamList } from 'components/TeamList';
import InviteTeamMemberModal from 'modals/InviteTeamMemberModal';
import { User, ValidUser } from 'models/User';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const TeamPage: React.FC = () => {
  const [teamList, setTeamList] = useState<ValidUser[]>([]);
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
  const { locator } = useParams();
  const [token, setToken] = useState<string>('0');

  const loadTeamList = () => {
    const success = (team, invitations) => {
      const updatedTeamList = team.concat(
        invitations
          .filter((i) => !i.acceptedAt)
          .map((i) =>
            Object.assign(i, {
              pending: true,
              id: `invitation-${i.id} `,
            }),
          ),
      );
      setTeamList(updatedTeamList);
    };
    if (locator) {
      getTeam(locator, success);
    }
  };

  const removeTeamMemberHandler = (member: User) => {
    removeTeamMember(locator, member.id, () => {
      let updatedTeam = teamList.filter((item) => item !== member);
      setTeamList(updatedTeam);
    });
  };

  const removePendingInviteHandler = (member: ValidUser) => {
    deleteInvitation(member.token ?? '', () => {
      let updatedTeam = teamList.filter((item) => item !== member);
      setTeamList(updatedTeam);
    });
  };

  const openInviteModal = () => {
    setInviteModalOpen(true);
  };

  const closeInviteModal = (token = '') => {
    setToken(token);
    setInviteModalOpen(false);
    loadTeamList();
  };

  useEffect(() => {
    loadTeamList();
  }, [locator]);

  return (
    <Page>
      <>
        <h1 className="Page__Title">Team members</h1>
        <div className="Page__Header">
          <p>
            Learn about{' '}
            <a
              href="https://support.stagepilot.com/en/article/adding-team-members"
              target="_blank"
            >
              roles and permissions
            </a>
            .
          </p>
          <IfAdmin>
            <Button
              primary
              caption="Invite team member"
              onClick={openInviteModal}
            />
          </IfAdmin>
        </div>
        {teamList.length > 0 && (
          <TeamList
            teamList={teamList}
            removePendingInviteHandler={removePendingInviteHandler}
            removeTeamMemberHandler={removeTeamMemberHandler}
          />
        )}
        <InviteTeamMemberModal
          open={inviteModalOpen}
          onClose={closeInviteModal}
        />
        <div>
          <span data-testid="invitation-token">{token}</span>
        </div>
      </>
    </Page>
  );
};

export default TeamPage;
