import React from 'react';

export default function Copyright() {
  const year = new Date().getFullYear().toString();

  return (
    <>
      &copy; <time dateTime={year}>{year}</time> StagePilot, LLC – All rights
      reserved.
    </>
  );
}
