import classNames from 'classnames';
import darkLogoPath from 'images/logo-black.svg';
import lightLogoPath from 'images/logo-white.svg';
import React from 'react';

interface LogoProps {
  dark?: boolean;
  centered?: boolean;
}
const Logo: React.FC<LogoProps> = ({ dark, centered }: LogoProps) => (
  <img
    alt="StagePilot VIP"
    className={classNames('Logo', { 'Logo--centered': centered })}
    src={dark ? darkLogoPath : lightLogoPath}
  />
);

export default Logo;
