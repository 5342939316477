import classNames from 'classnames';
import React from 'react';

interface TabSwitchItem {
  caption: string;
  key: string;
}

interface TabProps {
  item: TabSwitchItem;
  selectedItem: string;
  onClick: (selectedItem: string) => void;
}

const Tab: React.FC<TabProps> = ({ item, selectedItem, onClick }) => {
  const cls = classNames('Tab', { 'Tab--active': item.key === selectedItem });
  return (
    <div className={cls} onClick={() => onClick(item.key)}>
      {item.caption}
    </div>
  );
};

interface TabSwitchProps {
  caption?: string;
  items: TabSwitchItem[];
  selectedItem: string;
  onClick: (selectedItem: string) => void;
}

const TabSwitch: React.FC<TabSwitchProps> = ({
  caption = 'View:',
  items,
  selectedItem,
  onClick,
}) => {
  return (
    <div className="Tabs">
      {caption}
      {items.map((i) => (
        <Tab
          key={i.key}
          item={i}
          selectedItem={selectedItem}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

export default TabSwitch;
