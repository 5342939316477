import Page from 'components/core/Page';
import PhotoBoothSettings from 'components/PhotoBoothSettings';
import React from 'react';
import EventSettingsHeader from './EventSettingsHeader';

const EventPhotoBoothSettingsPage: React.FC = () => {
  return (
    <Page>
      <div
        className="EventPhotoBoothSettingsPage"
        data-testid="EventPhotoBoothSettingsPage"
      >
        <EventSettingsHeader />

        <div className="Tabs__Content">
          <PhotoBoothSettings />
        </div>
      </div>
    </Page>
  );
};

export default EventPhotoBoothSettingsPage;
