import AdminAlerts from 'components/AdminAlerts';
import IconButton from 'components/core/IconButton';
import LinkButton from 'components/core/LinkButton';
import EventPicker from 'components/EventPicker';
import { AppContext, IAppContext, IModalContext, ModalContext } from 'context';
import { User } from 'models';
import React, { useContext } from 'react';
import './styles.scss';

interface HeaderControlsProps {
  user: User;
  showCreateEventModal: (show: any) => void;
  resetImpersonation: () => void;
}
const HeaderControls: React.FC<HeaderControlsProps> = ({
  user,
  resetImpersonation,
  showCreateEventModal,
}: HeaderControlsProps) => {
  const impersonating = !!user.impersonating;
  return (
    <div className="Header__Content">
      <span className="Header__Controls">
        <AdminAlerts />
        {user.superUser && (
          <IconButton
            icon="create-new"
            caption="Create a new event"
            onClick={() => showCreateEventModal(true)}
          />
        )}
      </span>
      {impersonating && (
        <span className="Header__Impersonating">
          Impersonating: {user.email} |{' '}
          <LinkButton caption="Reset" light onClick={resetImpersonation} />
        </span>
      )}
      <span className="Header__Picker">
        <EventPicker />
      </span>
    </div>
  );
};

const Header: React.FC = () => {
  const { showCreateEventModal } = useContext<IModalContext>(ModalContext);
  const { user, isLoggedIn, impersonate } = useContext<IAppContext>(AppContext);
  const resetImpersonation = () => impersonate(0);
  return (
    <div className="Header">
      {isLoggedIn && user ? (
        <HeaderControls
          user={user}
          showCreateEventModal={showCreateEventModal}
          resetImpersonation={resetImpersonation}
        />
      ) : null}
    </div>
  );
};

export default Header;
