export interface UserSimple {
  firstName: string;
  lastName: string;
  email: string;
}

export interface User extends UserSimple {
  id?: number;
  password?: string;
  pending?: boolean;
  roleType?: string;
  token?: string;
  impersonating?: boolean;
  superUser?: boolean;
}

export interface ValidUser extends User {
  id: number;
  roleType: string;
}

export const emptyUser = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  roleType: '',
};
