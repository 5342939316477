import classNames from 'classnames';
import clockPath from 'images/icons/clock.svg';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = DatePicker as any;

interface TimeInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange'> {
  caption?: string;
  optional?: boolean;
  value?: Date;
  onChange: (value: Date) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({
  name,
  caption = name,
  value,
  onChange,
  optional = false,
  autoFocus = false,
  readOnly,
}: TimeInputProps) => {
  const className = classNames('Input', 'TimeInput', 'Input--icon', {
    'Input--optional': optional,
  });
  return (
    <div className={className}>
      <label htmlFor={name} className="Input__Caption">
        {caption}
      </label>
      <div className="Input__Control Input__Control--icon Input__Control--time">
        <img className="Input__Icon" src={clockPath} role="presentation" />
        <CustomDatePicker
          className="Input__Picker TimeInput__Picker"
          id={name}
          name={name}
          selected={value}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          dateFormat="h:mm aa"
          onChange={onChange}
          autoFocus={autoFocus}
          placeholderText={optional ? 'optional' : 'HH:MM PM'}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default TimeInput;
