import { IfPermissionLevel } from 'components/core/Authorization';
import Button from 'components/core/Button';
import { AppContext, IAppContext } from 'context';
import React, { useContext, useState } from 'react';
import './styles.scss';

interface RemovePendingInviteButtonProps {
  invite: object;
  removePendingInviteHandler: Function;
}

export const RemovePendingInviteButton: React.FC<
  RemovePendingInviteButtonProps
> = ({
  invite,
  removePendingInviteHandler,
}: RemovePendingInviteButtonProps) => {
  const [canceling, setCanceling] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Pending');
  const [buttonType, setButtonType] = useState<string>('Button--secondary');
  const { currentRole } = useContext<IAppContext>(AppContext);

  const deleteInvitation = () => {
    setCanceling(true);
    removePendingInviteHandler(invite);
  };

  return (
    <IfPermissionLevel requiredLevel={currentRole}>
      <Button
        className={buttonType}
        confirmMessage={`An invite has been emailed to your team member.\nAre you sure you want to cancel the invitation?`}
        busyMessage={canceling ? 'Canceling...' : undefined}
        onMouseOver={() => {
          setButtonText('Cancel');
          setButtonType('Button--danger');
        }}
        onMouseLeave={() => {
          setButtonText('Pending');
          setButtonType('Button--secondary');
        }}
        onClick={() => deleteInvitation()}
      >
        {buttonText}
      </Button>
    </IfPermissionLevel>
  );
};
