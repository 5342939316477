export const IDLE = 'IDLE';
export const ACTIVE = 'ACTIVE';
export const ERROR = 'ERROR';
export const COMPLETE = 'COMPLETE';

class UploadItem {
  locator: string;
  scheduleItemId: number;
  file: any;
  size: number;
  name: string;
  bytesSent: number;
  failedAttempts: number;
  retryable: boolean;
  failureReason: any;

  static MAX_ATTEMPTS_PER_UPLOAD = 3;

  constructor(locator, scheduleItemId, file) {
    this.locator = locator;
    this.scheduleItemId = scheduleItemId;
    this.file = file;
    this.size = file.size;
    this.name = file.name;

    this.bytesSent = 0;
    this.failedAttempts = 0;
    this.retryable = false;
    this.failureReason = null;
  }

  matches = (other) =>
    this.name === other.name && this.scheduleItemId === other.scheduleItemId;

  markFailed = () => {
    this.bytesSent = 0;
    this.failedAttempts++;
    return this.failedAttempts < UploadItem.MAX_ATTEMPTS_PER_UPLOAD;
  };

  // Firefox doesn't seem to be setting the file.type so we need to rely
  // on the file extension
  isSupportedFormat = () => {
    return (
      this.file.type === 'image/gif' ||
      this.file.name.endsWith('.gif') ||
      this.file.type === 'image/jpeg' ||
      this.file.name.endsWith('.jpg') ||
      this.file.name.endsWith('.jpeg')
    );
  };
}

class UploadQueueScheduler {
  setTimeout = (fn, dur) => window.setTimeout(fn, dur);
  clearTimeout = (id) => window.clearTimeout(id);
}

class UploadQueue {
  owner: any;
  uploadFileHandler: any;
  scheduler: any;

  static MAX_CONCURRENT_UPLOADS = 2;
  static COMPLETE_STATUS_DURATION = 3000;

  items: Record<string, any> = {
    pending: [],
    active: [],
    failed: [],
    complete: [],
  };

  cleanupTimers = {};

  constructor(
    owner,
    uploadFileHandler,
    scheduler = new UploadQueueScheduler(),
  ) {
    this.owner = owner;
    this.uploadFileHandler = uploadFileHandler;
    this.scheduler = scheduler;
  }

  // public interface

  add = (locator, scheduleItemId, file) => {
    this.enqueueItem(new UploadItem(locator, scheduleItemId, file));
  };

  clearErrors = (scheduleItemId = null) => {
    const keep = (i) => i.scheduleItemId !== scheduleItemId;

    this.items.failed = this.items.failed.filter(keep);
    this.items.complete = this.items.complete.filter(keep);

    this.updateProgressSummary();
  };

  retryFailed = (scheduleItemId = null) => {
    const shouldRetry = (item) =>
      item.retryable &&
      (scheduleItemId === null || item.scheduleItemId === scheduleItemId);

    this.items.failed
      .filter(shouldRetry)
      .forEach(({ locator, scheduleItemId, file }) => {
        this.add(locator, scheduleItemId, file);
      });
  };

  // private implementation

  enqueueItem = (item) => {
    if (this.itemPending(item)) {
      return;
    }

    this.items.failed = this.items.failed.filter((i) => !i.matches(item));
    this.items.pending.push(item);
    this.initiateNextTransfer();
    this.updateProgressSummary();
  };

  itemPending = (item) =>
    this.items.pending.find((i) => i.matches(item)) ||
    this.items.active.find((i) => i.matches(item));

  initiateNextTransfer = () => {
    while (
      this.items.pending.length > 0 &&
      this.items.active.length < UploadQueue.MAX_CONCURRENT_UPLOADS
    ) {
      const item = this.items.pending.shift();
      this.items.active.push(item);
      this.startTransfer(item);
    }
  };

  scheduleCleanupSchedueItem = (scheduleItemId) => {
    const old = this.cleanupTimers[scheduleItemId];
    if (old) {
      this.scheduler.clearTimeout(old);
    }

    const cleanup = () => this.removeCompletedItems(scheduleItemId);
    const id = this.scheduler.setTimeout(
      cleanup,
      UploadQueue.COMPLETE_STATUS_DURATION,
    );

    this.cleanupTimers[scheduleItemId] = id;
  };

  removeCompletedItems = (scheduleItemId) => {
    const isPeer = (i) => i.scheduleItemId === scheduleItemId;
    const isNotPeer = (i) => !isPeer(i);

    const peerCount =
      this.items.pending.filter(isPeer).length +
      this.items.active.filter(isPeer).length +
      this.items.failed.filter(isPeer).length;

    if (peerCount === 0) {
      this.items.complete = this.items.complete.filter(isNotPeer);
      this.updateProgressSummary();
    }
  };

  startTransfer = (item) => {
    if (!item.isSupportedFormat()) {
      this.markDone(item, false, false, `Unsupported file type`);
      return;
    }

    const success = (post, scheduleItem) => {
      this.markDone(item, true);
      this.notifyItemComplete(post, scheduleItem);
    };
    const failure = (status, reason) => {
      const retryable = status >= 500;
      this.markDone(item, false, retryable, reason);
    };
    const progress = (sent) => this.recordProgress(item, sent);

    this.uploadFileHandler(
      item.locator,
      item.scheduleItemId,
      item.file,
      progress,
      success,
      failure,
    );
  };

  recordProgress = (item, bytesSent) => {
    item.bytesSent = bytesSent;
    this.updateProgressSummary();
  };

  markDone = (
    item: any,
    success: boolean,
    retryable?: boolean,
    reason?: string,
  ) => {
    this.items.active = this.items.active.filter((i) => i !== item);
    let nextQueue;

    if (success) {
      nextQueue = this.items.complete;
    } else {
      const retry = retryable && item.markFailed();
      nextQueue = retry ? this.items.pending : this.items.failed;

      item.failureReason = reason;
      item.retryable = retryable;
      item.bytesSent = 0;
    }
    nextQueue.push(item);

    this.initiateNextTransfer();
    this.scheduleCleanupSchedueItem(item.scheduleItemId);
    this.updateProgressSummary();
  };

  notifyItemComplete = (post, scheduleItem) => {
    this.owner.notifyItemComplete(post, scheduleItem);
  };

  updateProgressSummary = () => {
    const progress = {};

    const recordItems = (type) => (item) => {
      const p = progress[item.scheduleItemId] || {
        total: 0,
        sent: 0,
        failed: [],
        counts: {},
      };

      p.total += item.size;
      p.sent += item.bytesSent;
      p.counts[type] = (p.counts[type] || 0) + 1;

      if (type === 'failed') {
        p.failed = p.failed.concat({
          name: item.name,
          failureReason: item.failureReason,
          retryable: item.retryable,
        });
      }

      progress[item.scheduleItemId] = p;
    };

    this.items.pending.forEach(recordItems('pending'));
    this.items.failed.forEach(recordItems('failed'));
    this.items.active.forEach(recordItems('active'));
    this.items.complete.forEach(recordItems('complete'));

    Object.values(progress).map((i: any) => {
      if (i.counts.active > 0 || i.counts.pending > 0) {
        i.state = ACTIVE;
      } else if (i.counts.failed > 0) {
        i.state = ERROR;
      } else if (i.counts.complete > 0) {
        i.state = COMPLETE;
      } else {
        i.state = IDLE;
      }

      if (i.state === IDLE) {
        i.total = 0;
        i.sent = 0;
      }
    });

    this.owner.updateProgress(progress);
  };
}

export default UploadQueue;
