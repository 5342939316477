import React from 'react';
import Form from 'components/core/Form';
import ButtonPanel from 'components/core/ButtonPanel';
import Input from 'components/core/Input';
import Button from 'components/core/Button';
import { showInfoMsg } from 'components/core/AlertMessages';
import { changePassword } from 'api';

class ChangePasswordForm extends React.Component {
  state = {
    password: '',
    errors: {},
  };

  updateField = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, this.updateButtonStatus);
  };

  save = (e) => {
    e.preventDefault();

    const { password } = this.state;

    const success = () => {
      showInfoMsg('Password changed');
    };

    const failure = (errors) => {
      this.setState({ errors: errors.errors });
    };

    changePassword(password, success, failure);
  };

  render() {
    return (
      <Form errors={this.state.errors}>
        <div className="FieldSet">
          <Input
            testid="ChangePasswordForm-password"
            type="password"
            name="password"
            caption="New password"
            value={this.state.password}
            onChange={this.updateField}
          />
        </div>

        <ButtonPanel>
          <Button
            testid="ChangePasswordForm-Button-Save"
            caption="Change password"
            enabled={this.state.saveEnabled}
            onClick={this.save}
            primary
          />
        </ButtonPanel>
      </Form>
    );
  }
}

export default ChangePasswordForm;
