import LinkButton from 'components/core/LinkButton';
import Logo from 'components/Logo';
import { NavigationItem, NavigationSection } from 'components/Navigation';
import { AppContext, IAppContext } from 'context';
import React, { useContext } from 'react';

const NavigationLink = ({ className = 'NavigationLink', onClick, caption }) => (
  <LinkButton onClick={onClick} caption={caption} className={className} />
);

const Sidebar = () => {
  const { hasRole, currentEvent, user } = useContext<IAppContext>(AppContext);
  const locator = currentEvent?.locator;
  const isAdmin = hasRole('a');
  const eventPresent = locator !== null;

  return (
    <div className="Sidebar">
      <Logo />
      <NavigationItem
        topLevel
        to={`/event/${locator}`}
        disabled={!isAdmin || !eventPresent}
      >
        Dashboard
      </NavigationItem>

      <NavigationSection title="Event" teal>
        <NavigationItem
          to={`/event/${locator}/settings`}
          disabled={!isAdmin || !eventPresent}
        >
          Event settings
        </NavigationItem>
        <NavigationItem
          to={`/event/${locator}/schedule`}
          disabled={!eventPresent}
        >
          Schedule
        </NavigationItem>
        <NavigationItem
          to={`/event/${locator}/team`}
          disabled={!isAdmin || !eventPresent}
        >
          Team members
        </NavigationItem>
        <NavigationItem
          to={`/event/${locator}/fans`}
          disabled={!isAdmin || !eventPresent}
        >
          Fan data
        </NavigationItem>
      </NavigationSection>

      <NavigationSection title="Photo Galleries" red>
        <NavigationItem
          to={`/event/${locator}/uploader`}
          disabled={!eventPresent}
        >
          Uploader
        </NavigationItem>
      </NavigationSection>

      {user?.superUser && (
        <NavigationSection title="Check-in" orange>
          <NavigationItem
            to={`/event/${locator}/ticketPackages`}
            disabled={!eventPresent}
          >
            Ticket Packages
          </NavigationItem>
          <NavigationItem
            to={`/event/${locator}/ticketPurchases`}
            disabled={!eventPresent}
          >
            Ticket Data
          </NavigationItem>
        </NavigationSection>
      )}
      <div className="Sidebar__BottomActions">
        <a
          className="NavigationItem NavigationItem--topLevel"
          href="https://support.stagepilot.com"
          target="_blank"
        >
          Help
        </a>
        {user?.superUser && (
          <NavigationItem topLevel to="/admin">
            Superuser
          </NavigationItem>
        )}
        <NavigationItem topLevel to={`/settings`}>
          Account settings
        </NavigationItem>
        <NavigationItem topLevel to={`/logout`}>
          Log out
        </NavigationItem>
      </div>
    </div>
  );
};

export default Sidebar;
