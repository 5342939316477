import { inviteTeamMember } from 'api';
import { showInfoMsg } from 'components/core/AlertMessages';
import Button from 'components/core/Button';
import Form from 'components/core/Form';
import Input from 'components/core/Input';
import Modal, { ModalButtons } from 'components/core/Modal';
import Select from 'components/core/Select';
import { AppContext, IAppContext } from 'context';
import React, { useContext, useEffect, useState } from 'react';

interface InviteTeamMemberModalProps {
  open?: boolean;
  onClose?: (token?: string) => void;
}
const InviteTeamMemberModal: React.FC<InviteTeamMemberModalProps> = ({
  open,
  onClose,
}: InviteTeamMemberModalProps) => {
  const [email, setEmail] = useState<string>('');
  const [roleType, setRoleType] = useState<string>('a');
  const [enabled, setEnabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>(null);

  const { currentEvent, hasRole } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    updateButtonStatus();
  }, [email]);

  const updateButtonStatus = () => {
    setEnabled(!!email);
  };

  const sendInvitation = () => {
    const success = ({ token }) => {
      showInfoMsg('Invitation sent');
      onClose?.(token);
    };
    const failure = (err) => {
      setErrors(err.errors);
    };

    inviteTeamMember(
      currentEvent?.locator ?? '',
      email,
      roleType,
      success,
      failure,
    );
  };

  const closeModal = () => {
    onClose?.();
  };

  const roleOptions = [
    ['a', 'Admin'],
    ['p', 'Photographer'],
  ];

  if (hasRole('o')) {
    roleOptions.unshift(['o', 'Owner']);
  }

  return (
    <Modal open={open} title="Invite team member" onClose={closeModal}>
      <Form errors={errors}>
        <Input
          testid="email-address"
          caption="Email address"
          autoFocus
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Select
          testid="role-type"
          caption="Role"
          name="roleType"
          options={roleOptions}
          value={roleType}
          onChange={(e) => setRoleType(e.target.value)}
        />
        <ModalButtons>
          <Button
            testid="invite-button"
            primary
            caption="Send invitation"
            onClick={sendInvitation}
            enabled={enabled}
          />
        </ModalButtons>
      </Form>
    </Modal>
  );
};

export default InviteTeamMemberModal;
