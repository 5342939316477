import BillingSettings from 'components/BillingSettings';
import Page from 'components/core/Page';
import React from 'react';
import EventSettingsHeader from './EventSettingsHeader';

const EventSettingsPage: React.FC = () => {
  return (
    <Page>
      <div className="EventSettingsPage">
        <EventSettingsHeader />

        <div className="Tabs__Content">
          <BillingSettings />
        </div>
      </div>
    </Page>
  );
};

export default EventSettingsPage;
