import Input from 'components/core/Input';
import React, { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import './styles.scss';

interface GuestSearchProps {
  selectedFilter: string;
  searchBoxTerm: string;
  setSearchBoxTerm: (type: string) => void;
  setSelectedFilter: (type: string) => void;
}

type MyOption = { value: string; label: string };
const FILTER_OPTIONS: MyOption[] = [
  { value: 'all', label: 'All guests' },
  { value: 'checkedIn', label: 'Checked in' },
  { value: 'partiallyCheckedIn', label: 'Partially checked in' },
  { value: 'notCheckedIn', label: 'Not checked in' },
  { value: 'notes', label: 'Notes' },
];

const GuestSearch: React.FunctionComponent<GuestSearchProps> = ({
  selectedFilter,
  searchBoxTerm,
  setSearchBoxTerm,
  setSelectedFilter,
}: GuestSearchProps) => {
  const [selectedOption, setSelectedOption] = useState<MyOption>(
    FILTER_OPTIONS[0],
  );

  const updateSelectedOption = (option: SingleValue<MyOption>) => {
    setSelectedOption(option ?? FILTER_OPTIONS[0]);
    setSelectedFilter(option?.value ?? '');
  };

  const changeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBoxTerm(e.target.value);
  };

  return (
    <div className="GuestSearch">
      <div className="GuestSearch__Input">
        <Input
          type="search"
          fullWidth
          hiddenCaption
          className="form-control"
          id="search"
          placeholder="Search"
          value={searchBoxTerm}
          onChange={changeSearchTerm}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
        />
      </div>

      <div className="GuestSearch__Select">
        <Select
          id="filter"
          value={selectedOption}
          onChange={updateSelectedOption}
          getOptionLabel={(option: MyOption) => option.label}
          getOptionValue={(option: MyOption) => option.value}
          options={FILTER_OPTIONS}
        ></Select>
      </div>
    </div>
  );
};

export default GuestSearch;
//
