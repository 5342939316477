import React from 'react';

interface AutoAddFieldProps {
  name?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  onAddNew?: (value?: string) => void;
}
const AutoAddField: React.FC<AutoAddFieldProps> = ({
  name,
  value,
  onChange,
  onAddNew,
}: AutoAddFieldProps) => {
  const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const keyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      onAddNew?.(value);

      // Move to the next element in the form
      const target = e.target as HTMLInputElement;
      const form = target.form;

      if (!form) return;

      // Focus on the next element after the component has re-rendered
      setTimeout(() => {
        const index = Array.prototype.indexOf.call(form, target);
        const nextInput = form.elements[index + 1] as HTMLInputElement;
        if (nextInput) nextInput.focus();
      }, 10);
    }
  };

  return (
    <div className="Input">
      <label htmlFor={name} className="Input__Caption">
        {name}
      </label>
      <input
        className="Input__Control"
        type="text"
        id={name}
        name={name}
        value={value}
        onKeyDown={keyDown}
        onChange={changed}
        onKeyUp={keyUp}
      />
    </div>
  );
};

export default AutoAddField;
