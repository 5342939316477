import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getPhotoBoothSettings, imagePath, savePhotoBoothSettings } from 'api';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import FileInput from 'components/core/FileInput';
import Form from 'components/core/Form';
import Input from 'components/core/Input';
import ToggleField from 'components/core/ToggleField';
import { useUnload } from 'components/useUnload';
import { Graphic } from 'models/Graphic';
import {
  PhotoBoothOptions,
  emptyPhotoBoothOptions,
} from 'models/PhotoBoothOptions';

const PhotoBoothSettingsForm: React.FC = () => {
  const [options, setOptions] = useState<PhotoBoothOptions>(
    emptyPhotoBoothOptions,
  );
  const [frame, setFrame] = useState<Graphic>({});
  const [background, setBackground] = useState<Graphic>({});
  const [framePath, setFramePath] = useState<string>();
  const [backgroundPath, setBackgroundPath] = useState<string>();
  const [errors, setErrors] = useState<object>({});
  const [dirty, setDirty] = useState<boolean>(false);
  const { locator } = useParams();

  useUnload({
    when: () => dirty,
    message: `You have unsaved changes. Are you sure you want to navigate away?`,
  });

  useEffect(() => {
    updateSettingsState();
  }, [locator]);

  const updateSwitch = (key: string) => {
    return (value: boolean) => {
      console.log('[' + key + ']: ' + value);
      setOptions({ ...options, [key]: value });
      setDirty(true);
    };
  };

  const updateMarketingSignupText = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOptions({ ...options, marketingSignupText: e.target.value });
    setDirty(true);
  };

  const updateSettingsState = () => {
    getPhotoBoothSettings(locator, ({ photoBoothSettings }) => {
      const { frameDigest, backgroundDigest } = photoBoothSettings;

      setFramePath(
        frameDigest
          ? imagePath(
              frameDigest,
              { w: 500, h: 375 },
              { fit: true, format: 'png' },
            )
          : undefined,
      );

      setBackgroundPath(
        backgroundDigest
          ? imagePath(
              backgroundDigest,
              { w: 500, h: 375 },
              { fit: true, format: 'png' },
            )
          : undefined,
      );
      delete photoBoothSettings.frameDigest;
      delete photoBoothSettings.backgroundDigest;
      setOptions(photoBoothSettings);
    });
  };

  const updateFrame = (e: Graphic) => {
    setFrame(e);
    setDirty(true);
  };
  const updateBackground = (e: Graphic) => {
    setBackground(e);
    setDirty(true);
  };

  const onValidationError = (newErrors) => {
    setErrors(newErrors.errors);
  };

  const save = () => {
    const photoBoothSettings: PhotoBoothOptions = {
      offerEmailDelivery: options.offerEmailDelivery,
      offerSmsDelivery: options.offerSmsDelivery,
      offerMarketingSignup: options.offerMarketingSignup,
      marketingSignupText: options.marketingSignupText,
    };

    // Only include the graphic param if the user has uploaded a new one.
    if (frame?.data) {
      photoBoothSettings.frame = frame;
    }

    if (background?.data) {
      photoBoothSettings.background = background;
    }

    savePhotoBoothSettings(
      locator,
      photoBoothSettings,
      () => {
        setErrors({});
        setDirty(false);
      },
      onValidationError,
    );
  };

  const fileError = (errors) => {
    setErrors(errors);
  };

  return (
    <Form errors={errors} data-testid="PhotoBoothSettings">
      <div className="FieldSet">
        <ToggleField
          name="offerEmailDelivery"
          testid="offerEmailDelivery"
          caption="Offer Email Delivery"
          checked={options.offerEmailDelivery}
          onChange={updateSwitch('offerEmailDelivery')}
        >
          Allow fans to deliver their photo with an email
        </ToggleField>
      </div>

      <div className="FieldSet">
        <ToggleField
          name="offerSmsDelivery"
          testid="offerSmsDelivery"
          caption="Offer SMS Delivery"
          checked={options.offerSmsDelivery}
          onChange={updateSwitch('offerSmsDelivery')}
        >
          Allow fans deliver their photo with an SMS
        </ToggleField>
      </div>

      <hr className="Separator" />
      <div className="FieldSet">
        <ToggleField
          name="offerMarketingSignup"
          caption="Offer Marketing Signup"
          checked={options.offerMarketingSignup}
          onChange={updateSwitch('offerMarketingSignup')}
        >
          Allow fans to sign up for marketing emails
        </ToggleField>
        <Input
          optional
          name="marketingSignupText"
          caption="Marketing Signup Text"
          placeholder="Receive promotions, sales, and discounts?"
          value={options.marketingSignupText}
          onChange={updateMarketingSignupText}
        />{' '}
      </div>

      <hr className="Separator" />

      <div className="FieldSet">
        <FileInput
          name="frame"
          testid="frame-file"
          type="file"
          caption="Photo Frame"
          buttonCaption="Upload frame"
          onChange={updateFrame}
          onError={fileError}
          previewUrl={framePath}
          previewWidth="500px"
          previewHeight="375px"
          accept="image/png"
          hint={
            <>
              Transparent PNG
              <br />
              <br />
              Cover sides or bottom
              <br />
              leaving center transparent
            </>
          }
        />
      </div>

      <div className="FieldSet">
        <FileInput
          name="background"
          testid="background-file"
          type="file"
          caption="Menu Background"
          buttonCaption="Upload background"
          onChange={updateBackground}
          onError={fileError}
          previewUrl={backgroundPath}
          previewWidth="500px"
          previewHeight="375px"
          accept="image/jpeg, image/png"
          hint={
            <>
              JPG or PNG Image
              <br />
              <br />
              Cover sides, leaving
              <br />
              center transparent
            </>
          }
        />
      </div>

      <ButtonPanel>
        <Button
          caption="Save"
          onClick={save}
          enabled={Object.keys(errors).length === 0 && dirty}
          primary
        />
      </ButtonPanel>
    </Form>
  );
};

export default PhotoBoothSettingsForm;
