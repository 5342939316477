import { getFans } from 'api';
import { WithBlankSlate } from 'components/BlankSlate';
import IconButton from 'components/core/IconButton';
import Page from 'components/core/Page';
import FansList from 'components/FansList';
import emptyStateImagePath from 'images/fans-empty-state.png';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { triggerDownload } from 'utils';

const FansPage: React.FC = () => {
  const [fansList, setFansList] = useState<object[]>();
  const { locator } = useParams();

  useEffect(() => {
    if (locator) {
      loadFansList(locator);
    }
  }, [locator]);

  const loadFansList = (locator: string) => {
    const success = (list: any[]) => setFansList(list);

    getFans(locator, success);
  };

  const downloadCSV = (e: React.MouseEvent) => {
    e.preventDefault();
    triggerDownload(`${process.env.API_HOST}/events/${locator}/fans.csv`);
  };

  return (
    <Page>
      <h1 className="Page__Title">Fan data</h1>
      <div className="Page__Header">
        <IconButton
          icon="download"
          caption="Download CSV"
          href="#"
          onClick={downloadCSV}
          disabled={!fansList || fansList.length === 0}
        />
      </div>

      <WithBlankSlate
        title="Nothing to report here."
        message="Once you start uploading photos, fans will start rolling in."
        collection={fansList}
        imagePath={emptyStateImagePath}
      >
        <FansList fansList={fansList} />
      </WithBlankSlate>
    </Page>
  );
};

export default FansPage;
