import classNames from 'classnames';
import React, { useState } from 'react';

interface ToggleSwitchProps {
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  testid?: string;
  onChange?: (value: boolean) => void;
}
const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  disabled,
  testid,
  onChange,
}: ToggleSwitchProps) => {
  // changed is used to add an transition class only if the user clicks the component
  // and avoid the transition the first time the element is rendered
  const [changed, setChanged] = useState<boolean>(false);

  const toggleSwitch = () => {
    if (disabled) {
      return;
    }

    setChanged(true);
    onChange?.(!checked);
  };

  const togglerClasses = classNames('ToggleSwitch__Toggler', {
    'ToggleSwitch__Toggler--on': checked,
    'ToggleSwitch__Toggler--off': !checked,
    'ToggleSwitch__Toggler--changed': changed,
  });

  return (
    <div className="ToggleSwitch" data-testid={testid} onClick={toggleSwitch}>
      <div className={togglerClasses} />
    </div>
  );
};

export default ToggleSwitch;
