import { resetPassword } from 'api';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import ErrorBar from 'components/core/ErrorBar';
import Input from 'components/core/Input';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Copyright from './Copyright';
import FooteLinks from './FooterLinks';

const ResetPasswordForm: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [formEnabled, setFormEnabled] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { token } = useParams();

  useEffect(() => {
    setFormEnabled(password.length > 4);
  }, [password]);

  const handleNewPassword = (e) => {
    e.preventDefault();
    setError(null);
    setSubmitting(true);

    const success = () => {
      setSubmitting(false);
      setPasswordChanged(true);
    };

    const failure = (json) => {
      setSubmitting(false);
      setError(json.reason || 'Something went wrong');
    };

    resetPassword(token ?? '', password, success, failure);
  };

  if (passwordChanged) {
    return (
      <div className="FullPageForm" data-testid="password-changed">
        <h1 className="FullPageForm__Title">Password changed</h1>
        <p className="FullPageForm__Description">
          You can now login with your new password.
        </p>
        <Link className="Button Button--primary" to="/login">
          Log in
        </Link>

        <footer className="FullPageForm__Footer">
          <p>
            <Link to="/login">Go back to the login page</Link>
          </p>
          <FooteLinks />
          <Copyright />
        </footer>
      </div>
    );
  }

  return (
    <form
      className="ResetPasswordForm FullPageForm"
      onSubmit={handleNewPassword}
    >
      <h1 className="FullPageForm__Title">Reset your password</h1>
      <ErrorBar error={error} />
      <Input
        type="password"
        name="password"
        testid="password"
        caption="New password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
      />
      <div className="ResetPasswordForm__Buttons">
        <ButtonPanel>
          <Button
            primary
            caption="Reset password"
            type="submit"
            busyMessage={submitting ? 'Resetting password...' : undefined}
            enabled={formEnabled}
          />
        </ButtonPanel>
      </div>
      <footer className="FullPageForm__Footer">
        <p>
          Never mind, <Link to="/login">go back to the login page</Link>
        </p>
        <span className="FullPageForm__Footer--right">
          Privacy Policy | Terms and Conditions
        </span>
        <Copyright />
      </footer>
    </form>
  );
};

export default ResetPasswordForm;
