import React from 'react';
import './styles.scss';

interface Props {
  children?: React.ReactNode;
  actions?: React.ReactNode;
}

const PageHeader: React.FC<Props> = ({ children, actions }) => {
  return (
    <div className="PageHeader">
      <h1 className="PageHeader__Title">{children}</h1>
      <div className="PageHeader__Actions">{actions}</div>
    </div>
  );
};

export default PageHeader;
