import SortableTable, { CustomSorters } from 'components/SortableTable';
import notesIconPath from 'images/icons/notes.svg';
import TicketPurchase, { hasNote } from 'models/TicketPurchase';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';

interface TicketPurchasesListProps {
  ticketPurchases: TicketPurchase[];
}
const customSorters: CustomSorters<TicketPurchase> = {
  checkedIn: (desc) => (a, b) => {
    const tickets1 = a.tickets;
    const tickets2 = b.tickets;
    let result;

    const checkedInCount1 = tickets1.filter((t) => t.checkedIn).length;
    const checkedInCount2 = tickets2.filter((t) => t.checkedIn).length;
    const checkedInPercent1 = checkedInCount1 / tickets1.length;
    const checkedInPercent2 = checkedInCount2 / tickets2.length;

    if (checkedInPercent1 !== checkedInPercent2) {
      result = checkedInPercent1 - checkedInPercent2;
    } else {
      result = tickets1.length - tickets2.length;
    }

    return desc ? result : -result;
  },
};

const TicketPurchasesList: React.FC<TicketPurchasesListProps> = ({
  ticketPurchases,
}: TicketPurchasesListProps) => (
  <SortableTable<TicketPurchase>
    headers={['First', 'Last', 'Tickets', 'Notes', 'Checked in']}
    keys={['firstName', 'lastName', 'quantity', 'notes', 'checkedIn']}
    items={ticketPurchases}
    className="TicketPurchases"
    mapper={(tp) => <TicketPurchaseRow ticketPurchase={tp} key={tp.id} />}
    sorters={customSorters}
    defaultSortKey="lastName"
  />
);

interface TicketPurchaseRowProps {
  ticketPurchase: TicketPurchase;
}

const TicketPurchaseRow: React.FC<TicketPurchaseRowProps> = ({
  ticketPurchase,
}: TicketPurchaseRowProps) => {
  const navigate = useNavigate();
  const { locator, scheduleItemId } = useParams<{
    locator: string;
    scheduleItemId: string;
  }>();
  const ticketPurchasePath = `/event/${locator}/ticketPurchases/${scheduleItemId}/${ticketPurchase.id}`;

  const openTicketPurchasePath = () => navigate(ticketPurchasePath);

  return (
    <tr className="TicketPurchasesList__TicketRow">
      <td onClick={openTicketPurchasePath}>{ticketPurchase.firstName}</td>
      <td onClick={openTicketPurchasePath}>{ticketPurchase.lastName}</td>
      <td onClick={openTicketPurchasePath}>{ticketPurchase.quantity}</td>
      <td
        onClick={() => {
          navigate(ticketPurchasePath + '/notes');
        }}
      >
        {hasNote(ticketPurchase) && <img src={notesIconPath} />}
      </td>
      <td>{ticketPurchase.tickets.map((t) => (t.checkedIn ? '●' : '○'))}</td>
    </tr>
  );
};

export default TicketPurchasesList;
