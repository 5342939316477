import RCTooltip from 'rc-tooltip';
import React from 'react';
import './style.scss';

const Tooltip = ({ tooltip, children }) => {
  return (
    <RCTooltip
      placement="top"
      overlay={<span>{tooltip}</span>}
      mouseLeaveDelay={0.01}
    >
      {children}
    </RCTooltip>
  );
};

export default Tooltip;
