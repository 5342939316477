export type Schedule = ScheduleItem[];

export interface ScheduleItem {
  id?: number;
  description?: string;
  location?: string;
  password?: string;
  hidden?: boolean;
  startsAt?: string;
  time?: string;
  lastPostAt?: Date | string;
  ticketsCount?: number;
  bandsintownEventId?: string | number;
  postCount?: number;
}

// extends ScheduleItem to require valid value(s)
export interface ValidScheduleItem extends ScheduleItem {
  startsAt: string;
}

export const emptyScheduleItem: ScheduleItem = {
  location: '',
  description: '',
  password: '',
  hidden: false,
  startsAt: undefined,
  time: undefined,
};
