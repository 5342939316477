import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

const DEFAULT_EXPIRY_TIME = 5000;

interface AlertMessageDetail {
  msg: string;
  type: string;
  expiryTime?: any;
}
interface AlertMessageType {
  detail: AlertMessageDetail;
}

// Alert messages are dismissable notifications
interface AlertMessageProps {
  type: any;
  msg: any;
  onDismiss?: () => void;
}
export const AlertMessage: React.FC<AlertMessageProps> = ({
  type,
  msg,
  onDismiss,
}: AlertMessageProps) => {
  return (
    <div className={`Alert Alert--${type}`}>
      <p>{msg}</p>

      <button
        type="button"
        className="Alert__Close"
        aria-label="Close"
        onClick={onDismiss}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

// Alert states are used to show errors that depend on a component state
interface AlertStateProps {
  type: any;
  children?: JSX.Element | JSX.Element[] | null;
}
export const AlertState: React.FC<AlertStateProps> = ({
  type,
  children,
}: AlertStateProps) => {
  const root = document.getElementById('AlertPortal');

  if (!root) {
    console.log('Unable to render alert -- no portal found');
    return null;
  }

  return ReactDOM.createPortal(
    <div className={`Alert Alert--${type}`}>{children}</div>,
    root,
  ) as React.ReactNode;
};

interface AlertMessages {
  //
}
const AlertMessages: React.FC = (props) => {
  const location = useLocation();
  const [oldLocaton, setOldLocation] = useState<string>(location.pathname);
  const [msgs, setMsgs] = useState<AlertMessageDetail[]>([]);

  useEffect(() => {
    if (location.pathname !== oldLocaton) {
      dismiss();
      setOldLocation(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    document.addEventListener('appAlert', addMessage as any);
    return () => {
      document.removeEventListener('appAlert', addMessage as any);
    };
  }, []);

  const addMessage = (e: AlertMessageType) => {
    const { type, expiryTime } = e.detail;

    const newItem: AlertMessageDetail = { msg: e.detail.msg, type: type };
    let newMessages = msgs;

    const existing = msgs.find(
      (m: AlertMessageDetail) =>
        m.msg === newItem.msg && m.type === newItem.type,
    );

    if (!existing) {
      newMessages = msgs.concat(newItem);
    }

    if (expiryTime) {
      setTimeout(() => removeItem(newItem), expiryTime);
    }

    setMsgs(newMessages);
  };

  const removeItem = (item: AlertMessageDetail) => {
    const remainingMessages = msgs.filter((t) => t !== item);
    setMsgs(remainingMessages);
  };

  const dismiss = () => {
    setMsgs([]);
  };

  const dismissMessage = (msg: AlertMessageDetail) => {
    setMsgs(msgs.filter((m) => m !== msg));
  };

  return (
    <div className="AlertsArea" id="AlertPortal">
      <div className="Alert__Messages">
        {/* <TransitionGroup
          component={React.Fragment}
          transitionName="AlertChange"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        > */}
        {msgs.map((m) => (
          <AlertMessage
            type={m.type}
            msg={m.msg}
            key={`${m.type}-${m.msg}`}
            onDismiss={() => {
              dismissMessage(m);
            }}
          />
        ))}
        {/* </TransitionGroup> */}
      </div>
    </div>
  );
};

export const showInfoMsg = (msg: string) => {
  alert(msg, 'info', DEFAULT_EXPIRY_TIME);
};

export const showErrorMsg = (msg: string, expiryTime?: number) => {
  alert(msg, 'error', expiryTime ?? -1);
};

export const alert = (msg: string, type: string, expiryTime: number) => {
  document.dispatchEvent(
    new CustomEvent('appAlert', { detail: { msg, type, expiryTime } }),
  );
};

export default AlertMessages;
