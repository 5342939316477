import NoteModel, { time } from 'models/Note';
import React from 'react';
import './style.scss';

interface Props {
  note: NoteModel;
}

const Note: React.FC<Props> = ({ note }) => {
  const content = note.content.split('\n').map((para, idx) => (
    <p className="Note__Paragraph" key={idx}>
      {para}
    </p>
  ));

  return (
    <div className="Note">
      <div className="Note__Header">
        <span className="Note__User">{note.user.email}</span>{' '}
        <span className="Note__Time">{time(note)}</span>
      </div>
      <div className="Note__Content">{content}</div>
    </div>
  );
};

export default Note;
