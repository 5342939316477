import React from 'react';
import classNames from 'classnames';
import './style.scss';

const DashboardLoadingCard = ({ className = '' }) => {
  const classnames = classNames('DashboardLoadingCard', className);

  return (
    <div className={classnames} data-testid="DashboardLoadingCard">
      <div className="DashboardLoadingCard__Message">Loading...</div>
    </div>
  );
};

export default DashboardLoadingCard;
