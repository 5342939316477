import React from 'react';
import { createRoot } from 'react-dom/client';
import ApplicationError from 'components/ApplicationError';
import { BrowserRouter } from 'react-router-dom';
import App from 'components/App';
import './styles/styles.scss';

if (process.env.GIT_VERSION) {
  console.log(`StagePilot VIP Admin version ${process.env.GIT_VERSION}`);
}

const rootDOM = createRoot(document.getElementById('root'));
rootDOM.render(
  <BrowserRouter>
    <ApplicationError>
      <App />
    </ApplicationError>
  </BrowserRouter>,
);
