import classNames from 'classnames';
import Color from 'models/Color';
import TicketPurchase from 'models/TicketPurchase';
import React, { useEffect, useState } from 'react';
import './styles.scss';

const ALL_TICKETS = 'All tickets';

export interface EventCheckInStats {
  eventId?: number;
  scheduleItemId?: number;
  ticketPackageId: number;
  ticketPackageName: string;
  ticketPackageColor: Color;
  ticketsCount: number;
  checkedInCount: number;
}

interface CheckInStatsProps {
  selectedCategory?: string;
  setSelectedCategory?: (type: string) => void;
  purchases?: TicketPurchase[];
  staticCheckInStats?: EventCheckInStats[];
}

const CheckInStats = ({
  selectedCategory,
  setSelectedCategory,
  purchases,
  staticCheckInStats,
}: CheckInStatsProps) => {
  const [stats, setStats] = useState<EventCheckInStats[]>([]);
  const clickTicketPackage = (clicked: string) => {
    if (clicked === ALL_TICKETS) {
      setSelectedCategory?.('');
    } else if (selectedCategory === clicked) {
      setSelectedCategory?.('');
    } else {
      setSelectedCategory?.(clicked);
    }
  };

  useEffect(() => {
    if (!purchases || !Array.isArray(purchases) || purchases.length < 1) return;

    const statsMap = new Map<number, EventCheckInStats>();

    // Scan the ticket purchases for ticket packages
    purchases
      .map((p) => p.ticketPackage)
      .forEach((tp) => {
        if (!statsMap.has(tp.id)) {
          statsMap.set(tp.id, {
            ticketPackageId: tp.id,
            ticketPackageName: tp.name,
            ticketsCount: 0,
            checkedInCount: 0,
            ticketPackageColor: new Color(tp.color),
          });
        }
      });

    let totalTickets = 0;
    let totalCheckedInCount = 0;
    // Now tally up the ticket check-ins
    purchases.forEach((p) => {
      let s = statsMap.get(p.ticketPackage.id);
      p.tickets.forEach((t) => {
        s && s.ticketsCount++;
        totalTickets++;
        if (t.checkedIn) {
          s && s.checkedInCount++;
          totalCheckedInCount++;
        }
      });
    });

    const allTicketStats = {
      ticketPackageId: -1,
      ticketPackageColor: new Color('#EEEEEE'),
      ticketPackageName: ALL_TICKETS,
      ticketsCount: totalTickets,
      checkedInCount: totalCheckedInCount,
    };

    const stats = Array.from(statsMap.values());
    // add All Ticket stats to the top of the list
    stats.push(allTicketStats);
    setStats(stats);
  }, [purchases]);

  useEffect(() => {
    if (
      !staticCheckInStats ||
      !Array.isArray(staticCheckInStats) ||
      staticCheckInStats.length < 1
    )
      return;

    let totalTickets = 0;
    let totalCheckedInCount = 0;

    // build totals
    staticCheckInStats.forEach((p) => {
      totalTickets += p.ticketsCount;
      totalCheckedInCount += p.checkedInCount;
    });

    const allTicketStats = {
      ticketPackageId: -1,
      ticketPackageColor: new Color('#EEEEEE'),
      ticketPackageName: ALL_TICKETS,
      ticketsCount: totalTickets,
      checkedInCount: totalCheckedInCount,
    };

    staticCheckInStats.forEach((tp) => {
      tp.ticketPackageColor = new Color(`${tp.ticketPackageColor}`);
    });
    const staticStats = Array.from(staticCheckInStats);

    // add All Ticket stats to the top of the list
    staticStats.push(allTicketStats);
    setStats(staticStats);
  }, [staticCheckInStats]);

  return (
    <div className="CheckInStats" data-testid="CheckinStats-container">
      <div className="CheckInStats__Title">Check-Ins</div>
      <div className="CheckInStats__Stats">
        {stats &&
          stats.map((s: any) => (
            <div
              className={classNames('PackageCheckIn', {
                'PackageCheckIn--inactive':
                  selectedCategory && selectedCategory !== s.ticketPackageName,
              })}
              key={s.ticketPackageId}
              id={s.ticketPackageId}
              onClick={() => {
                purchases && clickTicketPackage(s.ticketPackageName);
              }}
              style={{
                backgroundColor: s.ticketPackageColor.hex,
                color: s.ticketPackageColor.textColor,
              }}
            >
              <div className="PackageCheckIn__Title">{s.ticketPackageName}</div>
              <div className="PackageCheckIn__Count">
                {s.checkedInCount} / {s.ticketsCount}
              </div>
              <div className="PackageCheckIn__Percent">
                {Math.floor((s.checkedInCount / s.ticketsCount) * 100)}%
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CheckInStats;
