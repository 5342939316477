import React, { useEffect, useRef, useState } from 'react';

const minOfZero = (input: number) => (input > 0 ? input : 0);

interface RecyclerViewProps {
  className?: string;
  itemHeight: number;
  children: JSX.Element[];
}

const RecyclerView: React.FC<RecyclerViewProps> = ({
  className,
  itemHeight,
  children,
}: RecyclerViewProps) => {
  const container = useRef<HTMLDivElement>(null);
  const [animationFrameRequest, setAnimationFrameRequest] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    updateScrollPosition();
    return () => cancelAnimationFrame(animationFrameRequest);
  }, []);

  const updateScrollPosition = () => {
    const current = container.current ?? { clientHeight: 0, scrollTop: 0 };
    const pos = Math.floor(current.scrollTop / itemHeight);
    setHeight(current.clientHeight);
    setScrollPosition(pos);
    setAnimationFrameRequest(requestAnimationFrame(updateScrollPosition));
  };

  const numberItemsToShow = Math.floor(height / itemHeight) + 5;
  const startPosition = minOfZero(scrollPosition);
  const endPosition =
    scrollPosition + numberItemsToShow >= children.length
      ? children.length
      : scrollPosition + numberItemsToShow;

  const paddingTop = startPosition * itemHeight;
  const paddingBottom = minOfZero((children.length - endPosition) * itemHeight);

  return (
    <div ref={container} className={className}>
      <div key="padding-top" style={{ height: paddingTop }} />
      {children?.slice(startPosition, endPosition)}
      <div key="padding-bottom" style={{ height: paddingBottom }} />
    </div>
  );
};

export default RecyclerView;
