import { AppContext, IAppContext } from 'context';
import { User } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// This components adds the Intercom integration.  It will render every time the
// location changes, or the user context changes.  It integrates by sending an
// initial `boot` message, followed by `update` messages for each change.  If we
// find that we no longer have a user (meaning, the user logged out) then we
// send a `shutdown` message to make the session anonymous again.
//
// For more information, see the docs:
//   https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app

interface IntercomSyncProps {
  user: User;
}

const IntercomSync: React.FC<IntercomSyncProps> = () => {
  const [booted, setBooted] = useState(false);
  const location = useLocation();
  const { user } = useContext<IAppContext>(AppContext);

  const notify = () => {
    const method = booted ? 'update' : 'boot';

    const payload = {
      app_id: window['intercomSettings']?.app_id,
    };

    if (user) {
      Object.assign(payload, {
        user_id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });
    }

    window['Intercom'] && window['Intercom'](method, payload);
    setBooted(true);
  };

  const shutdown = () => {
    window['Intercom'] && window['Intercom']('shutdown');
    setBooted(false);
  };

  useEffect(() => {
    if (booted && !user) {
      shutdown();
    } else {
      notify();
    }
  }, [location, user]);
  return null;
};

export default IntercomSync;
