import classNames from 'classnames';
import DropTarget from 'components/DropTarget';
import React, { useEffect, useState } from 'react';

interface FileInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  accept?: string;
  buttonCaption?: string;
  caption: string;
  dimensions?: { width: number; height: number };
  encodeBase64?: boolean;
  fullWidth?: boolean;
  inlineCaption?: boolean;
  hint?: JSX.Element | string;
  name?: string;
  outline?: boolean;
  preview?: any;
  previewHeight?: string;
  previewUrl?: string;
  previewWidth?: string;
  testid?: string;
  onChange?: (image: any) => void;
  onError?: (e: any) => void;
}

const FileInput: React.FC<FileInputProps> = ({
  accept,
  buttonCaption,
  caption,
  dimensions,
  encodeBase64 = true,
  fullWidth,
  hint,
  inlineCaption,
  name,
  outline,
  preview,
  previewHeight,
  previewUrl,
  previewWidth,
  testid,
  onChange,
  onError,
}: FileInputProps) => {
  const [file, setFile] = useState<any>(null);
  const [value, setValue] = useState<any>(undefined);

  useEffect(() => {
    if (!file) return; // Wait until file state is updated
    if (isValidType(file.type)) {
      loadFile(file);
    } else {
      handleError('invalid file type');
    }
  }, [file]);

  const loadFile = async (file) => {
    if (!encodeBase64) {
      onChange?.(file);
      return;
    }

    try {
      const result = await readFileAsDataURL(file);
      if (dimensions) {
        await checkImageDimensions(result);
      }

      setValue(result);
      onChange?.({ file: file.name, data: result });
    } catch (error: any) {
      handleError(error.message);
    }
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        if (result === null) {
          reject(new Error('error reading file'));
        } else {
          resolve(result as string);
        }
      };
      reader.onerror = () => {
        reject(new Error('error reading file'));
      };
      reader.readAsDataURL(file);
    });
  };

  const checkImageDimensions = (dataURL) => {
    return new Promise<void>((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width !== dimensions?.width || height !== dimensions?.height) {
          reject(new Error('invalid image dimensions'));
        } else {
          resolve();
        }
      };
      image.onerror = () => {
        reject(new Error('error loading image'));
      };
      image.src = dataURL;
    });
  };

  const isValidType = (mimeType) => {
    if (accept) {
      const acceptedTypes = accept.split(',').map((t) => t.trim());
      if (acceptedTypes.indexOf(mimeType) === -1) {
        return false;
      }
    }
    return true;
  };
  const handleError = (errorMessage: string) => {
    onError?.({ [caption]: [errorMessage] });
  };

  const onFileFieldChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;
    setFile(selectedFile);
  };

  const dropTargetChanged = (file) => {
    if (!file) return;
    setFile(file);
  };

  const className = classNames('Input', 'Input--tall', 'Input__File', {
    'Input__File--inline-caption': inlineCaption,
  });

  const buttonClasses = classNames('Button', 'Input__FileButton', {
    'Button--primary': !outline,
  });

  let _preview;
  let previewStyle = {};

  if (typeof preview === 'undefined') {
    // If the parent component didn't specify a preview, show
    // an img with the uploaded file, if we have one
    if (value || previewUrl) {
      _preview = <img src={value || previewUrl} />;
      previewStyle = {
        maxWidth: previewWidth,
        height: previewHeight,
      };
    }
  } else {
    _preview = preview;
  }

  return (
    <label className={className}>
      <span
        className={classNames('Input__Caption', {
          'Input__Caption--fullWidth': fullWidth,
        })}
      >
        {caption}
      </span>

      <DropTarget
        className="Input__File Input__Control Input__FileArea"
        accept={accept}
        onError={onError}
        uploadFile={dropTargetChanged}
      >
        {!_preview ? (
          <div className="Input__FileInputInlineCaption">{caption}</div>
        ) : (
          <></>
        )}
        <div className="Input__FileInputPreview" style={previewStyle}>
          {_preview ?? <></>}
        </div>
        <input
          className={classNames('Input__File', 'Input__FileControl', {
            'Input__Control--fullWidth': fullWidth,
          })}
          type="file"
          accept={accept}
          name={name}
          data-testid={testid}
          onChange={onFileFieldChange}
        />

        <div className={buttonClasses}>{buttonCaption ?? 'Upload file'}</div>

        {hint ? (
          <div className="Input__Hint">
            <p>{hint}</p>
          </div>
        ) : (
          <></>
        )}
      </DropTarget>
    </label>
  );
};

export default FileInput;
