const textColors = {
  BLACK: '#000000',
  WHITE: '#ffffff',
};

export default class Color {
  hex: string;
  constructor(hex: string) {
    this.hex = hex;
  }

  hexToDecimal = (hex_string: string) => {
    return parseInt(hex_string, 16);
  };

  getLuminance = (hex: string) => {
    const rgb = this.hexToRGB(hex);
    for (const key in rgb) {
      let c = rgb[key];
      c /= 255;

      c = c > 0.03928 ? Math.pow((c + 0.055) / 1.055, 2.4) : (c /= 12.92);

      rgb[key] = c;
    }

    return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
  };

  contrastRatioPair = (hex1: string, hex2: string) => {
    const lum1 = this.getLuminance(hex1);
    const lum2 = this.getLuminance(hex2);

    return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
  };

  hexToRGB = (hex: string) => {
    const r = this.hexToDecimal(hex.substring(1, 3));
    const g = this.hexToDecimal(hex.substring(3, 5));
    const b = this.hexToDecimal(hex.substring(5, 7));

    return { r, g, b };
  };

  contrastRatioWith(hex2: string) {
    return this.contrastRatioPair(this.hex, hex2);
  }

  get luminance() {
    return this.getLuminance(this.hex);
  }

  get textColor() {
    const { BLACK, WHITE } = textColors;

    return this.contrastRatioWith(BLACK) > this.contrastRatioWith(WHITE)
      ? BLACK
      : WHITE;
  }
}
