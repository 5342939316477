export const getArtistInfo = async (artist) => {
  if (!artist) {
    return {};
  }

  const url = encodeURI(
    `https://rest.bandsintown.com/artists/${artist}?app_id=Arcivr`,
  );

  const resp = await fetch(url);
  if (resp.status !== 200) {
    return {};
  }

  const json = await resp.json();
  if (!json.url) {
    return {};
  }

  return {
    url: json.url,
    name: json.name,
    icon: json.thumb_url,
  };
};
