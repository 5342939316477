import classNames from 'classnames';
import React from 'react';

type ButtonType = 'button' | 'submit' | 'reset' | undefined;

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  id?: string;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  danger?: boolean;
  outline?: any;
  caption?: string;
  enabled?: boolean;
  busyMessage?: string;
  confirmMessage?: string;
  testid?: string;
}

const Button = ({
  id,
  testid,
  primary,
  secondary,
  tertiary,
  danger,
  outline,
  caption,
  onClick,
  onMouseOver,
  onMouseLeave,
  onBlur,
  enabled = true,
  busyMessage,
  confirmMessage,
  children,
  type = 'button',
  className = '',
}: ButtonProps) => {
  const classes = classNames('Button', className, {
    'Button--primary': primary,
    'Button--secondary': secondary,
    'Button--tertiary': tertiary,
    'Button--danger': danger,
    'Button--outline': outline,
  });

  let clickHandler = onClick;
  let mouseOverHandler = onMouseOver;
  let mouseLeaveHandler = onMouseLeave;

  if (confirmMessage && onClick) {
    clickHandler = (e) => window.confirm(confirmMessage) && onClick(e);
  }

  return (
    <button
      id={id}
      type={type as ButtonType}
      className={classes}
      data-testid={testid}
      onClick={clickHandler}
      onMouseOver={mouseOverHandler}
      onMouseLeave={mouseLeaveHandler}
      disabled={!!busyMessage || !enabled}
      onBlur={onBlur}
    >
      {busyMessage ?? caption ?? children}
    </button>
  );
};

export default Button;
