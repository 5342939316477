import React from 'react';
import { NavLink } from 'react-router-dom';

export const Tabs = ({ children }) => <div className="Tabs">{children}</div>;

export const Tab = ({ path, children }) => (
  <NavLink
    to={path}
    className={({ isActive }) => (isActive ? 'Tab Tab--active' : 'Tab')}
    end
  >
    {children}
  </NavLink>
);
