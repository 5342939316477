import { updateEvent as putUpdatedEvent } from 'api';
import { showInfoMsg } from 'components/core/AlertMessages';
import EventSettingsForm from 'components/EventSettingsForm';
import { AppContext, IAppContext } from 'context';
import React, { useContext } from 'react';

const EditEventSettingsForm: React.FC = () => {
  const { currentEvent, updateEvent } = useContext<IAppContext>(AppContext);

  const onSave = (
    eventAttributes?: any,
    successCallback?: () => void,
    errorCallback?: (errors: any) => void,
  ) => {
    const success = (result) => {
      successCallback?.();
      updateEvent(result.event);
      showInfoMsg(`Event saved`);
    };

    putUpdatedEvent(
      currentEvent?.locator,
      eventAttributes,
      success,
      errorCallback,
    );
  };

  return <EventSettingsForm event={currentEvent} onSave={onSave} />;
};

export default EditEventSettingsForm;
