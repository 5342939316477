import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext, IAppContext } from 'context';
import { getScheduleItem, getTicketPurchase } from 'api';
import { formatDate } from 'api/util';
import { Breadcrumb } from 'components/core/Breadcrumb';
import Page from 'components/core/Page';
import PageHeader from 'components/core/PageHeader';
import PageLoadingIndicator from 'components/core/PageLoadingIndicator';
import { MerchandiseItem, ScheduleItem } from 'models';
import Ticket from 'models/Ticket';
import TicketPurchase from 'models/TicketPurchase';

function keyify(s: any) {
  return s.toString().replace(/[^a-z0-9]+/g, '-');
}

type MerchandiseItemRow = [string | boolean];

/**
 * Take the purchased tickets and merchandise items and return a structure for
 * tabular display (i.e. a 2D array).
 *
 * @param merchItems
 * @param tickets
 */
const columnizeMerch = (merchItems?: MerchandiseItem[], tickets?: Ticket[]) => {
  let table: MerchandiseItemRow[] = [];

  if (!merchItems || !tickets) return table;

  // Header row
  const header: string[] = [''];
  for (const mi of merchItems) {
    header.push(mi.name);
  }
  table.push(header as MerchandiseItemRow);

  for (const tix of tickets) {
    let row: any[] = [tix.name];
    for (const mi of merchItems) {
      let claimed = false;
      for (const mh of tix.merchandiseHandovers || []) {
        if (mh.merchandiseItemId === mi.id && !mh.deletedAt) {
          claimed = true;
        }
      }
      row.push(claimed);
    }
    table.push(row as MerchandiseItemRow);
  }

  return table;
};

const TicketPurchasePage: React.FC = () => {
  const [ticketPurchase, setTicketPurchase] = useState<TicketPurchase>();
  const [scheduleItem, setScheduleItem] = useState<ScheduleItem>();
  const [loading, setLoading] = useState<boolean>(true);
  const { currentEvent } = useContext<IAppContext>(AppContext);

  // const [merchTable, setMerchTable] = useState<any[][]>([[]]);
  const { locator, scheduleItemId, id } = useParams();

  const purchase = ticketPurchase;
  const merchTable = columnizeMerch(
    purchase?.ticketPackage.merchandiseItems,
    purchase?.tickets,
  );

  const ticketsPath = `/event/${locator}/ticketPurchases`;
  const siPath = `/event/${locator}/ticketPurchases/${scheduleItemId}`;
  const notesPath = `${siPath}/${id}/notes`;

  useEffect(() => {
    loadTicketPurchase();
  }, [currentEvent, locator, scheduleItemId, id]);

  const loadTicketPurchase = async () => {
    if (!currentEvent) return;

    let newScheduleItem = await getScheduleItem(
      locator ?? '',
      scheduleItemId ?? '',
    );
    let newTicketPurchase = await getTicketPurchase(
      currentEvent.locator,
      scheduleItemId,
      id,
    );

    setTicketPurchase(newTicketPurchase as TicketPurchase);
    setScheduleItem(newScheduleItem as ScheduleItem);
    setLoading(false);
  };

  const notesLink = (
    <Link to={notesPath} className="Button Button--primary">
      Notes
    </Link>
  );

  const currentScheduleItem = scheduleItem;
  if (!currentScheduleItem) return <PageLoadingIndicator />;
  const scheduleItemDesc = [
    currentScheduleItem?.location,
    formatDate(currentScheduleItem?.startsAt),
  ].join(' - ');
  const ticketName = [purchase?.firstName, purchase?.lastName].join(' ');

  const headers =
    merchTable.length > 0 ? merchTable[0].slice(1).map((s) => keyify(s)) : [];

  return (
    <>
      {loading ? (
        <PageLoadingIndicator />
      ) : (
        <Page>
          <>
            <PageHeader actions={notesLink}>
              <Breadcrumb to={ticketsPath} title="Ticket data" />
              <Breadcrumb to={siPath} title={scheduleItemDesc} />
              {ticketName}
            </PageHeader>

            <section className="TicketPurchasePage__BodySection">
              <h4 className="TicketPurchasePage__BodySectionHeader">
                Purchase details
              </h4>
              <PurchaseDetail label="Tickets" value={purchase?.quantity} />
              <PurchaseDetail label="Email" value={purchase?.email} />
              <PurchaseDetail
                label="Package"
                value={purchase?.ticketPackage.name}
              />
              <PurchaseDetail
                label="Imported Package"
                value={purchase?.package}
              />
              <PurchaseDetail
                label="Seat location"
                value={purchase?.seatLocation}
              />
              <PurchaseDetail label="Row" value={purchase?.row} />
              <PurchaseDetail
                label="Order number"
                value={purchase?.originalOrderNumber}
              />
              <PurchaseDetail label="Phone" value={purchase?.phone} />

              <hr className="Separator" />

              {purchase
                ? Object.entries(purchase.customFields).map(([k, v]) => (
                    <PurchaseDetail label={k} value={v} key={k} />
                  ))
                : null}
            </section>

            {purchase?.ticketPackage.merchandiseEnabled &&
            merchTable?.length ? (
              <div>
                <h4 className="TicketPurchasePage__BodySectionHeader">
                  Merchandise
                </h4>
                {purchase?.ticketPackage.merchandiseItems && (
                  <ul>
                    {purchase?.ticketPackage.merchandiseItems.map((mi) => (
                      <li key={'merch_items_' + mi.id}>{mi.name}</li>
                    ))}
                  </ul>
                )}

                <table className="TicketPurchasePage__MerchTable">
                  <thead>
                    <tr>
                      {merchTable[0].map((col) => (
                        <th key={keyify('col-' + col)}>{col}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {merchTable.slice(1).map((row) => (
                      <tr key={keyify('row-' + row[0])}>
                        <td className="TicketPurchasePage__MerchTable_Name">
                          {row[0]}
                        </td>
                        {row.slice(1).map((cell, i) => (
                          <td
                            className="TicketPurchasePage__MerchTable_Cell"
                            key={keyify(row[0]) + '_' + headers[i]}
                          >
                            <input
                              readOnly
                              disabled
                              type="checkbox"
                              checked={cell as boolean}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </>
        </Page>
      )}
    </>
  );
};

const PurchaseDetail = ({ label, value }) => {
  return (
    <p className="PurchaseDetails">
      <span className="PurchaseDetails__Label">{label}:</span>
      <span className="PurchaseDetails__Value">{value}</span>
    </p>
  );
};

export default TicketPurchasePage;
