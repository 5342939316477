import classNames from 'classnames';
import Tooltip from 'components/core/Tooltip';
import DashboardEmptyCard from 'components/DashboardEmptyCard';
import React from 'react';
import './style.scss';

const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const HOUR_NAMES = [
  '12am-4am',
  '4am-8am',
  '8am-12pm',
  '12pm-4pm',
  '4pm-8pm',
  '8pm-12pm',
];

export class Day {
  day: number;
  name: string;
  hours: Hour[];

  constructor(day: number, hours: Hour[]) {
    this.day = day;
    this.hours = hours;
    this.name = WEEKDAYS[day - 1];
  }
}

export class Hour {
  label: string;
  ratio: number;

  constructor(label: string, ratio: number) {
    this.label = label;
    this.ratio = ratio;
  }
}

const thresholds = [0.75, 0.5, 0.25, 0.0001, 0];

const HourCell = ({ Hour }) => {
  const ratio = Hour.ratio;

  const threshold = thresholds.length - thresholds.findIndex((t) => ratio >= t);

  const className = classNames(
    'HourlyTable__hour',
    `HourlyTable__ratio${threshold}`,
  );

  return (
    <Tooltip tooltip={Hour.label}>
      <span className={className} />
    </Tooltip>
  );
};

export const HourlyTable: React.FunctionComponent<{
  header: string;
  title: string;
  days: Day[];
  empty: boolean;
}> = ({ header, title, days, empty }) => {
  return (
    <div className="HourlyTable">
      <h3 className="DashboardCard__Date">{header}</h3>
      <h1 className="DashboardCard__Title">{title}</h1>

      {empty ? <DashboardEmptyCard /> : <HourCells days={days} />}
    </div>
  );
};

const HourCells: React.FunctionComponent<{ days: Day[] }> = ({ days }) => {
  return (
    <div className="DashboardCard__Content">
      {days.map((d, di) => (
        <div className="HourlyTable__day" key={`day${di}`}>
          {d.hours.map((h, hi) => (
            <HourCell Hour={h} key={`day${di}-hour${hi}`} />
          ))}
          <div className="HourlyTable__dayName">{d.name}</div>
        </div>
      ))}

      <div className="HourlyTable__HourNames">
        {HOUR_NAMES.map((h, hi) => (
          <div className="HourlyTable__HourName" key={`hourName${hi}`}>
            {h}
          </div>
        ))}
      </div>
    </div>
  );
};
