import {
  getDownloadsByLocation,
  getProductByProfitMetrics,
  getProductByVolumeMetrics,
  getSalesByLocation,
} from 'api';
import { moneyFormatter, numberFormatter } from 'api/util';
import DashboardCard from 'components/DashboardCard';
import { DashboardQueryProps } from 'models';
import React, { useEffect, useState } from 'react';
import './style.scss';

export interface ReportProps extends DashboardQueryProps {
  title: string;
  fetchFn: (
    locator: string | undefined,
    query: string | undefined,
    success?: (result: { total: any; values: any }) => void,
    fail?: (error: any) => void,
  ) => void;
  formatter: (value: number) => string;
}

export const ProductsByProfitReport: React.FC<DashboardQueryProps> = ({
  locator,
  query,
  dateRange,
  ...etc
}: DashboardQueryProps) => (
  <DashboardReport
    title="Top products by profit"
    formatter={moneyFormatter}
    fetchFn={getProductByProfitMetrics}
    locator={locator}
    query={query}
    dateRange={dateRange}
  />
);

export const ProductsByVolumeReport: React.FC<DashboardQueryProps> = ({
  locator,
  query,
  dateRange,
}: DashboardQueryProps) => (
  <DashboardReport
    title="Top products by units sold"
    formatter={numberFormatter}
    fetchFn={getProductByVolumeMetrics}
    locator={locator}
    query={query}
    dateRange={dateRange}
  />
);

export const SalesByLocation: React.FC<DashboardQueryProps> = ({
  locator,
  query,
  dateRange,
}: DashboardQueryProps) => (
  <DashboardReport
    title="Top locations by sales"
    formatter={moneyFormatter}
    fetchFn={getSalesByLocation}
    locator={locator}
    query={query}
    dateRange={dateRange}
  />
);

export const DownloadsByLocation: React.FC<DashboardQueryProps> = ({
  locator,
  query,
  dateRange,
}: DashboardQueryProps) => (
  <DashboardReport
    title="Top locations by downloads"
    formatter={numberFormatter}
    fetchFn={getDownloadsByLocation}
    locator={locator}
    query={query}
    dateRange={dateRange}
  />
);

const DashboardReport: React.FC<ReportProps> = ({
  locator,
  query,
  dateRange,
  title,
  fetchFn,
  formatter,
}: ReportProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);

  const parseResponse = ({ values }) => {
    values = values.map((v) => ({
      name: v.name,
      amount: formatter(v.amount),
    }));
    setItems(values);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchFn(locator, query, parseResponse);
  }, [locator, query]);

  return (
    <DashboardCard
      className="DashboardReport"
      loading={loading}
      title={title}
      dateRange={dateRange}
    >
      {items.length ? (
        <table className="DashboardReport__Table">
          <tbody>
            {items.map((i) => (
              <tr key={i.name} className="DashboardReport__Row">
                <td>{i.name}</td>
                <td className="DashboardReport__Value">{i.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </DashboardCard>
  );
};
