import { acceptInvitation, getInvitation } from 'api';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import ErrorBar from 'components/core/ErrorBar';
import Input from 'components/core/Input';
import { AppContext, IAppContext } from 'context';
import { User } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { hasRequiredProperties } from 'utils';
import Copyright from './Copyright';

interface UserEx extends User {
  password?: string;
}

const AcceptInvitationForm: React.FC = () => {
  const [newUser, setNewUser] = useState<Partial<UserEx>>();
  const [eventName, setEventName] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenValid, setTokenValid] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const { token } = useParams();
  const { setUser } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    retrieveInvitation();
  }, []);

  useEffect(() => {
    setEnabled(
      hasRequiredProperties<UserEx>(newUser, [
        'email',
        'password',
        'firstName',
        'lastName',
      ]),
    );
  }, [newUser]);

  const updateUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    setNewUser({ ...newUser, [key]: value });
  };

  const retrieveInvitation = () => {
    const success = (invitation) => {
      setLoading(false);
      setTokenValid(true);
      setEventName(invitation.event.name);
      setNewUser({ ...newUser, email: invitation.email });
    };
    const failure = () => {
      setLoading(false);
      setTokenValid(false);
    };

    getInvitation(token, success, failure);
  };

  const processInvitation = () => {
    const success = (user) => setUser(user);
    const failure = (json) => setError(json.reason || 'Something went wrong');
    acceptInvitation(
      token,
      newUser?.email,
      newUser?.password,
      newUser?.firstName,
      newUser?.lastName,
      success,
      failure,
    );
  };

  const handleJoin = (e) => {
    e.preventDefault();
    processInvitation();
  };

  const invalidTokenMessage = (
    <ErrorBar error="This token is no longer valid" />
  );

  const joinForm = (
    <form className="AcceptInvitationForm FullPageForm" onSubmit={handleJoin}>
      <div className="FullPageForm__Hero">
        <h1>One more step, then you’re in!</h1>
      </div>
      <h1>Join {eventName || 'the event'}</h1>
      <ErrorBar error={error} />
      <Input
        data-testid="AcceptInvitationForm-firstName"
        type="text"
        name="firstName"
        caption="First name"
        value={newUser?.firstName}
        onChange={updateUser}
        autoFocus
        fullWidth
      />
      <Input
        data-testid="AcceptInvitationForm-lastName"
        type="text"
        name="lastName"
        caption="Last name"
        value={newUser?.lastName}
        onChange={updateUser}
        fullWidth
      />
      <Input
        data-testid="AcceptInvitationForm-email"
        type="text"
        name="email"
        caption="Email"
        value={newUser?.email}
        onChange={updateUser}
        fullWidth
      />
      <Input
        data-testid="AcceptInvitationForm-password"
        type="password"
        name="password"
        caption="Password"
        value={newUser?.password}
        onChange={updateUser}
        autoComplete="off"
        fullWidth
      />
      <div className="AcceptInvitationForm__Buttons">
        <ButtonPanel>
          <Button primary caption="Join" type="submit" enabled={enabled} />
        </ButtonPanel>
      </div>
      <footer className="FullPageForm__Footer">
        <span className="FullPageForm__Footer--right">
          Privacy Policy | Terms and Conditions
        </span>
        <Copyright />
      </footer>
    </form>
  );

  const body = tokenValid ? joinForm : invalidTokenMessage;

  return <>{!loading && body}</>;
};

export default AcceptInvitationForm;
