import classNames from 'classnames';
import DashboardEmptyCard from 'components/DashboardEmptyCard';
import DashboardLoadingCard from 'components/DashboardLoadingCard';
import React from 'react';
import './style.scss';

interface DashboardCardProps {
  className?: string;
  title?: string;
  total?: string;
  dateRange?: string;
  loading?: boolean;
  children?: JSX.Element | JSX.Element[] | null;
}
const DashboardCard: React.FC<DashboardCardProps> = ({
  className = '',
  title,
  total,
  dateRange,
  children,
  loading,
}: DashboardCardProps) => {
  const classnames = classNames('DashboardCard', className, {
    'DashboardCard--loading': loading,
  });

  const content = children || <DashboardEmptyCard />;

  return (
    <div className={classnames}>
      <h3 className="DashboardCard__Date">{dateRange}</h3>
      <h1 className="DashboardCard__Title">{title}</h1>
      {total && <h2 className="DashboardCard__Total">{total}</h2>}
      <div className="DashboardCard__Content">
        {loading ? <DashboardLoadingCard /> : content}
      </div>
    </div>
  );
};

export default DashboardCard;
