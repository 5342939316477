import AlertMessages from 'components/core/AlertMessages';
import PageLoadingIndicator from 'components/core/PageLoadingIndicator';
import DefaultRoute from 'components/DefaultRoute';
import Header from 'components/Header';
import Layout, {
  ContentArea,
  HeaderArea,
  NotificationsArea,
  SidebarArea,
} from 'components/Layout';
import PhotographerSignupForm from 'components/PhotographerSignupForm';
import Sidebar from 'components/Sidebar';
import { AppContext, IAppContext } from 'context';
import CreateEventModal from 'modals/CreateEventModal';
import DashboardPage from 'pages/DashboardPage';
import EditTicketPackagePage from 'pages/EditTicketPackagePage';
import EventBillingPage from 'pages/EventBillingPage';
import EventPhotoBoothSettingsPage from 'pages/EventPhotoBoothSettingsPage';
import EventSettingsPage from 'pages/EventSettingsPage';
import FansPage from 'pages/FansPage';
import NewTicketPackagePage from 'pages/NewTicketPackagePage';
import ScheduleItemsTicketsPage from 'pages/ScheduleItemsTicketsPage';
import SchedulePage from 'pages/SchedulePage';
import SettingsPage from 'pages/SettingsPage';
import TeamPage from 'pages/TeamPage';
import TicketNotesPage from 'pages/TicketNotesPage';
import TicketPackagesPage from 'pages/TicketPackagesPage';
import TicketPurchasePage from 'pages/TicketPurchasePage';
import TicketPurchasesPage from 'pages/TicketPurchasesPage';
import UploaderPage from 'pages/UploaderPage';
import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const AdminPage = React.lazy(() => import('admin/pages/AdminPage'));

const Logout: React.FC = () => {
  const { clearUser } = useContext<IAppContext>(AppContext);
  clearUser();
  return <Navigate to="/" />;
};

const PhotographerMigrationLayout: React.FC = () => {
  return (
    <>
      <Layout loggedIn>
        <SidebarArea>
          <Sidebar />
        </SidebarArea>
        <ContentArea>
          <PhotographerSignupForm />
        </ContentArea>
      </Layout>
    </>
  );
};

const UserLayout: React.FC = () => {
  const { user, clearUser } = useContext<IAppContext>(AppContext);
  useEffect(() => {
    if (!localStorage['TOKEN']) clearUser();
  }, []);
  return (
    <>
      <Layout loggedIn>
        <SidebarArea>
          <Sidebar />
        </SidebarArea>
        <NotificationsArea>
          <AlertMessages />
        </NotificationsArea>
        <HeaderArea>
          <Header />
        </HeaderArea>
        <ContentArea>
          <React.Suspense fallback={<PageLoadingIndicator />}>
            <Routes>
              <Route path="/event/:locator/" element={<DashboardPage />} />
              <Route path="/event/:locator/team" element={<TeamPage />} />
              <Route path="/event/:locator/fans" element={<FansPage />} />
              <Route
                path="/event/:locator/ticketPackages/new"
                element={<NewTicketPackagePage />}
              />
              <Route
                path="/event/:locator/ticketPackages/:ticketPackageId"
                element={<EditTicketPackagePage />}
              />
              <Route
                path="/event/:locator/ticketPackages"
                element={<TicketPackagesPage />}
              />
              <Route
                path="/event/:locator/ticketPurchases"
                element={<ScheduleItemsTicketsPage />}
              />

              <Route
                path="/event/:locator/ticketPurchases/:scheduleItemId/:ticketPurchaseId/notes"
                element={<TicketNotesPage />}
              />

              <Route
                path="/event/:locator/ticketPurchases/:scheduleItemId/:id"
                element={<TicketPurchasePage />}
              />

              <Route
                path="/event/:locator/ticketPurchases/:scheduleItemId"
                element={<TicketPurchasesPage />}
              />

              <Route
                path="/event/:locator/settings"
                element={<EventSettingsPage />}
              />
              {user?.superUser && (
                <Route
                  path="/event/:locator/settings/photobooth"
                  element={<EventPhotoBoothSettingsPage />}
                />
              )}
              <Route
                path="/event/:locator/settings/billing"
                element={<EventBillingPage />}
              />
              <Route
                path="/event/:locator/schedule"
                element={<SchedulePage />}
              />
              <Route
                path="/event/:locator/uploader/*"
                element={<UploaderPage />}
              />
              {user?.superUser && (
                <Route path="/admin/*" element={<AdminPage />} />
              )}
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="*" element={<DefaultRoute />} />
            </Routes>
          </React.Suspense>
        </ContentArea>
      </Layout>
      <div id="ModalPortal" />
      <CreateEventModal />
    </>
  );
};

const LoggedInLayout: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/event/:event/photographer"
        element={<PhotographerMigrationLayout />}
      />
      <Route path="*" element={<UserLayout />} />
    </Routes>
  );
};

export default LoggedInLayout;
