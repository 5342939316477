import React, { useContext, useEffect, useState } from 'react';
import pick from 'lodash.pick';
import { AppContext, IAppContext } from 'context';
import { imagePath } from 'api';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import FileInput from 'components/core/FileInput';
import Form from 'components/core/Form';
import Input from 'components/core/Input';
import ToggleField from 'components/core/ToggleField';
import { Event, newEvent } from 'models/Event';
import { useUnload } from './useUnload';
import { hasRequiredProperties } from 'utils';

type Graphic = { file: any; data: any };
interface EventEx extends Event {
  graphic?: Graphic;
  graphicPath?: string | null;
}

interface EventSettingsFormProps {
  isNew?: boolean;
  event?: EventEx;
  onSave?: (
    event?: EventEx,
    success?: () => void,
    failure?: (errors: any) => void,
  ) => void;
}
const EventSettingsForm: React.FC<EventSettingsFormProps> = ({
  isNew = false,
  event: _event,
  onSave,
}: EventSettingsFormProps) => {
  const emptyEvent = { ...newEvent(), graphic: undefined, errors: null };
  const [event, setEvent] = useState<EventEx>(_event ?? emptyEvent);

  const [errors, setErrors] = useState<any>();
  const [dirty, setDirty] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);

  const { user } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    setEnabled(
      dirty && hasRequiredProperties<EventEx>(event, ['name', 'locator']),
    );
  }, [event]);

  useEffect(() => {
    if (event.graphic) {
      setEvent({
        ...event,
        graphicPath: imagePath(
          event.graphic,
          { h: 250 },
          { fit: true, format: 'png' },
        ),
      });
    }
  }, [event.graphic]);

  useUnload({
    when: () => dirty,
    message: `You have unsaved changes. Are you sure you want to navigate away?`,
  });

  const updateProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    setEvent({ ...event, [key]: value } as EventEx);
    setDirty(true);
  };

  const updateSwitch = (name: string) => {
    return (value) => {
      setEvent({ ...event, [name]: value });
      setDirty(true);
    };
  };

  const updatedGraphic = ({ file, data }) => {
    setEvent({ ...event, graphic: { file, data } });
    setDirty(true);
  };

  const onValidationError = (errors) => {
    setErrors(errors.errors);
  };

  const save = () => {
    const eventAttributes: EventEx = pick(event, [
      'name',
      'description',
      'locator',
      'privacyPolicyUrl',
      'termsOfServiceUrl',
      'askEmail',
      'offerDiscount',
      'offerProducts',
      'showProductsList',
      'showFutureSchedule',
      'faceDetectionEnabled',
    ]);

    // Only include the graphic param if the user has uploaded a new one.
    if (event.graphic?.data) {
      eventAttributes.graphic = event.graphic;
    }

    onSave?.(
      eventAttributes,
      () => {
        setErrors({});
        setDirty(false);
      },
      onValidationError,
    );
  };

  const fileError = (error) => {
    setErrors({ file: error });
  };

  return (
    <Form errors={errors}>
      <div className="FieldSet">
        <Input
          name="name"
          testid="event-settings"
          caption="Event name"
          value={event.name}
          onChange={updateProperty}
          autoFocus
        />
        <Input
          optional
          name="description"
          caption="Description"
          placeholder="e.g., “VIP Meet & Greet” or “World Tour”"
          value={event.description}
          onChange={updateProperty}
        />
        <Input
          readOnly={!user?.superUser}
          className={isNew ? '' : 'EventSettingsForm__Locator'}
          name="locator"
          caption="Fan gallery URL"
          prefix="gallery.stagepilot.net/"
          value={event.locator}
          onChange={updateProperty}
        />
        {!isNew ? (
          <a
            className="Button Button--outline EventSettingsForm__Preview"
            href={`https://gallery.stagepilot.net/${event.locator}`}
            target="_blank"
          >
            Preview gallery
          </a>
        ) : null}
        <FileInput
          name="graphic"
          type="file"
          caption="Event graphic"
          buttonCaption="Upload graphic"
          onChange={updatedGraphic}
          previewUrl={event.graphicPath ?? ''}
          previewWidth="500px"
          previewHeight="95px"
          accept="image/jpeg, image/png"
          hint="JPG or PNG image, 1000x190 pixels"
          onError={fileError}
          dimensions={{ width: 1000, height: 190 }}
        />
      </div>

      <hr className="Separator" />

      <div className="FieldSet">
        <Input
          optional
          type="url"
          name="privacyPolicyUrl"
          caption="Privacy Policy"
          placeholder="https://stagepilot.com/legal/privacy"
          value={event.privacyPolicyUrl}
          onChange={updateProperty}
        />

        <Input
          optional
          type="url"
          name="termsOfServiceUrl"
          caption="Terms Of Service"
          placeholder="https://www.stagepilot.com/legal/terms-of-use"
          value={event.termsOfServiceUrl}
          onChange={updateProperty}
        />
      </div>

      <hr className="Separator" />

      <div className="FieldSet">
        <ToggleField
          name="askEmail"
          testid="askEmail"
          caption="Ask fan for email"
          checked={event.askEmail}
          onChange={updateSwitch('askEmail')}
        >
          This is optional and doesn't restrict access to their photos.
        </ToggleField>
      </div>

      {user?.superUser && (
        <div className="EventSettingsForm__Superuser">
          <strong>Superuser</strong>

          <div className="FieldSet">
            <ToggleField
              name="faceDetectionEnabled"
              caption="Face matching"
              checked={event.faceDetectionEnabled}
              onChange={updateSwitch('faceDetectionEnabled')}
            >
              Use face detection to match registered fans with their posts.
            </ToggleField>
          </div>
        </div>
      )}

      <hr className="Separator" />

      <ButtonPanel>
        {isNew ? (
          <Button
            id="save-event-button"
            caption="Create event"
            enabled={enabled}
            onClick={save}
            primary
          />
        ) : (
          <Button caption="Save" enabled={enabled} onClick={save} primary />
        )}
      </ButtonPanel>
    </Form>
  );
};

export default EventSettingsForm;
