import { deleteTicketPackage, saveTicketPackage } from 'api';
import { showInfoMsg } from 'components/core/AlertMessages';
import AutoAddField from 'components/core/AutoAddField';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import Form from 'components/core/Form';
import Input from 'components/core/Input';
import ToggleSwitch from 'components/core/ToggleSwitch';
import { MerchandiseItem, TicketPackage } from 'models';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';

interface TicketPackageFormProps {
  ticketPackage: TicketPackage;
}

const TicketPackageForm = ({ ticketPackage }: TicketPackageFormProps) => {
  const [tkPackage, setTkPackage] = useState<TicketPackage>(ticketPackage);
  const [merchandiseItemArray, setMerchandiseItemArray] = useState<
    MerchandiseItem[]
  >(ticketPackage.merchandiseItems);
  const [extraFieldArray, setExtraFieldArray] = useState<string[]>(
    ticketPackage.extraFields,
  );

  const setName = (name: string) => setTkPackage({ ...tkPackage, name });
  const setDescription = (description: string) =>
    setTkPackage({ ...tkPackage, description });
  const setWristbandsEnabled = (wristbandsEnabled: boolean) =>
    setTkPackage({ ...tkPackage, wristbandsEnabled });
  const setExtraFieldsEnabled = (extraFieldsEnabled: boolean) =>
    setTkPackage({ ...tkPackage, extraFieldsEnabled });
  const setMerchandiseEnabled = (merchandiseEnabled: boolean) =>
    setTkPackage({ ...tkPackage, merchandiseEnabled });
  const setColor = (color: string) => setTkPackage({ ...tkPackage, color });

  const [errors, setError] = useState<object>({});
  const navigate = useNavigate();
  const { locator } = useParams();

  const isNew = !ticketPackage.id;

  useEffect(() => {
    initializeMerchExtraFields();
  }, []);

  const initializeMerchExtraFields = () => {
    const newItem: MerchandiseItem = {
      name: '',
    };

    setMerchandiseItemArray([...tkPackage.merchandiseItems, newItem]);
    const newField = '';
    setExtraFieldArray([...(tkPackage.extraFields ?? []), newField]);
  };

  const deletePackage = async () => {
    if (!locator) return;
    const ticketPackageId = tkPackage.id;

    deleteTicketPackage(
      locator,
      ticketPackageId,
      () => {
        showInfoMsg('Ticket package deleted');
        navigate(`/event/${locator}/ticketPackages`);
      },
      () => {
        setError({ error: 'Unable to delete ticket package' });
      },
    );
  };

  const save = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!locator) return;
    const newTicketPackage = { ...tkPackage };
    Object.keys(newTicketPackage).forEach(function (keyName) {
      if (typeof newTicketPackage[keyName] === 'string') {
        newTicketPackage[keyName] = newTicketPackage[keyName].trim();
      }
    });
    newTicketPackage.merchandiseItems = merchandiseItemArray
      .map((mi) => ({ ...mi, name: mi.name?.trim() }))
      .filter((mi) => mi.name !== '');
    newTicketPackage.extraFields = extraFieldArray
      .map((ef) => ef.trim())
      .filter((ef) => ef !== '');

    try {
      await saveTicketPackage(locator, newTicketPackage);
      showInfoMsg('Ticket package saved');
      navigate(`/event/${locator}/ticketPackages`);
    } catch (e: any) {
      setError(e);
    }
  };

  const addMerchandise = () => {
    const newItem: MerchandiseItem = {
      id: 0,
      name: '',
      position: 0,
      ticketPackageID: 0,
      createdAt: '',
      updatedAt: '',
    };

    const newMerchandiseItems = [...tkPackage.merchandiseItems, newItem];
    setMerchandiseItemArray(newMerchandiseItems);
  };

  const changedMerchandise = (index: number, newValue: string) => {
    const newMerchandiseItems = [...merchandiseItemArray];
    newMerchandiseItems[index].name = newValue;
    setMerchandiseItemArray(newMerchandiseItems);
  };

  const addExtraField = () => {
    const newField = '';
    const newExtraFields = [...(tkPackage.extraFields ?? []), newField];
    setExtraFieldArray(newExtraFields);
  };

  const changedExtraField = (index: number, newValue: string) => {
    const newExtraFields = [...extraFieldArray];
    newExtraFields[index] = newValue;
    setExtraFieldArray(newExtraFields);
  };

  return (
    <Form className="TicketPackageForm" errors={errors}>
      <div className="FieldSet">
        <div className="Input">
          <span className="Legend Input__Caption">Package details</span>
        </div>

        <Input
          name="name"
          caption="Package name"
          value={tkPackage.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          autoFocus
        />

        <div className="Input TicketPackage_DescriptionInput">
          <label htmlFor="description" className="Input__Caption">
            Description
          </label>
          <textarea
            className="Input__Control"
            id="description"
            name="description"
            value={tkPackage.description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>

      <hr className="Separator" />

      <div className="FieldSet">
        <div className="Input">
          <span className="Legend Input__Caption">Wrist bands</span>
          <ToggleSwitch
            name="wristbandsEnabled"
            checked={tkPackage.wristbandsEnabled}
            onChange={(value: boolean) => setWristbandsEnabled(value)}
          />
        </div>

        {tkPackage.wristbandsEnabled && (
          <Input
            optional
            name="color"
            caption="Color"
            type="color"
            disabled={!tkPackage.wristbandsEnabled}
            className="TicketPackage_ColorInput"
            value={tkPackage.color}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setColor(e.target.value)
            }
          />
        )}
      </div>

      <div className="FieldSet">
        <div className="Input">
          <span className="Legend Input__Caption">Merchandise</span>
          <ToggleSwitch
            name="merchandiseEnabled"
            checked={tkPackage.merchandiseEnabled}
            onChange={(value: boolean) => setMerchandiseEnabled(value)}
          />
        </div>

        {tkPackage.merchandiseEnabled && (
          <div className="MerchandiseItems">
            {merchandiseItemArray.map((item, i) => {
              return (
                <AutoAddField
                  key={`merchandise-item-${i}`}
                  name={`Item ${i}`}
                  value={item.name}
                  onAddNew={addMerchandise}
                  onChange={(newValue) => changedMerchandise(i, newValue)}
                />
              );
            })}
          </div>
        )}
      </div>

      <div className="FieldSet">
        <div className="Input">
          <span className="Legend Input__Caption">Add’l details</span>
          <ToggleSwitch
            name="extraFieldsEnabled"
            checked={tkPackage.extraFieldsEnabled}
            onChange={(value: boolean) => setExtraFieldsEnabled(value)}
          />
        </div>

        {tkPackage.extraFieldsEnabled && (
          <div className="ExtraFields">
            {extraFieldArray.map((field, i) => {
              return (
                <AutoAddField
                  key={`extra-field-${i}`}
                  name={`Custom ${i}`}
                  value={field}
                  onAddNew={addExtraField}
                  onChange={(newValue) => changedExtraField(i, newValue)}
                />
              );
            })}
          </div>
        )}
      </div>

      <ButtonPanel>
        {isNew ? (
          <Button caption="Create package" onClick={save} primary />
        ) : (
          <>
            <Button caption="Update Package" onClick={save} primary />
            <Button
              caption="Delete package"
              danger
              confirmMessage={
                'Are you sure you want to delete this ticket package?'
              }
              onClick={deletePackage}
            />
          </>
        )}
      </ButtonPanel>
    </Form>
  );
};

export default TicketPackageForm;
