import {
  deletePosts,
  downloadsPath,
  getPosts,
  getScheduleItem,
  imagePath,
} from 'api';
import { formatDate } from 'api/util';
import classNames from 'classnames';
import { Breadcrumb } from 'components/core/Breadcrumb';
import FilePicker from 'components/core/FilePicker';
import LinkButton from 'components/core/LinkButton';
import NotifyFansButton from 'components/NotifyFansButton';
import UploaderErrors from 'components/UploaderErrors';
import UploaderProgress from 'components/UploaderProgress';
import UploadHandlerDropTarget from 'components/UploadHandlerDropTarget';
import {
  AppContext,
  IAppContext,
  IUploaderContext,
  UploaderContext,
} from 'context';
import emptyStatePath from 'images/uploader-empty-state.png';
import { ScheduleItem } from 'models/ScheduleItem';
import React, { useContext, useEffect, useState } from 'react';
import { WithBlankSlate } from './BlankSlate';
import { useParams } from 'react-router';
interface IOptions {
  format?: string;
  animated?: boolean;
}

interface PostProps {
  post?: any;
  selected?: boolean;
  onClick?: (e: any) => void;
}
const Post: React.FC<PostProps> = ({ post, selected, onClick }: PostProps) => {
  const className = classNames('UploaderScheduleItemView__Post', {
    'UploaderScheduleItemView__Post--selected': selected,
  });
  const options: IOptions = {};

  if (post.isAnimated) {
    options.format = 'gif';
    options.animated = true;
  }

  const url = imagePath(post.contentDigest, 200, options);

  return (
    <div
      className={className}
      style={{ backgroundImage: `url(${url})` }}
      onClick={onClick}
    />
  );
};

interface UploaderScheduleItemViewProps {
  schedule?: ScheduleItem[];
}

const UploaderScheduleItemView: React.FC<UploaderScheduleItemViewProps> = ({
  schedule,
}: UploaderScheduleItemViewProps) => {
  const [posts, setPosts] = useState<any>([]);
  const [lastSelected, setLastSelected] = useState<any>(null);
  const [currentScheduleItem, setCurrentScheduleItem] = useState<any>(null);
  const { scheduleItemId } = useParams();

  const { progress, uploadFile, onItemComplete } =
    useContext<IUploaderContext>(UploaderContext);
  const { currentEvent } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    loadInitialState();
    onItemComplete.subscribe(whenItemCompletes);
    return () => {
      onItemComplete.unsubscribe(whenItemCompletes);
    };
  }, []);

  useEffect(() => {
    loadInitialState();
  }, [currentEvent]);

  useEffect(() => {
    loadInitialState();
  }, [schedule]);

  const loadScheduleItem = () => {
    if (currentEvent?.locator && scheduleItemId) {
      getScheduleItem(
        currentEvent.locator,
        scheduleItemId,
        (currentScheduleItem) => {
          setCurrentScheduleItem(currentScheduleItem);
        },
      );
    }
  };

  const loadPosts = () => {
    if (currentEvent?.locator && scheduleItemId) {
      const success = (posts) => {
        setPosts(posts);
        setLastSelected(null);
      };
      getPosts(currentEvent.locator, scheduleItemId, success);
    }
  };

  const destroyPosts = (postIds, callback) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete these images?',
    );

    if (!confirmed) return;

    if (currentEvent?.locator && scheduleItemId) {
      deletePosts(currentEvent.locator, scheduleItemId, postIds, callback);
    }
  };

  const uploadFilesFromPicker = (files: File[]) => {
    Array.from(files).forEach((file) => {
      uploadFile(currentEvent?.locator, +(scheduleItemId ?? 0), file);
    });
  };

  const whenItemCompletes = ({ post }) => {
    if (+post.scheduleItemId === +(scheduleItemId ?? 0)) {
      if (!posts) {
        return;
      }

      const found = posts.find((p) => p.id === post.id);
      const newPosts = found ? posts : posts.concat(post);
      setPosts(newPosts);
    }
  };

  const selectAll = () => {
    const newPosts = posts.map((p) => ({ ...p, selected: true }));
    setPosts(newPosts);
    setLastSelected(null);
  };

  const selectNone = () => {
    const newPosts = posts.map((p) => ({ ...p, selected: false }));
    setPosts(newPosts);
    setLastSelected(null);
  };

  const postClicked = (post, shift) => {
    const selectRange = (post) => {
      const at = posts.indexOf(post);
      const start = Math.min(lastSelected, at);
      const end = Math.max(lastSelected, at);

      const newPosts = posts.map((p, i) => ({
        ...p,
        selected: (i >= start && i <= end) || p.selected,
      }));

      setPosts(newPosts);
    };

    const toggleSelection = (post) => {
      const newPosts = posts.map((p) =>
        p !== post ? p : { ...p, selected: !p.selected },
      );
      const lastSelected = post.selected ? null : posts.indexOf(post);

      setPosts(newPosts);
      setLastSelected(lastSelected);
    };

    if (shift && lastSelected !== null) {
      return selectRange(post);
    } else {
      return toggleSelection(post);
    }
  };

  const deleteSelected = () => {
    const toDelete = posts.filter((p) => p.selected);
    const postIds = toDelete.map((p) => p.id);

    destroyPosts(postIds, () => {
      const newPosts = posts.filter((p) => !toDelete.includes(p));
      setPosts(newPosts);
    });
  };

  const downloadSelected = async () => {
    const toDownload = posts.filter((p) => p.selected);
    const postIds = toDownload.map((p) => p.id);

    if (currentEvent?.locator && scheduleItemId) {
      const downloadPath = downloadsPath(
        currentEvent.locator,
        scheduleItemId,
        postIds,
      );
      const token = localStorage.getItem('TOKEN');
      try {
        let response = await fetch(downloadPath, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 403) {
          throw new Error('Forbidden');
        }

        let data = await response.blob();
        let blobUrl = URL.createObjectURL(data);
        window.open(blobUrl);
      } catch (error) {
        console.error('Failed to download:', error);
      }
    }
  };

  const loadInitialState = () => {
    loadScheduleItem();
    loadPosts();
  };

  if (schedule === null) {
    return null;
  }

  const uploadsInProgress = !!progress;

  if (posts === null || !currentScheduleItem) {
    return null;
  }

  const postCount = posts.length;
  const selectedCount = posts.filter((p) => p.selected).length;

  return (
    <UploadHandlerDropTarget
      className="UploaderScheduleItemView__DropTarget"
      locator={currentEvent?.locator}
      scheduleItemId={+(scheduleItemId ?? 0)}
      multiple={true}
    >
      <div className="UploaderScheduleItemView__Title">
        <NotifyFansButton
          locator={currentEvent?.locator}
          scheduleItemId={+(scheduleItemId ?? 0)}
          waitingFansCount={currentScheduleItem.waitingFansCount}
          postCount={postCount}
          fansNotifiedAt={currentScheduleItem.fansNotifiedAt}
        />
        <UploaderProgress scheduleItemId={+(scheduleItemId ?? 0)} />
        <h1>
          <Breadcrumb
            to={`/event/${currentEvent?.locator}/uploader`}
            title="Uploader"
          />
          {currentScheduleItem.location}
          {' - '}
          <time className="UploaderScheduleItemView__Date">
            {formatDate(currentScheduleItem.startsAt)}
          </time>{' '}
          <span className="UploaderScheduleItemView__PhotosCount">
            ({postCount} photos)
          </span>
        </h1>
      </div>
      <UploaderErrors scheduleItemId={+(scheduleItemId ?? 0)} />

      <p>
        Drag and drop files or{' '}
        <FilePicker
          caption="search your device"
          multiple
          onPick={uploadFilesFromPicker}
        />
        .
      </p>

      <div className="UploaderScheduleItemView__Toolbar">
        <LinkButton
          className="UploaderScheduleItemView__ToolbarItem"
          caption="Select all"
          onClick={selectAll}
        />

        <LinkButton
          className="UploaderScheduleItemView__ToolbarItem"
          caption="Select none"
          onClick={selectNone}
        />

        <LinkButton
          className="UploaderScheduleItemView__ToolbarItem"
          disabled={selectedCount < 1}
          onClick={deleteSelected}
          caption={`Delete (${selectedCount})`}
        />

        <LinkButton
          className="UploaderScheduleItemView__ToolbarItem"
          disabled={selectedCount < 1}
          onClick={downloadSelected}
          caption={`Download (${selectedCount})`}
        />
      </div>

      <WithBlankSlate
        title="Drop photos here to upload"
        suppress={uploadsInProgress}
        message={
          <span>
            Prefer buttons to dragging?
            <br /> Just click below to upload.
          </span>
        }
        extraContent={
          <FilePicker
            button
            caption="Upload photos"
            multiple
            onPick={uploadFilesFromPicker}
          />
        }
        collection={posts}
        imagePath={emptyStatePath}
      >
        <div className="UploaderScheduleItemView">
          {posts.map((p) => (
            <Post
              key={p.id}
              post={p}
              selected={p.selected}
              onClick={(e) => postClicked(p, e.shiftKey)}
            />
          ))}
        </div>
      </WithBlankSlate>
    </UploadHandlerDropTarget>
  );
};

export default UploaderScheduleItemView;
