import React from 'react';
import { useLocation, Navigate, Route, Routes } from 'react-router-dom';
import { setLoginDestination } from 'localStorage';
import Layout, { FillArea } from 'components/Layout';
import LoginForm from 'components/LoginForm';
import NewResetPasswordForm from 'components/NewResetPasswordForm';
import ResetPasswordForm from 'components/ResetPasswordForm';
import SignupForm from 'components/SignupForm';
import AcceptInvitationForm from 'components/AcceptInvitationForm';
import PhotographerSignupForm from 'components/PhotographerSignupForm';
import Logo from 'components/Logo';

const SaveDestinationAndLogin = () => {
  const location = useLocation();
  setLoginDestination(location.pathname);
  return <Navigate to="/login" />;
};

const LoggedOutLayout = () => (
  <Layout gradient>
    <Logo light centered />
    <FillArea>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/join/:token" element={<AcceptInvitationForm />} />
        <Route path="/reset_password/new" element={<NewResetPasswordForm />} />
        <Route path="/reset_password/:token" element={<ResetPasswordForm />} />
        <Route
          path="/event/:event/photographer"
          element={<PhotographerSignupForm />}
        />
        <Route path="*" element={<SaveDestinationAndLogin />} />
      </Routes>
    </FillArea>
  </Layout>
);

export default LoggedOutLayout;
