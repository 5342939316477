import { createEvent } from 'api';
import FullModal from 'components/core/FullModal';
import EventSettingsForm from 'components/EventSettingsForm';
import { AppContext, IAppContext, IModalContext, ModalContext } from 'context';
import React, { useContext } from 'react';

const CreateEventModal: React.FC = () => {
  const { addNewEvent, firstRun, user } = useContext<IAppContext>(AppContext);
  const { open, showCreateEventModal } =
    useContext<IModalContext>(ModalContext);

  const save = (eventAttributes, successCallback, errorCallback) => {
    const success = (json) => {
      successCallback();
      showCreateEventModal?.(false);
      addNewEvent?.(json.event);
    };

    createEvent(eventAttributes, success, errorCallback);
  };

  const isFirstRun = firstRun && user?.superUser;

  const onClose = isFirstRun ? undefined : () => showCreateEventModal(false);

  const title = isFirstRun ? 'Create your first event' : 'Event settings';

  return (
    <FullModal open={!!open || isFirstRun} onClose={onClose}>
      <h1>{title}</h1>
      <>Settings can be updated at any time.</>
      <EventSettingsForm isNew onSave={save} />
    </FullModal>
  );
};

export default CreateEventModal;
