import Button from 'components/core/Button';
import { IUploaderContext, UploaderContext } from 'context';
import { ERROR } from 'models/UploadQueue';
import React, { useContext } from 'react';

interface UploaderErrorsProps {
  scheduleItemId: number;
}
const UploaderErrors: React.FC<UploaderErrorsProps> = ({
  scheduleItemId,
}: UploaderErrorsProps) => {
  const { progress, clearErrors, retryFailed } =
    useContext<IUploaderContext>(UploaderContext);
  const itemProgress = progress?.[scheduleItemId];

  if (itemProgress?.state !== ERROR) return null;

  const failed = itemProgress?.failed.filter((f) => f.retryable);
  const rejected = itemProgress?.failed.filter((f) => !f.retryable);

  return (
    <div className="UploaderErrors">
      {failed.length > 0 && (
        <>
          <h1 className="UploaderErrors__Title">{failed.length} failed:</h1>
          <div className="UploaderErrors__List">
            {failed.map((e) => (
              <div className="UploaderErrors__Error" key={e.name}>
                {e.name}
              </div>
            ))}
          </div>
        </>
      )}
      {rejected.length > 0 && (
        <>
          <h1 className="UploaderErrors__Title">{rejected.length} rejected:</h1>
          <div className="UploaderErrors__List">
            {rejected.map((e) => (
              <div className="UploaderErrors__Error" key={e.name}>
                {e.name} ({e.failureReason})
              </div>
            ))}
          </div>
        </>
      )}
      <div className="UploaderErrors__Actions">
        <Button
          secondary
          onClick={() => clearErrors(scheduleItemId)}
          caption="Ignore all"
        />
        {failed.length > 0 && (
          <Button
            primary
            onClick={() => retryFailed(scheduleItemId)}
            caption="Retry failed"
          />
        )}
      </div>
    </div>
  );
};

export default UploaderErrors;
