const RoleCodes = ['o', 'a', 'p', 'v'];

// Returns a numeric level associated to a role
const levelForRole = (roleCode) => {
  const level = RoleCodes.indexOf(roleCode);
  if (level === -1) {
    throw `Invalid role ${roleCode}`;
  }
  return level;
};

export const roleComparison = (first, second) => {
  const firstLevel = levelForRole(first);
  const secondLevel = levelForRole(second);

  if (firstLevel < secondLevel) {
    return -1;
  } else if (firstLevel > secondLevel) {
    return 1;
  }

  return 0;
};

export const atLeast = (requiredLevel, level) => {
  return roleComparison(requiredLevel, level) >= 0;
};
