import { formatDate, humanizeTimestamp } from 'api/util';
import classNames from 'classnames';
import { WithBlankSlate } from 'components/BlankSlate';
import UploaderOverviewErrors from 'components/UploaderOverviewErrors';
import UploaderProgress from 'components/UploaderProgress';
import UploadHandlerDropTarget from 'components/UploadHandlerDropTarget';
import { AppContext, IAppContext } from 'context';
import { differenceInDays, parseISO } from 'date-fns';
import emptyStatePath from 'images/uploader-empty-state.png';
import { ScheduleItem } from 'models/ScheduleItem';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

interface FanWaitingBadgeProps {
  fanCount: number;
  postCount: number;
  startsAt: any;
  notifiedAt: any;
}
const FanWaitingBadge: React.FC<FanWaitingBadgeProps> = ({
  fanCount,
  postCount,
  startsAt,
  notifiedAt = null,
}: FanWaitingBadgeProps) => {
  const daysSince = differenceInDays(parseISO(startsAt), new Date());
  if (
    daysSince > 0 ||
    fanCount === 0 ||
    (postCount > 0 && notifiedAt !== null)
  ) {
    return null;
  }

  const className = classNames('UploaderOverview__Badge', {
    'UploaderOverview__Badge--yesterday': daysSince === -1,
    'UploaderOverview__Badge--old': daysSince < -1,
  });

  const noun = fanCount === 1 ? 'fan' : 'fans';

  return (
    <span className={className}>
      {fanCount} {noun} waiting
    </span>
  );
};

interface ScheduleItemUIProps {
  index: number;
  event: any;
  scheduleItem: any;
}
const ScheduleItemUI: React.FC<ScheduleItemUIProps> = ({
  event,
  scheduleItem,
}: ScheduleItemUIProps) => {
  const className = classNames('UploaderOverview__Row', {
    'UploaderOverview__Row--hidden': scheduleItem.hidden,
  });

  return (
    <div className={className}>
      <Link
        className="Link--container"
        to={`/event/${event?.locator}/uploader/${scheduleItem.id}`}
      >
        <UploadHandlerDropTarget
          locator={event?.locator}
          scheduleItemId={scheduleItem.id}
        >
          <div className="UploaderOverview__Progress">
            <UploaderProgress scheduleItemId={scheduleItem.id} />
          </div>
          <div className="UploaderOverview__Date UploaderOverview__Cell">
            {formatDate(scheduleItem.startsAt)}
          </div>
          <div className="UploaderOverview__Location UploaderOverview__Cell">
            {scheduleItem.location}
          </div>
          <div className="UploaderOverview__LastModified UploaderOverview__Cell">
            {humanizeTimestamp(scheduleItem.lastPostAt)}
          </div>
          <div className="UploaderOverview__Photos UploaderOverview__Cell right">
            {scheduleItem.postCount}
          </div>
          <div className="UploaderOverview__Fans UploaderOverview__Cell right">
            <FanWaitingBadge
              fanCount={scheduleItem.waitingFansCount}
              postCount={scheduleItem.postCount}
              notifiedAt={scheduleItem.fansNotifiedAt}
              startsAt={scheduleItem.startsAt}
            />
          </div>
        </UploadHandlerDropTarget>
      </Link>
    </div>
  );
};

interface UploaderOverviewProps {
  schedule?: ScheduleItem[];
}
const UploaderOverview: React.FC<UploaderOverviewProps> = ({
  schedule,
}: UploaderOverviewProps) => {
  const { currentEvent, hasRole } = useContext<IAppContext>(AppContext);
  let blankSlateExtraContent;

  if (currentEvent && hasRole('a')) {
    blankSlateExtraContent = (
      <Link
        to={`/event/${currentEvent?.locator}/schedule`}
        className="Button Button--primary"
      >
        Add schedule items
      </Link>
    );
  }

  return (
    <>
      <UploaderOverviewErrors />
      <div className="UploaderOverview">
        <h1 className="Page__Title">Uploader</h1>
        <div className="Page__Header">
          Choose a folder to add photos or drop files onto the schedule below.
        </div>
        <WithBlankSlate
          title="Please add at least one schedule item."
          message="Once you add schedule items, corresponding upload folders will appear here."
          extraContent={blankSlateExtraContent}
          collection={schedule}
          imagePath={emptyStatePath}
        >
          <div className="UploaderOverview__List Table">
            <div className="UploaderOverview__Row">
              <div className="UploaderOverview__Date UploaderOverview__Header">
                Date
              </div>
              <div className="UploaderOverview__Location UploaderOverview__Header">
                Location
              </div>
              <div className="UploaderOverview__LastModified UploaderOverview__Header">
                Last Modified
              </div>
              <div className="UploaderOverview__Photos UploaderOverview__Header right">
                Photos
              </div>
              <div className="UploaderOverview__Fans UploaderOverview__Header right">
                Fans Waiting
              </div>
            </div>
            {schedule?.map((item: any, idx: number) => (
              <ScheduleItemUI
                index={idx}
                key={item.id}
                scheduleItem={item}
                event={currentEvent}
              />
            ))}
          </div>
        </WithBlankSlate>
      </div>
    </>
  );
};

export default UploaderOverview;
