import { getBillingDetails, originalPath, saveBillingDetails } from 'api';
import { showInfoMsg } from 'components/core/AlertMessages';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import FileInput from 'components/core/FileInput';
import Form from 'components/core/Form';
import Input from 'components/core/Input';
import React, { useEffect, useState } from 'react';
import { useUnload } from './useUnload';
import {
  BillingAttributes,
  emptyBillingAttributes,
} from 'models/BillingAttributes';
import { hasRequiredProperties } from 'utils';
import { useParams } from 'react-router';

const BillingSettings: React.FC = () => {
  const [state, setState] = useState<Partial<BillingAttributes>>(
    emptyBillingAttributes,
  );
  const [errors, setErrors] = useState<object>({});
  const [dirty, setDirty] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const { locator } = useParams();

  useEffect(() => {
    if (locator) {
      loadBillingDetails();
    }
  }, [locator]);

  useEffect(() => {
    setEnabled(
      hasRequiredProperties<BillingAttributes>(state, [
        'firstName',
        'lastName',
        'email',
        'address1',
        'city',
        'state',
        'zipcode',
      ]),
    );
  }, [state]);

  useUnload({
    when: () => dirty,
    message: `You have unsaved changes. Are you sure you want to navigate away?`,
  });

  const updateProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name: key, value } = e.target;
    setState({ ...state, [key]: value });
  };

  const loadBillingDetails = () => {
    getBillingDetails(locator, (billingDetails) => {
      setState({ ...state, ...billingDetails });
    });
  };

  const save = () => {
    if (!locator) return;

    const success = () => {
      showInfoMsg('Billing details saved');
      setErrors({});
      setDirty(false);
    };

    const failure = ({ errors }) => {
      setErrors(errors);
    };

    saveBillingDetails(locator, state as BillingAttributes, success, failure);
  };

  const updatedW9 = ({ file, data }) => {
    setState({ ...state, newW9: { file, data } });
    setDirty(true);
  };

  const w9Preview = () => {
    if (state.newW9?.file) {
      return (
        <span className="Input__FileInputFileName">{state.newW9.file}</span>
      );
    } else if (state.w9Filename && state.w9Digest) {
      return (
        <a target="_blank" href={originalPath(state.w9Digest)}>
          {state.w9Filename}
        </a>
      );
    } else {
      return false;
    }
  };

  return (
    <Form errors={errors}>
      <div className="FieldSet">
        <Input
          testid="BillingSettings-firstName"
          name="firstName"
          caption="Name"
          placeholder="First name"
          value={state.firstName}
          onChange={updateProperty}
          autoFocus
        >
          <input
            data-testid="BillingSettings-lastName"
            className="Input__Control Input__Control--inline"
            name="lastName"
            placeholder="Last name"
            value={state.lastName}
            onChange={updateProperty}
          />
        </Input>
        <Input
          testid="BillingSettings-email"
          name="email"
          caption="Email address"
          placeholder="Email address"
          value={state.email}
          onChange={updateProperty}
        />
        <Input
          testid="BillingSettings-organization"
          name="organization"
          caption="Company"
          placeholder="Company"
          value={state.organization}
          onChange={updateProperty}
        />
        <Input
          testid="BillingSettings-address1"
          name="address1"
          caption="Mailing address"
          value={state.address1}
          placeholder="Address"
          onChange={updateProperty}
        />
        <div className="Input__Description">
          <p>
            <em>This is where we’ll send monthly print revenue checks.</em>
          </p>
        </div>
        <Input
          testid="BillingSettings-address2"
          name="address2"
          caption=""
          placeholder="Address, line 2"
          value={state.address2}
          onChange={updateProperty}
        />
        <Input
          testid="BillingSettings-city"
          name="city"
          placeholder="City"
          caption=""
          value={state.city}
          onChange={updateProperty}
        >
          <input
            data-testid="BillingSettings-state"
            className="Input__Control Input__Control--inline Input__Control--shortWidth"
            name="state"
            placeholder="State"
            value={state?.state}
            onChange={updateProperty}
          />
          <input
            data-testid="BillingSettings-zipcode"
            className="Input__Control Input__Control--inline Input__Control--shortWidth"
            name="zipcode"
            placeholder="Zip code"
            value={state.zipcode}
            onChange={updateProperty}
          />
        </Input>

        <FileInput
          name="w9"
          caption="W-9"
          accept="image/png, image/jpeg, .pdf"
          hint="PDF, PNG, or JPG files"
          preview={w9Preview()}
          onChange={updatedW9}
        />
      </div>

      <ButtonPanel>
        <Button
          testid="BillingSettings-Button-Save"
          caption="Save"
          enabled={enabled}
          onClick={save}
          primary
        />
      </ButtonPanel>
    </Form>
  );
};

export default BillingSettings;
