export interface PhotoBoothOptions {
  background?: any; // for uploading data
  backgroundPath?: string;
  frame?: any; // for uploading data
  framePath?: string;
  marketingSignupText: string;
  offerEmailDelivery: boolean;
  offerSmsDelivery: boolean;
  offerMarketingSignup: boolean;
}

export const emptyPhotoBoothOptions: PhotoBoothOptions = {
  marketingSignupText: '',
  offerEmailDelivery: true,
  offerSmsDelivery: true,
  offerMarketingSignup: true,
};
