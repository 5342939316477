import { TicketPackage } from 'models/TicketPackage';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './styles.scss';

interface TicketPackagesProps {
  ticketPackages: TicketPackage[];
}

const TicketPackages: React.FC<TicketPackagesProps> = ({ ticketPackages }) => {
  const { locator } = useParams();

  return (
    <div className="TicketPackages">
      {ticketPackages.map((tp) => (
        <TicketPackageItem ticketPackage={tp} key={tp.id} />
      ))}

      <hr className="Separator" />

      <Link
        className="Button Button--primary"
        to={`/event/${locator}/ticketPackages/new`}
      >
        Add a ticket package
      </Link>
    </div>
  );
};

interface TicketPackageItemProp {
  ticketPackage: TicketPackage;
}

const TicketPackageItem: React.FC<TicketPackageItemProp> = ({
  ticketPackage,
}: TicketPackageItemProp) => {
  const { locator } = useParams();

  const style = {
    backgroundColor: ticketPackage.color,
  };

  return (
    <div className="TicketPackage">
      <div className="TicketPackage__Info">
        <div className="TicketPackage__Color" style={style} />
        <div className="TicketPackage__Name">{ticketPackage.name}</div>
      </div>
      <div className="TicketPackage__EditButton">
        <Link
          className="Button Button--secondary"
          to={`/event/${locator}/ticketPackages/${ticketPackage.id}`}
        >
          Edit Package
        </Link>
      </div>
    </div>
  );
};

export default TicketPackages;
