import {
  applyTicketPurchasesImport,
  getScheduleItem,
  getTicketPurchases,
  updateTicketPurchasesImportHeaders,
} from 'api';
import { formatDate } from 'api/util';
import CheckInStats from 'components/CheckInStats';
import { showErrorMsg, showInfoMsg } from 'components/core/AlertMessages';
import { Breadcrumb } from 'components/core/Breadcrumb';
import Page from 'components/core/Page';
import PageHeader from 'components/core/PageHeader';
import PageLoadingIndicator from 'components/core/PageLoadingIndicator';
import GuestSearch from 'components/GuestSearch';
import TicketPurchasesList from 'components/TicketPurchasesList';
import UploadTicketsCSVButton from 'components/UploadTicketsCSVButton';
import { AppContext, IAppContext } from 'context';
import ImportTicketPurchasesModal from 'modals/ImportTicketPurchasesModal';
import ImportPreviewResponse from 'models/ImportPreviewResponse';
import { ScheduleItem } from 'models/ScheduleItem';
import TicketPurchase, {
  clearSearchMatches,
  hasNote,
  isCheckedIn,
  partiallyCheckedIn,
  search,
} from 'models/TicketPurchase';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

type PurchasesFilter = (tp: TicketPurchase) => boolean;

const FILTERS: { [FilterName: string]: PurchasesFilter } = {
  all: () => true,
  checkedIn: (tp: TicketPurchase) => isCheckedIn(tp),
  partiallyCheckedIn: (tp: TicketPurchase) =>
    partiallyCheckedIn(tp) && !isCheckedIn(tp),
  notCheckedIn: (tp: TicketPurchase) => !partiallyCheckedIn(tp),
  notes: (tp: TicketPurchase) => hasNote(tp),
};

const TicketPurchasesPage: React.FC = () => {
  const [ticketPurchases, setTicketPurchases] = useState<TicketPurchase[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [scheduleItem, setScheduleItem] = useState<ScheduleItem | undefined>();
  const [currentImport, setCurrentImport] = useState<
    ImportPreviewResponse | undefined
  >();

  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [searchBoxTerm, setSearchBoxTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredPurchases, setFilteredPurchases] = useState(ticketPurchases);
  const [filtered, setFiltered] = useState(false);

  const { scheduleItemId } = useParams();

  const { currentEvent } = useContext<IAppContext>(AppContext);
  const locator = currentEvent?.locator;

  const noResults =
    filtered && filteredPurchases && filteredPurchases.length === 0;

  useEffect(() => {
    setFiltered(selectedFilter != 'all');

    const filter = FILTERS[selectedFilter];
    let filtered = ticketPurchases?.filter(filter) || [];

    if (!selectedCategory && searchBoxTerm.length < 2) {
      filtered.forEach((purchase) => {
        clearSearchMatches(purchase);
      });
    }
    if (selectedCategory) {
      filtered = filtered.filter((p) =>
        search(p, selectedCategory, ['package'], []),
      );
      setFiltered(true);
    }
    if (searchBoxTerm.length > 1) {
      filtered = filtered.filter((p) =>
        search(
          p,
          searchBoxTerm,
          [
            'firstName',
            'lastName',
            'section',
            'row',
            'seat',
            'originalOrderNumber',
            'phone',
            'seatLocation',
          ],
          ['Email'],
        ),
      );
      setFiltered(true);
    }

    setFilteredPurchases(filtered);
  }, [ticketPurchases, selectedFilter, selectedCategory, searchBoxTerm]);

  useEffect(() => {
    loadScheduleItem();
  }, [currentEvent]);

  const loadScheduleItem = () => {
    if (locator && scheduleItemId) {
      getScheduleItem(
        locator,
        scheduleItemId,
        function successFn(scheduleItem) {
          setScheduleItem(scheduleItem);
          loadTicketPurchases(locator, scheduleItemId);
        },
      );
    }
  };

  const loadTicketPurchases = (locator, scheduleItemId) => {
    getTicketPurchases(
      locator,
      scheduleItemId,
      function success(ticketPurchases) {
        setTicketPurchases(ticketPurchases);
        setLoading(false);
      },
    );
  };

  const ticketsUploaded = (currentImport: ImportPreviewResponse) => {
    setCurrentImport(currentImport);
  };

  const updateImportHeader = async (updatedImport: ImportPreviewResponse) => {
    if (locator && scheduleItemId) {
      const newImport = await updateTicketPurchasesImportHeaders(
        locator,
        scheduleItemId,
        updatedImport.id,
        updatedImport.headerMappings,
      );
      setCurrentImport(newImport);
    }
  };

  const applyTicketsUpload = async () => {
    try {
      await applyTicketPurchasesImport(
        locator,
        scheduleItemId,
        currentImport?.id,
      );
      loadTicketPurchases(locator, scheduleItemId);
      showInfoMsg('CSV successfully uploaded!');
    } catch {
      showErrorMsg('There was an error uploading your CSV', 5000);
    } finally {
      setCurrentImport(undefined);
    }
  };

  const cancelTicketsUpload = () => {
    setCurrentImport(undefined);
  };

  if (!currentEvent || !scheduleItem || loading) {
    return <PageLoadingIndicator />;
  }

  const uploadCSVButton = (
    <UploadTicketsCSVButton
      locator={locator}
      scheduleItemId={scheduleItem.id}
      onUpload={ticketsUploaded}
    />
  );

  return (
    <Page>
      <>
        <PageHeader actions={uploadCSVButton}>
          <Breadcrumb
            to={`/event/${locator}/ticketPurchases`}
            title="Ticket data"
          />
          {scheduleItem.location}
          {' - '}
          <time className="UploaderScheduleItemView__Date">
            {formatDate(scheduleItem.startsAt)}
          </time>
        </PageHeader>

        <CheckInStats
          purchases={ticketPurchases}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <GuestSearch
          selectedFilter={selectedFilter}
          searchBoxTerm={searchBoxTerm}
          setSearchBoxTerm={setSearchBoxTerm}
          setSelectedFilter={setSelectedFilter}
        />
        {noResults && (
          <div className="GuestSearch__Empty">
            <div className="GuestSearch__EmptyMessage">No results found</div>
          </div>
        )}
        {!noResults && (
          <TicketPurchasesList ticketPurchases={filteredPurchases} />
        )}

        {currentImport && (
          <ImportTicketPurchasesModal
            csvImport={currentImport}
            onUpdate={updateImportHeader}
            onImport={applyTicketsUpload}
            onCancel={cancelTicketsUpload}
          />
        )}
      </>
    </Page>
  );
};

export default TicketPurchasesPage;
