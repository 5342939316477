import classNames from 'classnames';
import calendarPath from 'images/icons/calendar.svg';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = DatePicker as any;

interface DateInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange'> {
  caption?: string;
  onChange: (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined,
  ) => void;
  optional?: boolean;
  value?: Date;
}

const DateInput: React.FC<DateInputProps> = ({
  name,
  caption = name,
  value,
  onChange,
  optional = false,
  autoFocus = false,
  readOnly,
}: DateInputProps) => {
  const className = classNames('Input', 'DateInput', 'Input--icon', {
    'Input--optional': optional,
  });
  return (
    <div className={className}>
      <label className="Input__Caption" htmlFor={name}>
        {caption}
      </label>
      <div className="Input__Control Input__Control--icon Input__Control--date">
        <img className="Input__Icon" src={calendarPath} role="presentation" />
        <CustomDatePicker
          className="Input__Picker TimeInput__Picker"
          name={name}
          selected={value}
          onChange={onChange}
          autoFocus={autoFocus}
          placeholderText={optional ? 'optional' : 'mm / dd / yyyy'}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default DateInput;
