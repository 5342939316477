import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode, useState } from 'react';
import './styles.scss';

type CustomSorter<T> = (desc: boolean) => (a: T, b: T) => number;

export type CustomSorters<T> = { [key: string]: CustomSorter<T> };

interface SortableTableProps<T> {
  headers: string[];
  keys: string[];
  items: T[];
  mapper: (item: T) => ReactNode;
  headerClasses?: { [key: string]: string };
  className: string;
  sorters?: CustomSorters<T>;
  defaultSortKey?: string;
}

const SortableTable = <T extends object>({
  headers,
  keys,
  items,
  mapper,
  headerClasses = {},
  className,
  sorters = {},
  defaultSortKey,
}: PropsWithChildren<SortableTableProps<T>>) => {
  const [sortBy, setSortBy] = useState(defaultSortKey || keys[0]);
  const [desc, setDesc] = useState(false);

  const defaultSorter = (a, b) => {
    const v1 = a[sortBy];
    const v2 = b[sortBy];
    let result;

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      result = v1 - v2;
    } else {
      result = String(v1).localeCompare(String(v2));
    }

    return desc ? -result : result;
  };

  const sortedData = () => {
    const result = items.slice();
    const fieldSorter = sorters[sortBy]?.(desc);
    result.sort(fieldSorter || defaultSorter);
    return result;
  };

  const setSort = (name) => {
    if (name === sortBy) {
      setDesc(!desc);
    } else {
      setSortBy(name);
      setDesc(false);
    }
  };

  return (
    <table className={classNames(className, 'Table')}>
      <thead>
        <tr>
          {headers.map((name, i) => (
            <th
              key={name}
              className={classNames('Table__Header', headerClasses[name])}
            >
              <a href="#" onClick={() => setSort(keys[i])}>
                {name}
                <span className="Table__SortIndicator">
                  {!!keys[i] && sortBy === keys[i] && (desc ? '▲' : '▼')}
                </span>
              </a>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{sortedData().map((row) => mapper(row))}</tbody>
    </table>
  );
};

export default SortableTable;
