import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  to: string;
  title: string;
}

export const Breadcrumb = ({ to, title }: Props) => {
  return (
    <>
      <Link to={to}>{title}</Link> &#x25b8;&nbsp;
    </>
  );
};
