import { notifyWaitingFans } from 'api';
import { showInfoMsg } from 'components/core/AlertMessages';
import Button from 'components/core/Button';
import NotifyPath from 'images/icons/notify.svg';
import React, { useState } from 'react';
import './style.scss';

interface NotifyFansButtonProps {
  locator?: string;
  scheduleItemId?: number;
  waitingFansCount?: number;
  postCount?: number;
  fansNotifiedAt?: Date;
}
const NotifyFansButton: React.FC<NotifyFansButtonProps> = ({
  postCount,
  locator,
  scheduleItemId,
  fansNotifiedAt: _fansNotifiedAt,
  waitingFansCount: _waitingFansCount,
}: NotifyFansButtonProps) => {
  const [waitingFansCount, setWaitingFansCount] = useState<number>(
    _waitingFansCount ?? 0,
  );
  const [fansNotifiedAt, setFansNotifiedAt] = useState<Date | undefined>(
    _fansNotifiedAt,
  );

  const showMessage = () => {
    if (!waitingFansCount || !postCount) {
      return false;
    }

    return !fansNotifiedAt;
  };

  const notify = () => {
    notifyWaitingFans(locator, scheduleItemId, () => {
      setFansNotifiedAt(new Date());
      setWaitingFansCount(0);
      showInfoMsg('Fans notified!');
    });
  };

  if (!showMessage()) {
    return null;
  }

  const count = waitingFansCount;
  const noun = count === 1 ? 'fan' : 'fans';

  const confirmMessage =
    `Are you sure you want to alert ${count} ${noun} ` +
    'that their photos are ready?';

  return (
    <Button
      danger
      className="NotifyFansButton"
      onClick={notify}
      confirmMessage={confirmMessage}
    >
      <img src={NotifyPath} className="NotifyFansButton__Icon" />
      <span className="NotifyFansButton__Text">
        Alert {count} {noun}!
      </span>
    </Button>
  );
};

export default NotifyFansButton;
