import { getTicketPackage } from 'api';
import { showErrorMsg } from 'components/core/AlertMessages';
import Page from 'components/core/Page';
import PageLoadingIndicator from 'components/core/PageLoadingIndicator';
import TicketPackageForm from 'components/TicketPackageForm';
import { TicketPackage } from 'models/TicketPackage';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const EditTicketPackagePage: React.FC = () => {
  const [ticketPackage, setTicketPackage] = useState<TicketPackage>(
    {} as TicketPackage,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const { locator, ticketPackageId } = useParams();

  useEffect(() => {
    loadTicketPackage();
  }, [locator, ticketPackageId]);

  const loadTicketPackage = () => {
    if (!locator) return null;

    const success = (ticketPackage: TicketPackage) => {
      setTicketPackage(ticketPackage);
      setLoading(false);
    };

    const failure = () => {
      showErrorMsg(
        'Failed to load ticket package. Please, try again or reload the page',
      );
    };

    getTicketPackage(locator, +(ticketPackageId ?? 0), success, failure);
  };

  return (
    <>
      {loading ? (
        <PageLoadingIndicator />
      ) : (
        <Page>
          <h1 className="Page__Title">Edit ticket package</h1>
          <TicketPackageForm ticketPackage={ticketPackage} />
        </Page>
      )}
    </>
  );
};

export default EditTicketPackagePage;
