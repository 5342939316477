import { formatLocalDate } from 'api/util';
import classNames from 'classnames';
import Button from 'components/core/Button';
import {
  compareAsc,
  differenceInCalendarDays,
  format,
  subDays,
} from 'date-fns';
import { DashboardQueryProps } from 'models';
import React, {
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';

const CustomDatePicker = DatePicker as any;

const DateRanges = {
  Week: {
    label: 'Last 7 days',
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
  },
  Month: {
    label: 'Last 30 days',
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  },
  Year: {
    label: 'Last 365 days',
    startDate: subDays(new Date(), 365),
    endDate: new Date(),
  },
};

export const checkDate = (value) => {
  let year, month, day;
  if (/\d{1,2}[-/]\d{1,2}[-/]\d{4}/.test(value)) {
    [month, day, year] = value.split(/[/-]/).map((v) => parseInt(v));
    if (month < 1 || day < 1 || year < 1) {
      throw new Error('Invalid date format');
    }
  } else {
    throw new Error('Invalid date range.');
  }
  const date = new Date(year, month - 1, day);
  return date;
};
interface RangeProps {
  label: string;
  startDate: Date;
  endDate: Date;
}
interface DateRangePickerProps {
  maxEndDate?: Date;
  onChange: (props: DashboardQueryProps) => void;
}
const DateRangePicker: React.FC<DateRangePickerProps> = ({
  maxEndDate,
  onChange,
}: DateRangePickerProps) => {
  const minDate = new Date(2013, 1, 1);
  const maxDate = maxEndDate ?? new Date();
  const [selecting, setSelecting] = useState<string>('');
  const [startDateField, setStartDateField] = useState<string>(
    formatLocalDate(DateRanges.Month.startDate),
  );
  const [endDateField, setEndDateField] = useState<string>(
    formatLocalDate(DateRanges.Month.endDate),
  );
  const [invalidText, setInvalidText] = useState<string>();
  const [startDate, setStartDate] = useState<Date>(DateRanges.Month.startDate);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [label, setLabel] = useState<string>(DateRanges.Month.label);
  const [query, setQuery] = useState<string>('');

  let popoverContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', checkFocus);
    return () => {
      document.removeEventListener('mousedown', checkFocus);
    };
  }, []);

  useEffect(() => {
    if (selecting === '') {
      handleChange();
    }
  }, [label, selecting]);

  useEffect(() => {
    onChange({ query, dateRange: label });
  }, [query]);

  const dateForLabel = (start, end) => {
    const startLabel = formatLocalDate(start);
    const endLabel = formatLocalDate(end);
    if (startLabel === endLabel) {
      return startLabel;
    }
    return startLabel + ' – ' + endLabel;
  };

  const handleChange = () => {
    const fromParam = format(startDate, 'yyyy-MM-dd');
    const forParam = differenceInCalendarDays(endDate, startDate) + 1;
    setQuery(`from=${fromParam}&for=${forParam}`);
    return query;
  };

  const selectStartDate = () => {
    setSelecting('startDate');
  };

  const selectEndDate = () => {
    setSelecting('endDate');
  };

  const checkFocus = (e: Event) => {
    if (
      popoverContainer.current &&
      !(popoverContainer.current as any).contains(e.target)
    ) {
      setSelecting('');
    }
  };
  const keyUp: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      if (invalidText) {
        alert('Invalid date range.');
      } else {
        setSelecting('');
      }
    }
  };

  const selectDateRange = (range: RangeProps) => {
    setStartDateField(formatLocalDate(range.startDate));
    setEndDateField(formatLocalDate(range.endDate));
    setStartDate(range.startDate);
    setEndDate(range.endDate);
    setLabel(range.label);
    setSelecting('');
  };

  const checkDateInput = (value) => {
    checkDate(value);
    setInvalidText('');
    return new Date(value);
  };

  const handleCalendarClick = (selectedDate) => {
    if (selecting === 'startDate') {
      setStartDate(selectedDate);
      setStartDateField(formatLocalDate(selectedDate));
      setEndDate(selectedDate);
      setSelecting('endDate');
      setLabel(dateForLabel(selectedDate, selectedDate));
    } else {
      const [newStartDate, newEndDate] = [startDate, selectedDate].sort(
        compareAsc,
      );
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setEndDateField(formatLocalDate(selectedDate));
      setSelecting('startDate');
      setLabel(dateForLabel(newStartDate, newEndDate));
    }
  };

  const handleStartDateChange = (e: React.FormEvent<HTMLInputElement>) => {
    setStartDateField(e.currentTarget.value);
    try {
      const date = e.currentTarget.value;
      const newDate = checkDateInput(date);
      if (newDate < minDate || newDate > endDate) {
        throw new Error('Invalid date range.');
      } else {
        setStartDate(newDate);
        setStartDateField(formatLocalDate(newDate));
        setLabel(dateForLabel(newDate, endDate));
        setInvalidText('');
      }
    } catch (error: any) {
      setInvalidText(error.message);
    }
  };

  const handleEndDateChange = (e) => {
    setEndDateField(e.currentTarget.value);
    try {
      const date = e.currentTarget.value;
      const newDate = checkDateInput(date);
      if (newDate > maxDate || newDate < startDate) {
        throw new Error('Invalid date range.');
      } else {
        setEndDate(newDate);
        setEndDateField(formatLocalDate(newDate));
        setLabel(dateForLabel(startDate, newDate));
        setInvalidText('');
      }
    } catch (error: any) {
      setInvalidText(error.message);
    }
  };
  const popover = () => {
    if (!selecting) {
      return null;
    }

    const startClasses = classNames(
      'Input__Control',
      'DateRangePicker__Input',
      {
        'DateRangePicker__Input--focused': selecting === 'startDate',
        'DateRangePicker__Input--invalid': !!invalidText,
      },
    );

    const endClasses = classNames('Input__Control', 'DateRangePicker__Input', {
      'DateRangePicker__Input--focused': selecting === 'endDate',
      'DateRangePicker__Input--invalid': !!invalidText,
    });

    return (
      <div className="DateRangePicker__Popover" ref={popoverContainer}>
        <CustomDatePicker
          className="DateRangePicker__Calendar"
          inline
          selected={selecting === 'startDate' ? startDate : endDate}
          endDate={endDate}
          startDate={startDate}
          selectsStart={selecting !== 'startDate'}
          selectsEnd={selecting !== 'startDate'}
          onChange={handleCalendarClick}
          minDate={minDate} //StagePilot did not exist prior to 2013
          maxDate={maxDate} // no future dates
        />

        <div className="DateRangePicker__Inputs">
          <div className="DateRangePicker__Inputs--start">
            <label htmlFor="startDate" className="DateRangePicker__Label">
              Start
            </label>
            <input
              name="startDate"
              className={startClasses}
              value={startDateField}
              placeholder="Start date"
              onFocus={selectStartDate}
              onChange={handleStartDateChange}
              onKeyUp={keyUp}
            />
          </div>

          <span className="DateRangePicker__InputSeparator">—</span>

          <div className="DateRangePicker__Inputs--end">
            <label htmlFor="startDate" className="DateRangePicker__Label">
              End
            </label>

            <input
              name="endDate"
              className={endClasses}
              value={endDateField}
              placeholder="End date"
              onFocus={selectEndDate}
              onChange={handleEndDateChange}
              onKeyUp={keyUp}
            />
          </div>
        </div>
        {invalidText ? (
          <div className="DateRangePicker__Error">
            <p className="DateRangePicker__Error_Text">{invalidText}</p>
          </div>
        ) : null}

        <div className="DateRangePicker__Ranges">
          <div className="DateRangePicker__Label">Last</div>

          <div className="DateRangePicker__Buttons">
            {Object.values(DateRanges).map((range) => {
              return (
                <Button
                  outline
                  key={range.label}
                  caption={range.label}
                  onClick={() => selectDateRange(range)}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="DateRangePicker">
      <Button secondary caption={label} onClick={selectStartDate} />
      {popover()}
    </div>
  );
};

export default DateRangePicker;
