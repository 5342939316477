import React from 'react';
import classNames from 'classnames';
import ToggleSwitch from 'components/core/ToggleSwitch';

const ToggleField = ({
  name,
  caption = name,
  checked,
  onChange,
  fullWidth = false,
  disabled = false,
  testid = '',
  children,
}) => {
  return (
    <label className="Input Input--tall">
      <span
        className={classNames('Input__Caption', 'Input__Caption--toggle', {
          'Input__Caption--fullWidth': fullWidth,
        })}
      >
        {caption}
      </span>

      <div
        className={classNames('Input__Control--toggle', {
          'Input__Control--disabled': disabled,
        })}
      >
        <ToggleSwitch
          name={name}
          disabled={disabled}
          checked={checked}
          testid={testid}
          onChange={onChange}
        />

        <div className="Input__Control--toggle-text">{children}</div>
      </div>
    </label>
  );
};

export default ToggleField;
