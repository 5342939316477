import { getToken } from 'localStorage';

// for validation.
//   check an object for expected properties
export const hasRequiredProperties = <T>(
  obj: Partial<T> | undefined,
  props: (keyof T)[],
): boolean => {
  return props.reduce((value, key) => value && !!obj?.[key], true);
};

// trigger a download from the API
//   passes the JWT token
export const triggerDownload = (url: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.setRequestHeader('Authorization', `Bearer ${getToken()}`);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (this.status === 200) {
      const blob = this.response;
      let filename = '';
      const disposition = xhr.getResponseHeader('Content-Disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        filename = matches?.[1].replace(/['"]/g, '') ?? '';
      }
      const URL = window.URL ?? window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);
      if (filename) {
        const a = document.createElement('a');
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      } else {
        window.location.href = downloadUrl;
      }
      setTimeout(function () {
        URL.revokeObjectURL(downloadUrl);
      }, 100); // cleanup
    }
  };
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  xhr.send();
};
