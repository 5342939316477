import classNames from 'classnames';
import React from 'react';

interface LinkButtonProps {
  id?: string;
  testid?: string;
  caption?: string;
  className?: string;
  disabled?: boolean;
  light?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
const LinkButton: React.FC<LinkButtonProps> = ({
  id,
  testid,
  caption,
  onClick,
  light,
  disabled,
  className,
}: LinkButtonProps) => (
  <button
    id={id}
    data-testid={testid}
    className={classNames(
      'LinkButton',
      {
        'LinkButton--light': light,
      },
      className,
    )}
    disabled={disabled}
    onClick={onClick}
  >
    {caption}
  </button>
);

export default LinkButton;
