import { photographerSignUp } from 'api';
import classNames from 'classnames';
import Button from 'components/core/Button';
import Form from 'components/core/Form';
import Input from 'components/core/Input';
import { AppContext, IAppContext } from 'context';
import { popLoginDestination, setLoginDestination } from 'localStorage';
import { Photographer } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import './style.scss';
import Copyright from 'components/Copyright';

const PhotographerSignupForm: React.FC = () => {
  const { event } = useParams();
  const [photographer, setPhotographer] = useState<Photographer>();
  const [signupStep, setSignupStep] = useState<boolean>(false);
  const [signupEnabled, setSignupEnabled] = useState<boolean>(false);
  const [doRedirect, setDoRedirect] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});

  const { user, setUser } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    setSignupEnabled(
      !!photographer?.email &&
        !!photographer?.password &&
        !!photographer?.firstName &&
        !!photographer?.lastName &&
        signupStep,
    );
  }, [photographer, signupStep]);

  const updatePhotographer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    setPhotographer({ ...photographer, [key]: value });
  };

  const handleNext = (e: React.MouseEvent) => {
    if (!user) {
      e.preventDefault();
      setSignupStep(true);
    }
  };

  const handleSignup = (e: React.FormEvent) => {
    e.preventDefault();

    const success = (json: any) => {
      const { user: aNewUser = {} } = json || {};
      popLoginDestination();
      setDoRedirect(true);
      if (aNewUser.email) {
        setUser(aNewUser);
      }
    };

    const failure = ({ errors }) => {
      setErrors(errors);
    };

    photographerSignUp(event ?? '', photographer, success, failure);
  };

  useEffect(() => {
    setLoginDestination(window.location.pathname);
  }, []);

  if (doRedirect) {
    return <Navigate to={`/event/${event}/uploader`} />;
  }

  const signupStepClasses = classNames('SignupStep', {
    'SignupStep--disabled': !signupStep,
  });

  const newUser = !user;
  const action = newUser ? 'Next' : 'Add';

  return (
    <div className="PhotographerSignupForm FullPageForm">
      <div className="FullPageForm__Hero">
        <h1>StagePilot has been updated!</h1>
        <p>Here's what you need to do:</p>
      </div>
      <Form onSubmit={handleSignup} errors={errors}>
        <h3 className="FullPageForm__Subtitle">
          {newUser && '1. '}
          Enter your existing uploader password
        </h3>
        <Input
          hiddenCaption
          className="UploadPassword"
          type="text"
          name="uploadPassword"
          value={photographer?.uploadPassword}
          onChange={updatePhotographer}
        >
          <Button
            primary
            type="submit"
            caption={action}
            enabled={!!photographer?.uploadPassword}
            onClick={handleNext}
          />
        </Input>

        {newUser ? (
          <>
            <hr className="Separator" />

            <div className={signupStepClasses}>
              <h3 className="FullPageForm__Subtitle">
                2. Create a StagePilot VIP account
              </h3>
              <p className="FullPageForm__Description">
                This is how you'll log in to the StagePilot VIP uploader from
                now on.
                <br />
                Already have a StagePilot VIP account?{' '}
                <Link to="/login">Log in now.</Link>
              </p>

              <Input
                caption="Your first name"
                type="text"
                name="firstName"
                value={photographer?.firstName}
                onChange={updatePhotographer}
                fullWidth
              />
              <Input
                caption="Your last name"
                type="text"
                name="lastName"
                value={photographer?.lastName}
                onChange={updatePhotographer}
                fullWidth
              />
              <Input
                caption="Your email address"
                type="text"
                name="email"
                value={photographer?.email}
                onChange={updatePhotographer}
                fullWidth
              />
              <Input
                caption="Choose a password"
                type="password"
                name="password"
                value={photographer?.password}
                onChange={updatePhotographer}
                fullWidth
              />
            </div>
            <Button
              primary
              type="submit"
              caption={action}
              enabled={signupEnabled}
            />
          </>
        ) : (
          <>
            This event will be added to your StagePilot VIP account.
            <br /> You are logged in as {user.email}. Wrong account?{' '}
            <a href="/logout">Switch accounts now.</a>
          </>
        )}
      </Form>
      {newUser && (
        <footer className="FullPageForm__Footer">
          <span className="FullPageForm__Footer--right">
            <a href="https://stagepilot.com/legal/privacy" target="_blank">
              Privacy Policy
            </a>
            {' | '}
            <a
              href="https://www.stagepilot.com/legal/terms-of-use"
              target="_blank"
            >
              Terms and Conditions
            </a>
          </span>
          <Copyright />
        </footer>
      )}
    </div>
  );
};

export default PhotographerSignupForm;
