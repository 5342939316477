import classNames from 'classnames';
import closeIconPath from 'images/icons/close.svg';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

const CloseButton = ({ onClick }) => (
  <button data-testid="modal-close" className="Modal__Close" onClick={onClick}>
    <img src={closeIconPath} />
  </button>
);

export const ModalButtons = ({ children }) => (
  <div className="Modal__Buttons">{children}</div>
);

interface ModalProps extends React.HTMLProps<HTMLDivElement> {
  darkHeader?: boolean;
  open?: boolean;
  optional?: boolean;
  title: string;
  onClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  className,
  darkHeader = false,
  open = false,
  optional = true,
  title,
  onClose,
}: ModalProps) => {
  const screen = useRef();

  const screenClick = (e: React.MouseEvent) => {
    if (optional && e.target === screen.current) {
      close(e);
    }
  };

  const close = (e: React.MouseEvent) => {
    e.preventDefault();
    onClose?.();
  };

  if (!open) {
    return null;
  }

  const root = document.getElementById('ModalPortal');
  if (!root) {
    console.log("No rendering modal because the portal doesn't exist yet");
    return null;
  }

  const cssClassName = classNames('Modal', className);
  const headerClassName = classNames('Modal__Header', {
    'Modal__Header--dark': darkHeader,
  });

  return createPortal(
    <div className={cssClassName}>
      <div className="Modal__Screen" ref={screen.current} onClick={screenClick}>
        <div className="Modal__Window">
          <div className={headerClassName}>
            <h1>{title}</h1>
            <CloseButton onClick={close} />
          </div>
          <div className="Modal__Body">{children}</div>
        </div>
      </div>
    </div>,
    root,
  ) as React.ReactNode;
};

export default Modal;
