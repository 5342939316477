import classNames from 'classnames';
import Logo from 'components/Logo';
import closeIconPath from 'images/icons/close.svg';
import React from 'react';

const CloseButton = ({ onClick }) => (
  <button className="FullModal__Close" onClick={onClick}>
    <img src={closeIconPath} />
  </button>
);

interface FullModalProps {
  fillWidth?: boolean;
  open?: boolean;
  onClose?: () => void;
  children?: JSX.Element | JSX.Element[] | null;
}
const FullModal: React.FC<FullModalProps> = ({
  fillWidth,
  open,
  onClose,
  children,
}: FullModalProps) => {
  const close = (e: React.MouseEvent) => {
    e.preventDefault();
    onClose?.();
  };
  if (!open) {
    return null;
  }

  const className = classNames('FullModal', {
    'FullModal--fill-width': fillWidth,
  });

  return (
    <div className={className}>
      <div className="FullModal__Header">
        <Logo dark centered={undefined} />
        {onClose ? <CloseButton onClick={close} /> : null}
      </div>
      <div className="FullModal__Body">{children}</div>
    </div>
  );
};

export default FullModal;
