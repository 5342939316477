import { getCurrentUser, saveCurrentUser } from 'api';
import { showInfoMsg } from 'components/core/AlertMessages';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import Form from 'components/core/Form';
import Input from 'components/core/Input';
import { AppContext, IAppContext } from 'context';
import { UserSimple, emptyUser } from 'models/User';
import React, { useContext, useEffect, useState } from 'react';

const UserProfile: React.FC = () => {
  const [userData, setUserData] = useState<UserSimple>(emptyUser);
  const [errors, setErrors] = useState<object>({});
  const { user } = useContext<IAppContext>(AppContext);

  const updateProperties = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    setUserData({ ...userData, [key]: value });
  };

  const loadUserDetails = () => {
    getCurrentUser((user: UserSimple & { errors: any }) => {
      const { firstName, lastName, email, errors: _errors } = user;
      setUserData({ ...userData, firstName, lastName, email });
      setErrors(_errors);
    });
  };

  useEffect(() => {
    loadUserDetails();
  }, [user]);

  const save = () => {
    const success = () => {
      showInfoMsg('Profile saved');
    };

    const failure = (errors: any) => {
      setErrors(errors.errors);
    };
    saveCurrentUser(userData, success, failure);
  };

  return (
    <Form errors={errors}>
      <div className="FieldSet">
        <Input
          testid="UserProfile-firstName"
          name="firstName"
          caption="Name"
          placeholder="First name"
          value={userData?.firstName}
          onChange={updateProperties}
          autoFocus
        >
          <input
            data-testid="UserProfile-lastName"
            className="Input__Control Input__Control--inline"
            name="lastName"
            placeholder="Last name"
            value={userData?.lastName}
            onChange={updateProperties}
          />
        </Input>
        <Input
          testid="UserProfile-email"
          name="email"
          caption="Email address"
          value={userData?.email}
          onChange={updateProperties}
        />
      </div>

      <ButtonPanel>
        <Button caption="Save" disabled={false} onClick={save} primary />
      </ButtonPanel>
    </Form>
  );
};

export default UserProfile;
