import { AppContext, IAppContext } from 'context';
import React, { useContext } from 'react';

export interface IfPermissionLevelProps {
  requiredLevel: any;
  children: JSX.Element | JSX.Element[];
}
export const IfPermissionLevel = ({ requiredLevel, children }) => {
  const { hasRole } = useContext<IAppContext>(AppContext);
  if (hasRole(requiredLevel)) {
    return children;
  }
  return null;
};

export const IfOwner = ({ children }) => {
  return <IfPermissionLevel requiredLevel="o">{children}</IfPermissionLevel>;
};

export const IfAdmin = ({ children }) => {
  return <IfPermissionLevel requiredLevel="a">{children}</IfPermissionLevel>;
};
