import { AlertState } from 'components/core/AlertMessages';
import LinkButton from 'components/core/LinkButton';
import { IUploaderContext, UploaderContext } from 'context';
import { ACTIVE, ERROR } from 'models/UploadQueue';
import React, { useContext } from 'react';

const UploaderOverviewErrors: React.FC = () => {
  const { progress, retryFailed } =
    useContext<IUploaderContext>(UploaderContext);
  let active = 0,
    failed = 0,
    rejected = 0;

  Object.values(progress ?? {}).map((p: any) => {
    if (p.state === ACTIVE) active++;
    if (p.state === ERROR) {
      failed += p.failed.filter((i) => i.retryable).length;
      rejected += p.failed.filter((i) => !i.retryable).length;
    }
  });

  if ((failed === 0 && rejected === 0) || active > 0) return null;

  let parts: string[] = [];
  if (failed > 0) {
    parts = parts.concat(`${failed} failed`);
  }
  if (rejected > 0) {
    parts = parts.concat(`${rejected} rejected`);
  }
  let title = parts.join(', ');

  return (
    <AlertState type="error">
      <>
        Upload problem: {title}
        {failed > 0 && (
          <span>
            &nbsp;
            <LinkButton light onClick={() => retryFailed()} caption="Retry" />
          </span>
        )}
      </>
    </AlertState>
  );
};

export default UploaderOverviewErrors;
