import React from 'react';

export interface IModalContext {
  open: string | boolean | null;
  children?: JSX.Element | JSX.Element[] | null;
  showCreateEventModal: (show: any) => void;
}

const defaultContext = {
  open: null,
  showCreateEventModal: () => undefined,
};

export const ModalContext = React.createContext<IModalContext>(defaultContext);

export class ModalProvider extends React.Component<IModalContext> {
  showCreateEventModal = (show) => {
    const open = show ? 'create-event' : null;
    this.setState({ open });
  };

  state = {
    open: null,
    showCreateEventModal: this.showCreateEventModal,
  };

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}
