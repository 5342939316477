import Button from 'components/core/Button';
import React, { useState } from 'react';

interface ConfirmationButtonProps {
  caption: string;
  onClick?: (event: React.MouseEvent) => void;
}
const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({
  caption,
  onClick,
}: ConfirmationButtonProps) => {
  const [confirming, setConfirming] = useState<boolean>(false);

  const onClickConfirmation = (e) => {
    onClick?.(e);
    setConfirming(false);
  };

  return (
    <span className="ConfirmationButton">
      {confirming ? (
        <Button
          testid="ConfirtmatonButton"
          danger
          caption="Are you sure?"
          onBlur={() => setConfirming(false)}
          onClick={onClickConfirmation}
        />
      ) : (
        <Button
          testid="ConfirtmatonButton"
          tertiary
          caption={caption}
          onClick={() => setConfirming(true)}
        />
      )}
    </span>
  );
};

export default ConfirmationButton;
