import {
  createScheduleItem,
  deleteScheduleItem,
  updateScheduleItem,
} from 'api';
import Button from 'components/core/Button';
import ConfirmationButton from 'components/core/ConfirmationButton';
import DateInput from 'components/core/DateInput';
import Input from 'components/core/Input';
import Modal, { ModalButtons } from 'components/core/Modal';
import TimeInput from 'components/core/TimeInput';
import ToggleField from 'components/core/ToggleField';
import { AppContext, IAppContext } from 'context';
import { format, setHours, setMinutes } from 'date-fns';
import { ScheduleItem } from 'models';
import { emptyScheduleItem } from 'models/ScheduleItem';
import React, { useContext, useEffect, useState } from 'react';
import { hasRequiredProperties } from 'utils';

interface ScheduleItemModalProps {
  item?: ScheduleItem;
  createNew?: boolean;
  itemAdded?: (item: any) => void;
  itemRemoved?: (item: any) => void;
  onClose?: () => void;
}
const ScheduleItemModal: React.FC<ScheduleItemModalProps> = ({
  item,
  createNew = false,
  itemAdded,
  itemRemoved,
  onClose,
}: ScheduleItemModalProps) => {
  const [scheduleItem, setScheduleItem] = useState<ScheduleItem>({
    ...emptyScheduleItem,
  });
  const [enabled, setEnabled] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const [date, setDate] = useState<Date>();
  const [time, setTime] = useState<Date>();

  const { currentEvent } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    if (item) setScheduleItem(item);
  }, [item]);

  useEffect(() => {
    setDate(parseAsLocalDate(scheduleItem?.startsAt));
    setTime(parseTime(scheduleItem?.time));
    setEnabled(
      hasRequiredProperties<ScheduleItem>(scheduleItem, [
        'location',
        'startsAt',
      ]),
    );
  }, [scheduleItem]);

  const updateItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    setScheduleItem({ ...scheduleItem, [key]: value });
  };

  const updateStartsAt = (value: Date | null) => {
    if (!value) {
      return;
    }
    const startsAt = toUTCDateString(value);
    setScheduleItem({ ...scheduleItem, startsAt });
  };

  const updateTime = (value?: Date) => {
    const time = value ? format(value, 'h:mm a') : '';
    setScheduleItem({ ...scheduleItem, time });
  };

  const toogleVisible = (value) => {
    setHidden(!value);
  };

  const saveAndAddAnother = () => {
    save(() => {
      setScheduleItem({ ...emptyScheduleItem });
      setEnabled(false);
    });
  };

  const saveAndClose = () => {
    save(() => {
      onClose?.();
    });
  };

  const deleteItem = () => {
    const success = (json: any) => {
      itemRemoved?.(json.scheduleItem);
      onClose?.();
    };
    deleteScheduleItem(currentEvent?.locator, item?.id, success);
  };

  const save = (callback?: () => void) => {
    if (!scheduleItem) return false;
    const success = (json) => {
      itemAdded?.(json.scheduleItem);
      callback?.();
    };
    if (createNew) {
      createScheduleItem(currentEvent?.locator ?? '', scheduleItem, success);
    } else {
      updateScheduleItem(currentEvent?.locator ?? '', scheduleItem, success);
    }
  };

  const title = createNew ? 'New schedule item' : 'Edit schedule item';
  const readonly = !!item?.bandsintownEventId;

  return (
    <Modal open={!!item} title={title} onClose={onClose}>
      <form className="ScheduleItemForm">
        <Input
          caption="Location"
          autoFocus
          name="location"
          placeholder="City, State, Country"
          value={scheduleItem?.location}
          onChange={updateItem}
          readOnly={readonly}
        />
        <Input
          caption="Additional info"
          optional
          name="description"
          value={scheduleItem?.description}
          onChange={updateItem}
          readOnly={readonly}
        />
        <Input
          caption="Password"
          optional
          name="password"
          value={scheduleItem?.password}
          onChange={updateItem}
        />
        <DateInput
          caption="Date"
          name="startsAt"
          value={date}
          onChange={updateStartsAt}
          readOnly={readonly}
        />
        <TimeInput
          caption="Time"
          optional
          name="time"
          value={time}
          onChange={updateTime}
          readOnly={readonly}
        />
        {!createNew && (
          <ToggleField
            name="hidden"
            caption="Hide/show"
            checked={!scheduleItem?.hidden}
            onChange={toogleVisible}
          >
            {hidden ? "Fans won't see this item" : 'Fans will see this item'}
          </ToggleField>
        )}
        <ModalButtons>
          {!createNew && !readonly && (
            <ConfirmationButton
              caption="Delete this item"
              onClick={deleteItem}
            />
          )}
          {createNew && (
            <Button
              caption="Save and add another item"
              onClick={saveAndAddAnother}
              enabled={enabled}
            />
          )}
          <Button
            testid="ScheduleItemModal_SaveButton"
            primary
            caption="Save"
            onClick={saveAndClose}
            enabled={enabled}
          />
        </ModalButtons>
      </form>
    </Modal>
  );
};

// react-datepicker only works with local dates, however we need our date
// strings to be in UTC.  So we should parse them into local dates on the way
// in, and the return them as UTC strings on the way out.

export const parseAsLocalDate = (value?: string): Date | undefined => {
  if (!value) return;
  const dt = new Date(value);
  return new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate());
};

export const toUTCDateString = (value: Date): string => {
  const dt = new Date(
    Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()),
  );
  return dt.toISOString().substring(0, 10);
};

export function parseTime(value?: string): Date | undefined {
  if (!value) {
    return;
  }
  const matchResult = value.match(/(\d+):(\d+) (\w+)/);
  if (!matchResult) {
    console.log('bad time format');
    return;
  }
  const [_m, h, m, meridian] = matchResult;
  let hours = parseInt(h, 10);
  let minutes = parseInt(m, 10);

  if (meridian == 'PM') {
    hours += 12;
  }
  if (hours % 12 === 0) {
    hours -= 12;
  }
  let result = new Date();
  result = setHours(result, hours);
  result = setMinutes(result, minutes);
  return result;
}

export default ScheduleItemModal;
