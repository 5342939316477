import { getBandsintownSettings, saveBandsintownSettings } from 'api';
import { getArtistInfo } from 'api/bandsintown';
import { formatLocalDate, parseDate } from 'api/util';
import Button from 'components/core/Button';
import ConfirmationButton from 'components/core/ConfirmationButton';
import DateInput from 'components/core/DateInput';
import Input from 'components/core/Input';
import Modal, { ModalButtons } from 'components/core/Modal';
import loadingLogo from 'images/icons/loading.svg';
import React, { useEffect, useState } from 'react';

const DEBOUNCE_DELAY = 500;

interface BandsintownModalProps {
  locator?: string;
  open?: boolean;
  onClose?: (reload: boolean) => void;
}

const BandsintownModal: React.FC<BandsintownModalProps> = ({
  locator,
  open,
  onClose,
}: BandsintownModalProps) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  const [artist, setArtist] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToFind, setFailedToFind] = useState<boolean>(false);
  const [confirmedArtist, setConfirmedArtist] = useState<string | null>();
  const [confirmedIcon, setConfirmedIcon] = useState<string | undefined>();
  const [confirmedUrl, setConfirmedUrl] = useState<string | undefined>();
  const [synced, setSynced] = useState<boolean>(false);
  const [startsAt, setStartsAt] = useState<any>();
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout>();
  const updateStartsAt = (value) => {
    setStartsAt(value);
  };

  const updateArtist = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArtist(e.target.value);
    // debounce
    clearTimeout(debounceTimeout);
    setDebounceTimeout(
      setTimeout(() => {
        updatePreview(e.target.value);
      }, DEBOUNCE_DELAY),
    );
  };

  const updatePreview = async (artist) => {
    setLoading(true);
    setFailedToFind(false);
    setConfirmedArtist(null);

    const { url, name, icon } = await getArtistInfo(artist);
    setLoading(false);
    setFailedToFind(!!artist && !name);
    setConfirmedArtist(name);
    setConfirmedIcon(icon);
    setConfirmedUrl(url);
  };

  const loadSettings = () => {
    if (!artist) {
      getBandsintownSettings(locator, (s) => {
        const {
          id: bandsId,
          artist: bandsArtist,
          startsAt: bandsStartsAt,
        } = s.bandsintownSettings;
        setSynced(!!bandsId);
        setArtist(bandsArtist);
        setStartsAt(bandsStartsAt && parseDate(bandsStartsAt));
        updatePreview(bandsArtist);
      });
    }
  };

  const unsyncEvent = () => {
    setArtist(null);
    setLoading(false);
    setFailedToFind(false);
    setConfirmedArtist(null);
    setConfirmedIcon(undefined);
    setConfirmedUrl(undefined);
    clearArtist();
    loadSettings();
  };
  const triggerSave = () => {
    setSaving(true);
    setShouldSave(true);
  };
  const clearArtist = () => {
    saveBandsintownSettings(locator, null, null, () => clearFunc());
  };
  const clearFunc = () => {
    setSaving(false);
    onClose?.(true);
  };
  const save = () => {
    saveBandsintownSettings(locator, artist, startsAt, () => clearFunc());
  };
  useEffect(() => {
    setSaveEnabled(!!confirmedArtist || !!artist);
  }, [confirmedArtist, artist]);

  useEffect(() => {
    if (!locator) {
      return;
    }
    loadSettings();
  });

  useEffect(() => {
    if (shouldSave) {
      save();
      setSynced(true);
      setShouldSave(false);
    }
  }, [shouldSave]);

  return (
    <Modal
      open={open}
      title="Bandsintown sync"
      onClose={() => onClose?.(false)}
    >
      <form className="BandsintownForm BandsintownModal">
        {synced && (
          <p>
            Synced with Bandsintown
            {startsAt && <span> from {formatLocalDate(startsAt)}</span>}
          </p>
        )}

        {!synced && (
          <div className="BandsintownForm_Fields">
            <Input
              caption="Artist"
              autoFocus
              autoComplete="off"
              name="artist"
              placeholder="As shown on bandsintown.com"
              value={artist as string}
              onChange={updateArtist}
            />
            <DateInput
              caption="Start date"
              name="startsAt"
              value={startsAt}
              onChange={updateStartsAt}
            />
          </div>
        )}

        <div className="BandsintownModal_ConfirmationPanel">
          {loading && (
            <div className="BandsintownModal_Loading">
              <img src={loadingLogo} />
            </div>
          )}

          {confirmedArtist && (
            <>
              <img className="BandsintownModal_Icon" src={confirmedIcon} />
              <p className="BandsintownModal_ConfirmedInfo">
                {confirmedArtist}
                <br />
                <a href={confirmedUrl} target="_blank">
                  View on Bandsintown
                </a>
              </p>
            </>
          )}
          {failedToFind && (
            <div className="BandsintownModal_NotFound">not found</div>
          )}
        </div>
        <ModalButtons>
          {synced && (
            <ConfirmationButton
              caption={!saving ? 'Unsync event' : 'Saving...'}
              onClick={unsyncEvent}
            />
          )}

          {!synced && (
            <Button
              primary
              caption={'Save'}
              onClick={triggerSave}
              enabled={saveEnabled}
            />
          )}
        </ModalButtons>
      </form>
    </Modal>
  );
};

export default BandsintownModal;
