import { formatDate } from 'api/util';
import { User } from 'models';
import Link from './Link';

export default interface Note {
  id: number;
  ticketPurchaseId: number;
  userId: number;
  user: User;
  userLink: Link;
  content: string;
  createdAt: string;
  updatedAt: string;
}

export const time = (n: Note) => {
  return formatDate(new Date(n.createdAt), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};
