import React from 'react';
import { AppProvider, ModalProvider, UploaderProvider } from 'context';
import Routing from 'components/Routing';
import UploaderUnloadWarning from 'components/UploaderUnloadWarning';
import DragDropIgnorer from 'components/DragDropIgnorer';
import IntercomSync from 'components/IntercomSync';

class App extends React.Component {
  render() {
    return (
      <AppProvider>
        <ModalProvider>
          <UploaderProvider>
            <UploaderUnloadWarning />
            <DragDropIgnorer />
            <Routing />
            <IntercomSync />
          </UploaderProvider>
        </ModalProvider>
      </AppProvider>
    );
  }
}

export default App;
