import React from 'react';

interface SelectProps {
  name?: string;
  caption?: string;
  options?: any[];
  value?: string;
  testid?: string;
  onChange?: any;
}
const Select = ({
  name = '',
  caption = '',
  value = '',
  options = [],
  testid = '',
  onChange,
}: SelectProps) => {
  const o = options.map((item) => (
    <option value={item[0]} key={item[0]}>
      {item[1]}
    </option>
  ));

  return (
    <div className="Select">
      <label htmlFor={name} className="Select__Caption">
        {caption}
      </label>
      <select
        data-testid={testid}
        className="Select__Control"
        onChange={onChange}
        name={name}
        value={value}
      >
        {o}
      </select>
    </div>
  );
};

export default Select;
