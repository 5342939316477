export default class FileTraverser {
  dataTransfer: DataTransfer;
  items: DataTransferItemList;
  supportsDirectories: boolean;

  constructor(dataTransfer: DataTransfer) {
    this.dataTransfer = dataTransfer;
    this.items = dataTransfer.items;

    this.supportsDirectories =
      this.items && !!this.items.length && !!this.items[0].webkitGetAsEntry;
  }

  files = async () => {
    if (!this.supportsDirectories) {
      return this.dataTransfer.files;
    }

    const entries = Array.from(this.items).map((i) => i.webkitGetAsEntry());
    let results = await Promise.all(
      entries.map((entry) => this.filesFromEntry(entry)),
    );

    // This is equivalent to Array.prototype.flat which doesn't seem to work on Edge
    results = results.reduce((acc, val) => acc.concat(val), []);
    return results.filter((f) => !!f && !f.name.startsWith('.'));
  };

  filesFromEntry = (entry: FileSystemEntry | null) => {
    if (!entry) {
      return null;
    }
    if (entry.isFile) {
      return this.fileFromFileEntry(entry as FileSystemFileEntry);
    } else {
      return this.filesFromDirectoryEntry(entry as FileSystemDirectoryEntry);
    }
  };

  fileFromFileEntry = async (entry: FileSystemFileEntry): Promise<File> => {
    return new Promise((resolve, reject) => {
      entry.file((file) => resolve(file), reject);
    });
  };

  filesFromDirectoryEntry = async (
    directoryEntry: FileSystemDirectoryEntry,
  ) => {
    const entries = await this.allEntriesForDirectoryEntry(directoryEntry);
    let results = await Promise.all(entries.map((e) => this.filesFromEntry(e)));

    // This is equivalent to Array.prototype.flat which doesn't seem to work on Edge
    results = results.reduce((acc, val) => acc.concat(val), []);

    return results.filter((f) => !!f);
  };

  allEntriesForDirectoryEntry = async (
    diretoryEntry: FileSystemDirectoryEntry,
  ) => {
    const directoryReader = diretoryEntry.createReader();

    const readChunk = async () => {
      return new Promise<FileSystemEntry[]>((resolve) => {
        directoryReader.readEntries((entries: FileSystemEntry[]) =>
          resolve(entries),
        );
      });
    };

    let entries: FileSystemEntry[] = [];
    let done = false;
    while (!done) {
      const chunk = await readChunk();
      if (chunk.length > 0) {
        entries = entries.concat(chunk);
      } else {
        done = true;
      }
    }
    return entries;
  };
}
