import { getSalesByWeekdayHour } from 'api';
import { moneyFormatter } from 'api/util';
import { Day, Hour, HourlyTable } from 'components/core/HourlyTable';
import { DashboardQueryProps } from 'models';
import React, { useEffect, useState } from 'react';
import './style.scss';

const DashboardSalesTable: React.FC<DashboardQueryProps> = ({
  locator,
  query,
  dateRange,
}: DashboardQueryProps) => {
  const [salesByDay, setSalesByDay] = useState<Day[]>([]);
  const [empty, setEmpty] = useState<boolean>(false);

  useEffect(() => {
    getSalesByWeekdayHour(locator, query, parseAPIResponse);
  }, [query, locator, dateRange]);

  const parseAPIResponse = (apiResponse: any) => {
    const { days, maxValue } = buildDays(apiResponse);
    setSalesByDay(days);
    setEmpty(maxValue === 0);
  };

  return (
    <div className="DashboardCard DashboardSalesTable">
      <HourlyTable
        header={dateRange}
        title="Sales by time of day"
        days={salesByDay}
        empty={empty}
      />
    </div>
  );
};

export const buildDays = (apiResponse: any) => {
  const salesMap = {};
  let maxValue = 0;

  apiResponse.sales.forEach((r) => {
    const amount = r.total;
    const total = amount ? amount.total : 0;

    const [day, hour] = r.weekdayHour.split(/\s+/).map((d) => parseInt(d, 10));
    const key = `${day} ${Math.floor(hour / 4)}`;

    salesMap[key] = total + (salesMap[key] || 0);
    maxValue = Math.max(maxValue, salesMap[key]);
  });

  const days: Day[] = [];

  for (let d = 1; d < 8; d++) {
    const hours: Hour[] = [];

    for (let h = 0; h < 6; h++) {
      const key = `${d} ${Math.floor(h)}`;
      const total = salesMap[key] || 0;

      const ratio = total / maxValue || 0;
      const label = moneyFormatter(total * 100);
      hours.push(new Hour(label, ratio));
    }

    const day = new Day(d, hours);
    days.push(day);
  }

  return { days, maxValue };
};

export default DashboardSalesTable;
