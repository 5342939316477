import React from 'react';
import { ValidationErrors } from './ValidationErrors';

const Form = ({ children, errors, ...rest }) => (
  <form className="Form" {...rest}>
    {ValidationErrors({ errors: errors })}
    {children}
  </form>
);

export default Form;
