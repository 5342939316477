import { IfPermissionLevel } from 'components/core/Authorization';
import Button from 'components/core/Button';
import { AppContext, IAppContext } from 'context';
import { ValidUser } from 'models/User';
import React, { useContext, useState } from 'react';
import './styles.scss';

interface RemoveTeamMemberButtonProps {
  member: ValidUser;
  removeTeamMemberHandler?: (member: ValidUser) => void;
}

export const RemoveTeamMemberButton: React.FC<RemoveTeamMemberButtonProps> = ({
  member,
  removeTeamMemberHandler,
}: RemoveTeamMemberButtonProps) => {
  const [deleting, setDeleting] = useState<boolean>(false);
  const { user, currentRole } = useContext<IAppContext>(AppContext);

  const deleteUser = () => {
    setDeleting(true);
    removeTeamMemberHandler?.(member);
  };

  // Don't show the remove button for the current logged in user
  if (member.email === user?.email) {
    return null;
  }

  return (
    <IfPermissionLevel requiredLevel={currentRole}>
      <Button
        danger
        testid={`RemoveTeamMemberButton_${user?.email}`}
        confirmMessage="Are you sure you want to remove this user?"
        busyMessage={deleting ? 'Deleting...' : undefined}
        onClick={() => deleteUser()}
      >
        <div>Remove</div>
      </Button>
    </IfPermissionLevel>
  );
};

export default RemoveTeamMemberButton;
