import React from 'react';
import { formatDate } from 'api/util';
import './style.scss';
import { TicketPurchaseActivity } from 'models/TicketPurchase';

interface Props {
  activity: TicketPurchaseActivity;
}

function formatTime(timestamp: Date | string): string {
  const d = timestamp instanceof Date ? timestamp : new Date(timestamp);
  return formatDate(d, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

const Activity: React.FC<Props> = ({ activity }) => {
  return (
    <div className="Activity" data-testid="Activity">
      <span className="Activity__Header">
        <span className="Activity__Time">{formatTime(activity.timestamp)}</span>
        {': '}
        <span className="Activity__Action">{activity.action}</span>
        {' by '}
        <span className="Activity__PerformedBy">
          {activity.performedBy.email}
        </span>
      </span>
    </div>
  );
};

export default Activity;
