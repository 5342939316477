import classNames from 'classnames';
import React from 'react';
import './styles.scss';

export interface ButtonPanelProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  align?: string;
}

const ButtonPanel: React.FC<ButtonPanelProps> = ({
  children,
  className = '',
  align = 'left',
}: ButtonPanelProps) => {
  const cls = classNames(`ButtonPanel ButtonPanel--${align}`, className);
  return <div className={cls}>{children}</div>;
};

export default ButtonPanel;
