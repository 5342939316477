import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext, IAppContext } from 'context';
import { getTicketPackages } from 'api';
import { WithBlankSlate } from 'components/BlankSlate';
import { showErrorMsg } from 'components/core/AlertMessages';
import Page from 'components/core/Page';
import PageLoadingIndicator from 'components/core/PageLoadingIndicator';
import TicketPackages from 'components/TicketPackages';
import { TicketPackage } from 'models/TicketPackage';
import emptyStatePath from 'images/uploader-empty-state.png';
import './TicketPurchasePage.scss';

const TicketPackagesPage: React.FC = () => {
  const [ticketPackages, setTicketPackages] = useState<TicketPackage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { currentEvent } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    loadTicketPackages();
  }, [currentEvent]);

  const loadTicketPackages = () => {
    if (!currentEvent) return;

    const success = (packages) => {
      setTicketPackages(packages);
      setLoading(false);
    };

    const failure = () => {
      showErrorMsg(
        'Failed to load ticket packages. Please, try again or reload the page',
      );
    };

    getTicketPackages(currentEvent.locator, success, failure);
  };

  if (!currentEvent) return null;
  if (loading) return <PageLoadingIndicator />;

  const blankSlateExtraContent = (
    <Link
      to={`/event/${currentEvent?.locator}/ticketPackages/new`}
      className="Button Button--primary"
    >
      Create a ticket package
    </Link>
  );

  return (
    <Page>
      <h1 className="Page__Title">Ticket packages</h1>
      <p>Packages and details can be edited at any time.</p>

      <WithBlankSlate
        title="You haven't created any ticket packages yet."
        extraContent={blankSlateExtraContent}
        collection={ticketPackages}
        imagePath={emptyStatePath}
      >
        <TicketPackages ticketPackages={ticketPackages} />
      </WithBlankSlate>
    </Page>
  );
};

export default TicketPackagesPage;
