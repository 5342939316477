import { signUp } from 'api';
import Button from 'components/core/Button';
import Form from 'components/core/Form';
import Input from 'components/core/Input';
import { AppContext, IAppContext } from 'context';
import { User } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { hasRequiredProperties } from 'utils';
import Copyright from './Copyright';
import FooteLinks from './FooterLinks';

interface UserEx extends User {
  password?: string;
}

const SignupForm: React.FC = () => {
  const [newUser, setNewUser] = useState<Partial<UserEx>>();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();

  const { setUser } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    setEnabled(
      hasRequiredProperties<UserEx>(newUser, [
        'email',
        'password',
        'firstName',
        'lastName',
      ]),
    );
  }, [newUser]);

  const updateUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    setNewUser({ ...newUser, [key]: value });
  };

  const handleSignup = (e) => {
    e.preventDefault();

    const success = (user) => setUser(user);

    const failure = (errors) => {
      setErrors(errors.errors);
    };

    signUp(
      newUser?.email,
      newUser?.password,
      newUser?.firstName,
      newUser?.lastName,
      success,
      failure,
    );
  };

  return (
    <div className="SignupForm FullPageForm">
      <div className="FullPageForm__Hero">
        <h1>
          You’re minutes away
          <br />
          from the world’s best{' '}
          <span style={{ whiteSpace: 'nowrap' }}>Meet &amp; Greet</span>{' '}
          galleries!
        </h1>
      </div>
      <h1 className="FullPageForm__Title">Create an account</h1>
      <p className="FullPageForm__Description">
        Already have a StagePilot VIP account?{' '}
        <Link to="/login">Log in now.</Link>
      </p>
      <Form onSubmit={handleSignup} errors={errors} className="Form--fullWidth">
        <Input
          caption="Your first name"
          type="text"
          name="firstName"
          value={newUser?.firstName}
          onChange={updateUser}
          fullWidth
        />
        <Input
          caption="Your last name"
          type="text"
          name="lastName"
          value={newUser?.lastName}
          onChange={updateUser}
          fullWidth
        />
        <Input
          caption="Your email address"
          type="text"
          name="email"
          value={newUser?.email}
          onChange={updateUser}
          fullWidth
        />
        <Input
          caption="Choose a password"
          type="password"
          name="password"
          value={newUser?.password}
          onChange={updateUser}
          fullWidth
        />
        <Button primary type="submit" caption="Sign up" enabled={enabled} />
      </Form>
      <footer className="FullPageForm__Footer">
        <FooteLinks />
        <Copyright />
      </footer>
    </div>
  );
};

export default SignupForm;
