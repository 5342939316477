import classNames from 'classnames';
import React from 'react';

interface PageProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  dark?: boolean;
}

const Page: React.FC<PageProps> = ({ className, dark = false, children }) => (
  <div className={classNames(className, 'Page', { 'Page--dark': dark })}>
    {children}
  </div>
);

export default Page;
