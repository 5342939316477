import Page from 'components/core/Page';
import TicketPackageForm from 'components/TicketPackageForm';
import { TicketPackage } from 'models/TicketPackage';
import React from 'react';

const NewTicketPackagePage: React.FC = () => {
  const newTicketPkg: TicketPackage = {
    id: 0,
    name: 'New ticket package',
    description: '',
    color: '#FF0000',
    wristbandsEnabled: false,
    merchandiseEnabled: false,
    extraFieldsEnabled: false,
    merchandiseItems: [],
    extraFields: [],
  };

  return (
    <Page>
      <h1 className="Page__Title">New ticket package</h1>
      <TicketPackageForm ticketPackage={newTicketPkg} />
    </Page>
  );
};

export default NewTicketPackagePage;
