import React, { ReactNode } from 'react';
import rollbar from 'errorReporting';

interface ApplicationErrorState {
  hasError: boolean;
}

class ApplicationError extends React.Component<
  { children: ReactNode },
  ApplicationErrorState
> {
  constructor(props: { children }) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: any) {
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    rollbar.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ApplicationError">
          <div className="ApplicationError__Message">
            <h1>That&rsquo;s not right&hellip;</h1>
            <p>We seem to have encountered an error.</p>
            <p>
              If refreshing the page doesn&rsquo;t help, please shoot us a note
              at{' '}
              <a href="mailto:support@stagepilot.com">support@stagepilot.com</a>
              .
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ApplicationError;
