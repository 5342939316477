import { logIn } from 'api';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import ErrorBar from 'components/core/ErrorBar';
import Input from 'components/core/Input';
import { AppContext, IAppContext } from 'context';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Copyright from './Copyright';
import { clearToken, setToken } from 'localStorage';

const LoginForm: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loginEnabled, setLoginEnabled] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { setUser } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    updateButtonStatus();
  }, [email, password]);

  const updateButtonStatus = () => {
    setLoginEnabled(email !== '' && password !== '');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setSubmitting(true);

    const success = (loginResponse: any) => {
      setToken(loginResponse.token);
      setUser(loginResponse.user);
      setSubmitting(false);
    };

    const failure = (json) => {
      setError(json.reason || 'Something went wrong');
      clearToken();
      setSubmitting(false);
    };

    logIn(email, password, success, failure);
  };

  return (
    <form
      className="LoginForm FullPageForm"
      onSubmit={handleLogin}
      data-testid="LoginForm"
    >
      <div className="FullPageForm__Hero">
        <h1>Welcome back!</h1>
      </div>
      <h1 className="FullPageForm__Title">Log in</h1>
      <p className="FullPageForm__Description">
        Don't have a StagePilot VIP account?
        <Link to="/signup"> Sign up now.</Link>
      </p>
      <ErrorBar error={error} />
      <Input
        data-testid="login_email"
        type="text"
        name="email"
        caption="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoFocus
        fullWidth
      />
      <Input
        data-testid="login_password"
        type="password"
        name="password"
        caption="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
      />
      <div className="LoginForm__Buttons">
        <ButtonPanel>
          <Button
            testid="LoginButton"
            primary
            caption="Log in"
            type="submit"
            busyMessage={submitting ? 'Logging in...' : undefined}
            enabled={loginEnabled}
          />
        </ButtonPanel>
      </div>
      <footer className="FullPageForm__Footer">
        <p>
          Forgot your password?{' '}
          <Link to="/reset_password/new">Send me reset instructions</Link>
        </p>
        <span className="FullPageForm__Footer--right">
          <a href="https://stagepilot.com/legal/privacy" target="_blank">
            Privacy Policy
          </a>
          {' | '}
          <a
            href="https://www.stagepilot.com/legal/terms-of-use"
            target="_blank"
          >
            Terms and Conditions
          </a>
        </span>
        <Copyright />
      </footer>
    </form>
  );
};

export default LoginForm;
