import { Tab, Tabs } from 'components/core/Tabs';
import { AppContext, IAppContext } from 'context';
import React, { useContext } from 'react';
import { useParams } from 'react-router';

const EventSettingsHeader = () => {
  const { user } = useContext<IAppContext>(AppContext);
  const { locator } = useParams();

  return (
    <>
      <h1 className="Page__Title">Event settings</h1>
      <div className="Page__Header">Update settings at any time.</div>

      <Tabs>
        View:
        <Tab path={`/event/${locator}/settings`}>Gallery Settings</Tab>
        {user?.superUser && (
          <Tab path={`/event/${locator}/settings/photobooth`}>Photo Booth</Tab>
        )}
        <Tab path={`/event/${locator}/settings/billing`}>Payment info</Tab>
      </Tabs>
    </>
  );
};

export default EventSettingsHeader;
