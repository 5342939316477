import React from 'react';
import loadingLogo from 'images/icons/loading.svg';
import './styles.css';

const PageLoadingIndicator = () => (
  <div className="PageLoadingIndicator" data-testid="loading-indicator">
    <img src={loadingLogo} />
  </div>
);

export default PageLoadingIndicator;
