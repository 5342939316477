import Page from 'components/core/Page';
import UserSettings from 'components/UserSettings';
import React from 'react';

const SettingsPage: React.FC = () => {
  return (
    <Page>
      <div className="SettingsPage">
        <h1 className="Page__Title">Settings</h1>
        <UserSettings />
      </div>
    </Page>
  );
};

export default SettingsPage;
