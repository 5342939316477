import { uploadTicketPurchases } from 'api';
import { showErrorMsg } from 'components/core/AlertMessages';
import FilePicker from 'components/core/FilePicker';
import iconv from 'iconv-lite';
import jschardet from 'jschardet';
import React from 'react';

const arrayOfMacRomanBytes = [-114 - 118, -110, -106, -121, -97, -102];

const decodeText = (result) => {
  const buffer = Buffer.from(result as string);
  const arrayofInts = new Int8Array(buffer);
  const found = arrayofInts.some((r) => arrayOfMacRomanBytes.indexOf(r) >= 0);
  if (found) {
    return iconv.decode(buffer, 'macroman');
  } else {
    return iconv.decode(buffer, jschardet.detect(buffer).encoding);
  }
};

const UploadTicketsCSVButton = ({ locator, scheduleItemId, onUpload }) => {
  const uploadCSV = async (selectedFiles) => {
    if (selectedFiles.length < 1) return;
    const csvFile = selectedFiles[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(csvFile);
    reader.onload = async () => {
      const convertedText = decodeText(reader.result);
      const response = await uploadTicketPurchases(
        locator,
        scheduleItemId,
        convertedText,
      );

      if (response.success()) {
        onUpload(response.json);
      } else {
        showErrorMsg('There was an error uploading your CSV', 5000);
      }
    };
  };

  return <FilePicker caption="Upload CSV" button={true} onPick={uploadCSV} />;
};

export default UploadTicketsCSVButton;
