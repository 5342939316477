import { useEffect } from 'react';

const DragDropIgnorer = () => {
  const ignore = (e) => e.preventDefault();
  useEffect(() => {
    window.addEventListener('dragover', ignore);
    window.addEventListener('drop', ignore);
    return () => {
      window.removeEventListener('drop', ignore);
      window.removeEventListener('dragover', ignore);
    };
  }, []);

  return null;
};

export default DragDropIgnorer;
