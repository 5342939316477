import classNames from 'classnames';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import FullModal from 'components/core/FullModal';
import TabSwitch from 'components/core/TabSwitch';
import SortableTable from 'components/SortableTable';
import HeaderMappings from 'modals/ImportTicketPurchasesModal/HeaderMappings';
import NewPackagesWarning from 'modals/ImportTicketPurchasesModal/NewPackagesWarning';
import ImportPreviewResponse, {
  CSVRowUpdate,
} from 'models/ImportPreviewResponse';
import TicketPurchase from 'models/TicketPurchase';
import React, { useState } from 'react';
import './styles.scss';

interface ImportPreviewRowProps {
  row: CSVRowUpdate;
}

const ImportPreviewRow: React.FC<ImportPreviewRowProps> = ({ row }) => {
  const cls = classNames('ImportPreviewRow', {
    'ImportPreviewRow--added': row.added,
    'ImportPreviewRow--deleted': row.deleted,
    'ImportPreviewRow--changed': row.changed,
  });
  const cell = (col: string) => {
    const purchase = (row.ticketPurchase ||
      row.previousTicketPurchase) as TicketPurchase;
    const value = purchase[col];
    const hasChanged = row.changes && row.changes.indexOf(col) >= 0;
    const cls = hasChanged ? 'ImportPreviewItem--changed' : undefined;

    return <td className={cls}>{value}</td>;
  };

  return (
    <tr className={cls}>
      {cell('firstName')}
      {cell('lastName')}
      {cell('email')}
      {cell('package')}
      {cell('quantity')}
      {cell('originalOrderNumber')}
      {cell('phone')}
    </tr>
  );
};

interface ImportTicketPurchasesModalProps {
  csvImport: ImportPreviewResponse;
  onUpdate: (updated: ImportPreviewResponse) => void;
  onImport: () => void;
  onCancel: () => void;
}

const filterPurchases = (imp: ImportPreviewResponse, filter: string) => {
  switch (filter) {
    case 'all':
      return imp.updates;
    case 'new':
      return imp.updates.filter((u) => u.added);
    case 'updated':
      return imp.updates.filter((u) => u.changed);
    default:
      return imp.updates.filter((u) => u.deleted);
  }
};

const ImportTicketPurchasesModal: React.FC<ImportTicketPurchasesModalProps> = ({
  csvImport,
  onUpdate,
  onImport,
  onCancel,
}) => {
  const [filter, setFilter] = useState('all');
  const [headerMappingsSeen, setHeaderMappingsSeen] = useState(false);
  const [newPackagesWarningSeen, setNewPackagesWarningSeen] = useState(false);

  if (!headerMappingsSeen) {
    return (
      <HeaderMappings
        importPreview={csvImport}
        onCancel={onCancel}
        onUpdate={onUpdate}
        onConfirm={() => setHeaderMappingsSeen(true)}
      />
    );
  }

  const added = csvImport.updates.filter((u) => u.added);
  const changed = csvImport.updates.filter((u) => u.changed);
  const deleted = csvImport.updates.filter((u) => u.deleted);
  const unchanged = csvImport.updates.filter(
    (u) => !u.changed && !u.added && !u.deleted,
  );

  const tabs = [
    { key: 'all', caption: 'All' },
    { key: 'new', caption: `New (${added.length})` },
    { key: 'updated', caption: `Updated (${changed.length})` },
    { key: 'deleted', caption: `Deleted (${deleted.length})` },
  ];

  const purchases = filterPurchases(csvImport, filter);
  const showNewPackagesWarning =
    csvImport.missingPackages.length > 0 && !newPackagesWarningSeen;
  const isFirstImport =
    changed.length === 0 &&
    deleted.length === 0 &&
    unchanged.length === 0 &&
    added.length > 0;

  return (
    <FullModal open fillWidth>
      <h1>CSV Import Preview</h1>
      <p>
        Individual records cannot be edited in StagePilot. If you see an error,
        please correct and re-upload your CSV.
      </p>

      {!isFirstImport ? (
        <TabSwitch items={tabs} selectedItem={filter} onClick={setFilter} />
      ) : (
        <></>
      )}

      <SortableTable<CSVRowUpdate>
        headers={[
          'First',
          'Last',
          'Email',
          'Ticket Package',
          'Quantity',
          'Order',
          'Phone',
        ]}
        keys={[
          'firstName',
          'lastName',
          'email',
          'package',
          'quantity',
          'originalOrderNumber',
          'phone',
        ]}
        headerClasses={{ Tickets: 'right' }}
        items={purchases}
        className="TicketPurchases"
        mapper={(row: CSVRowUpdate) => (
          <ImportPreviewRow
            key={
              row.ticketPurchase?.originalOrderNumber ||
              row.previousTicketPurchase?.originalOrderNumber
            }
            row={row}
          />
        )}
      />
      <ButtonPanel className="ImportTicketPurchasesModal__ButtonPanel">
        <Button tertiary caption="Cancel" onClick={onCancel} />
        <Button primary caption="Import" onClick={onImport} />
      </ButtonPanel>

      <NewPackagesWarning
        packageNames={csvImport.missingPackages}
        open={showNewPackagesWarning}
        onCancel={onCancel}
        onConfirm={() => setNewPackagesWarningSeen(true)}
      />
    </FullModal>
  );
};

export default ImportTicketPurchasesModal;
