import Rollbar from 'rollbar';

const rollbar = (window.rollbar || {}).client
  ? window.rollbar
  : new Rollbar({
      accessToken: process.env.ROLLBAR,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: process.env.NODE_ENV === 'production',
      environment: process.env.STACK_NAME,
      client: { javascript: { code_version: process.env.GIT_VERSION } },
    });

window.rollbar = rollbar;

export default rollbar;
