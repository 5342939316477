import classNames from 'classnames';
import React from 'react';
import './style.scss';

type WithChildren = { children?: JSX.Element | JSX.Element[] | null };

export const HeaderArea: React.FC<WithChildren> = ({ children }) => (
  <header className="HeaderArea">{children}</header>
);

export const NotificationsArea: React.FC<WithChildren> = ({ children }) => (
  <div className="NotificationsArea">{children}</div>
);

export const SidebarArea: React.FC<WithChildren> = ({ children }) => (
  <nav className="SidebarArea">{children}</nav>
);
export const ContentArea: React.FC<WithChildren> = ({ children }) => (
  <main className="ContentArea">{children}</main>
);
export const FillArea: React.FC<WithChildren> = ({ children }) => (
  <div className="FillArea">{children}</div>
);

interface LayoutProps {
  loggedIn?: boolean;
  gradient?: boolean;
  children?: JSX.Element | JSX.Element[] | null;
}
const Layout: React.FC<LayoutProps> = ({
  loggedIn = false,
  gradient,
  children,
}: LayoutProps) => (
  <div
    data-testid={loggedIn ? 'LoggedInLayout' : 'LoggedOutLayout'}
    className={classNames('Layout', {
      'Layout--gradient': gradient,
      'Layout--logged-in': loggedIn,
    })}
  >
    {children}
  </div>
);

export default Layout;
