import SortableTable from 'components/SortableTable';
import Fan from 'models/Fan';
import React from 'react';

export const FansRow = ({ item }) => {
  const purchases = item.total;
  const amount = purchases.total > 0 ? purchases.formatted : '-';

  return (
    <tr className="FansList__FansRow">
      <td>{item.firstName}</td>
      <td>{item.lastName}</td>
      <td>{item.email}</td>
      <td>{item.location}</td>
      <td className="right">{amount}</td>
    </tr>
  );
};

const FansList = ({ fansList }) => (
  <SortableTable<Fan>
    headers={['First', 'Last', 'Email', 'Location', 'Total Purchases']}
    keys={['firstName', 'lastName', 'email', 'location', 'total']}
    headerClasses={{ 'Total Purchases': 'right' }}
    items={fansList}
    className="FansList"
    mapper={(row) => <FansRow item={row} key={row.email} />}
  />
);

export default FansList;
