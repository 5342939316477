import SortableTable from 'components/SortableTable';
import { ValidUser } from 'models/User';
import React from 'react';
import { RemovePendingInviteButton } from './RemovePendingInviteButton';
import RemoveTeamMemberButton from './RemoveTeamMemberButton';
import './styles.scss';

export const RoleType = {
  o: 'Owner',
  a: 'Admin',
  p: 'Photographer',
};

interface TeamListProps {
  teamList: ValidUser[];
  removeTeamMemberHandler: (member: ValidUser) => void;
  removePendingInviteHandler: (member: ValidUser) => void;
}
export const TeamList: React.FC<TeamListProps> = ({
  teamList,
  removeTeamMemberHandler,
  removePendingInviteHandler,
}: TeamListProps) => (
  <SortableTable<ValidUser>
    headers={['First', 'Last', 'Email', 'Role', '']}
    keys={['firstName', 'lastName', 'email', 'roleType', '']}
    items={teamList}
    className="TeamList"
    mapper={(s) => (
      <TeamRow
        item={s}
        key={s.id}
        removeTeamMemberHandler={removeTeamMemberHandler}
        removePendingInviteHandler={removePendingInviteHandler}
      />
    )}
  />
);

interface TeamRowProps {
  item: ValidUser;
  removeTeamMemberHandler: (member: ValidUser) => void;
  removePendingInviteHandler: (member: ValidUser) => void;
}
const TeamRow: React.FC<TeamRowProps> = ({
  item,
  removeTeamMemberHandler,
  removePendingInviteHandler,
}: TeamRowProps) => (
  <tr className="TeamList__TeamRow">
    <td>{item.firstName}</td>
    <td>{item.lastName}</td>
    <td>{item.email}</td>
    <td>{RoleType[item.roleType]}</td>
    <td className="right">
      <div className="TeamList__TeamRowActions">
        {item.pending ? (
          <RemovePendingInviteButton
            invite={item}
            removePendingInviteHandler={removePendingInviteHandler}
          />
        ) : (
          <RemoveTeamMemberButton
            member={item}
            removeTeamMemberHandler={removeTeamMemberHandler}
          />
        )}
      </div>
    </td>
  </tr>
);
