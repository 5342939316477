import { getScheduleItems } from 'api';
import { addOrUpdateById } from 'api/util';
import Page from 'components/core/Page';
import UploaderOverview from 'components/UploaderOverview';
import UploaderScheduleItemView from 'components/UploaderScheduleItemView';
import { IUploaderContext, UploaderContext } from 'context';
import { ScheduleItem } from 'models/ScheduleItem';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

const UploaderPage: React.FC = () => {
  const [schedule, setSchedule] = useState<ScheduleItem[]>();
  const { locator } = useParams();
  const { onItemComplete } = useContext<IUploaderContext>(UploaderContext);

  useEffect(() => {
    const itemCompleted = ({ scheduleItem }) => {
      setSchedule((prevSchedule) =>
        addOrUpdateById(prevSchedule, scheduleItem),
      );
    };

    const loadAndSubscribe = () => {
      if (locator) {
        getScheduleItems(locator, (scheduleItems: ScheduleItem[]) => {
          setSchedule(scheduleItems);
          onItemComplete.subscribe(itemCompleted);
        });
      }
    };

    loadAndSubscribe();
    return () => {
      onItemComplete.unsubscribe(itemCompleted);
    };
  }, [locator]);

  return (
    <Page>
      <Routes>
        <Route
          path="/:scheduleItemId"
          element={<UploaderScheduleItemView schedule={schedule} />}
        />
        <Route path="*" element={<UploaderOverview schedule={schedule} />} />
      </Routes>
    </Page>
  );
};

export default UploaderPage;
