import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

interface NavigationSectionProps {
  title?: string;
  red?: boolean;
  orange?: boolean;
  teal?: boolean;
  children?: JSX.Element | JSX.Element[] | null;
}
export const NavigationSection: React.FC<NavigationSectionProps> = ({
  title,
  red,
  orange,
  teal,
  children,
}: NavigationSectionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    let shouldHighlight: boolean = false;
    if (ref.current) {
      shouldHighlight =
        ref.current?.getElementsByClassName('NavigationItem--active').length >
        0;
    }
    setHighlighted(shouldHighlight);
  });

  const cls = classNames('NavigationSection', {
    'NavigationSection--highlighted': highlighted,
    'NavigationSection--teal': teal,
    'NavigationSection--red': red,
    'NavigationSection--orange': orange,
  });
  return (
    <div className={cls} ref={ref}>
      <div className="NavigationSection__Title">{title}</div>
      {children}
    </div>
  );
};

export const NavigationItem = ({
  children,
  disabled = false,
  topLevel = false,
  to,
  ...props
}) => {
  const cls = classNames('NavigationItem', {
    'NavigationItem--topLevel': topLevel,
  });
  return (
    <>
      {disabled && (
        <div className="NavigationItem NavigationItem--disabled">
          {children}
        </div>
      )}
      {!disabled && (
        <NavLink
          {...props}
          className={cls}
          to={to}
          // activeClassName="NavigationItem--active"
        >
          {children}
        </NavLink>
      )}
    </>
  );
};
