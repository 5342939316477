export const persistUser = (user) => {
  const auth = JSON.stringify({
    user,
  });
  localStorage.setItem('USER', auth);
};

export const getToken = () => localStorage.getItem('TOKEN');

export const setToken = (token) => localStorage.setItem('TOKEN', token);

export const clearToken = () => localStorage.removeItem('TOKEN');

export const clearPersistedUser = () => {
  localStorage.removeItem('USER');
};

export const loadPersistedUser = () => {
  try {
    const json = localStorage.getItem('USER') || '{}';
    return JSON.parse(json);
  } catch {
    return {};
  }
};

export const setLoginDestination = (url) => {
  localStorage.setItem('LOGIN_TO', url);
};

export const popLoginDestination = () => {
  const url = localStorage.getItem('LOGIN_TO');
  localStorage.removeItem('LOGIN_TO');
  return url;
};
