import { getEventStats, getScheduleItems } from 'api';
import { formatDate, humanizeTimestamp } from 'api/util';
import { WithBlankSlate } from 'components/BlankSlate';
import CheckInStats, { EventCheckInStats } from 'components/CheckInStats';
import { showErrorMsg } from 'components/core/AlertMessages';
import IconButton from 'components/core/IconButton';
import Page from 'components/core/Page';
import PageLoadingIndicator from 'components/core/PageLoadingIndicator';
import SortableTable from 'components/SortableTable';
import { AppContext, IAppContext } from 'context';
import emptyStatePath from 'images/uploader-empty-state.png';
import { Event } from 'models';
import { ScheduleItem } from 'models/ScheduleItem';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { triggerDownload } from 'utils';

type ScheduleItemEx = ScheduleItem & { lastPostAt: Date };

const TicketsSchedulePage: React.FC = () => {
  const [schedule, setSchedule] = useState<ScheduleItemEx[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [eventCheckInStats, setEventCheckInStats] = useState<
    EventCheckInStats[]
  >([]);

  const { currentEvent } = useContext<IAppContext>(AppContext);

  useEffect(() => {
    if (currentEvent) {
      loadScheduleItems();
      loadStats();
    }
  }, [currentEvent]);

  const loadStats = async () => {
    getEventStats(
      currentEvent?.locator ?? '',
      undefined,
      (stats: EventCheckInStats[]) => {
        setEventCheckInStats(stats);
      },
    );
  };

  const loadScheduleItems = () => {
    const success = (scheduleItems: ScheduleItemEx[]) => {
      setSchedule(scheduleItems);
      setLoading(false);
    };
    const failure = () => {
      showErrorMsg(
        'Failed to load schedule items. Please, try again or reload the page',
      );
    };

    getScheduleItems(currentEvent?.locator ?? '', success, failure);
  };

  const downloadCSV = (e: React.MouseEvent) => {
    e.preventDefault();
    triggerDownload(
      `${process.env.API_HOST}/events/${currentEvent?.locator}/ticketPurchases.csv`,
    );
  };

  if (!currentEvent) return null;
  if (loading) return <PageLoadingIndicator />;

  const blankSlateExtraContent = (
    <Link
      to={`/event/${currentEvent.locator}/schedule`}
      className="Button Button--primary"
    >
      Add schedule items
    </Link>
  );

  return (
    <Page>
      <h1 className="Page__Title">Ticket data</h1>

      <WithBlankSlate
        title="Please add at least one schedule item."
        message="Once you add schedule items, corresponding upload folders will appear here."
        extraContent={blankSlateExtraContent}
        collection={schedule}
        imagePath={emptyStatePath}
      >
        <CheckInStats staticCheckInStats={eventCheckInStats} />

        <div className="Page__Header">
          <IconButton
            icon="download"
            caption="Download CSV"
            onClick={downloadCSV}
            href="#"
          />
        </div>

        <div className="Schedule__List Table">
          <SortableTable<ScheduleItemEx>
            headers={['Date', 'Location', 'Last Modified', 'Orders']}
            keys={['startsAt', 'location', 'lastPostAt', 'ticketsCount']}
            headerClasses={{ Tickets: 'right' }}
            items={schedule}
            className="TicketPurchases"
            mapper={(item) => (
              <ScheduleItemRow key={item.id} item={item} event={currentEvent} />
            )}
          />
        </div>
      </WithBlankSlate>
    </Page>
  );
};

interface RowProps {
  event: Event;
  item: ScheduleItem & { lastPostAt: Date };
}

const ScheduleItemRow: React.FunctionComponent<RowProps> = ({
  event,
  item,
}) => {
  const navigate = useNavigate();
  const visitScheduleItem = () => {
    const location = `/event/${event.locator}/ticketPurchases/${item.id}`;
    navigate(location);
  };

  return (
    <tr className="TicketsList__TicketsRow" onClick={visitScheduleItem}>
      <td>{formatDate(item.startsAt)}</td>
      <td>{item.location}</td>
      <td>{humanizeTimestamp(item.lastPostAt)}</td>
      <td className="right">{item.ticketsCount || 0}</td>
    </tr>
  );
};

export default TicketsSchedulePage;
