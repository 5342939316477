import Page from 'components/core/Page';
import {
  DownloadsChart,
  FansChart,
  ProfitChart,
  SalesChart,
} from 'components/DashboardCharts';
import DashboardDownloadsTable from 'components/DashboardDownloadsTable';
import {
  DownloadsByLocation,
  ProductsByProfitReport,
  ProductsByVolumeReport,
  SalesByLocation,
} from 'components/DashboardReports';
import DashboardSalesTable from 'components/DashboardSalesTable';
import DateRangePicker from 'components/DateRangePicker';
import { DashboardQueryProps } from 'models';
import React, { useState } from 'react';
import { useParams } from 'react-router';

const DashboardPage: React.FC = () => {
  const [state, setState] = useState<DashboardQueryProps>({ query: '' });
  const { locator } = useParams();

  const dateRangeChanged = (updates: any) => {
    setState({ ...state, ...updates });
  };

  return (
    <Page dark>
      <div className="DashboardPage">
        <DateRangePicker onChange={dateRangeChanged} />
        <h1 className="Page__Title">Dashboard</h1>
        <div className="Page__Header" />
        {locator && (
          <div className="DashboardPage__Charts">
            <FansChart locator={locator} {...state} />
            <DownloadsChart locator={locator} {...state} />
            <ProfitChart locator={locator} {...state} />
            <ProductsByVolumeReport locator={locator} {...state} />
            <ProductsByProfitReport locator={locator} {...state} />
            <SalesChart locator={locator} {...state} />
            <DashboardSalesTable locator={locator} {...state} />
            <SalesByLocation locator={locator} {...state} />
            <DashboardDownloadsTable locator={locator} {...state} />
            <DownloadsByLocation locator={locator} {...state} />
          </div>
        )}
      </div>
    </Page>
  );
};

export default DashboardPage;
