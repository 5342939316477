import classNames from 'classnames';
import React from 'react';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  autoComplete?: any;
  autoFocus?: boolean;
  caption?: string;
  fullWidth?: boolean;
  hiddenCaption?: boolean;
  mediumWidth?: boolean;
  optional?: boolean;
  suffix?: any;
  tall?: boolean;
  testid?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({
  className = '',
  testid,
  type = 'text',
  name,
  caption = name,
  value,
  onChange,
  optional = false,
  autoFocus = false,
  prefix,
  suffix,
  tall = false,
  fullWidth = false,
  mediumWidth = false,
  hiddenCaption = false,
  disabled = false,
  autoComplete,
  placeholder,
  children,
  readOnly,
}: InputProps) => {
  const classnames = classNames('Input', className, {
    'Input--optional': optional,
    'Input--tall': tall,
    'Input--fullwidth': fullWidth,
    'Input--prefix': prefix,
    'Input--suffix': suffix,
  });

  return (
    <div className={classnames}>
      {!hiddenCaption && (
        <label
          htmlFor={name}
          className={classNames('Input__Caption', {
            'Input__Caption--fullWidth': fullWidth,
          })}
        >
          {caption}
        </label>
      )}
      {prefix && <div className="Input__Control Input__Prefix">{prefix}</div>}
      <input
        data-testid={testid}
        className={classNames('Input__Control', {
          'Input__Control--fullWidth': fullWidth,
          'Input__Control--mediumWidth': mediumWidth,
          'Input__Control--disabled': disabled,
        })}
        type={type}
        id={name}
        name={name}
        value={value || ''}
        onChange={onChange}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        disabled={disabled}
        placeholder={placeholder || (optional ? 'optional' : undefined)}
        readOnly={readOnly}
      />
      {suffix && (
        <div className="Input__Suffix">
          <span className="Input__Suffix--value">{value}</span>
          {suffix}
        </div>
      )}

      {children}
    </div>
  );
};

export default Input;
