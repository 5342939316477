import { getAdminAlerts, readAdminAlert } from 'api';
import classNames from 'classnames';
import { showErrorMsg } from 'components/core/AlertMessages';
import IconButton from 'components/core/IconButton';
import RecyclerView from 'components/core/RecyclerView';
import { formatDistanceToNow } from 'date-fns';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import './styles.scss';

interface Alert {
  id: number;
  message: string;
  readAt: Date;
  createdAt: Date;
}

interface Props {
  alert: Alert;
  markRead: Function;
}

const AdminAlert: React.FC<Props> = ({ alert, markRead }) => {
  useEffect(() => {
    let cancelled = false;
    if (!alert.readAt) {
      setTimeout(() => {
        if (cancelled || alert.readAt) {
          return;
        }

        markRead(alert);
      }, 1500);
    }

    return () => {
      cancelled = true;
    };
  }, [alert.readAt]);

  return (
    <div
      className={classNames('AdminAlert', { 'AdminAlert--read': alert.readAt })}
    >
      <Markdown>{alert.message}</Markdown>
      <p className="AdminAlert__Timestamp">
        {formatDistanceToNow(alert.createdAt, { addSuffix: true })}
      </p>
    </div>
  );
};

const AdminAlerts: React.FC = () => {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let cancelled = false;
    async function loadAlertList() {
      const success = (list: Alert[]) => {
        if (!cancelled) {
          setAlerts(
            list.map((alert) => ({
              ...alert,
              createdAt: new Date(alert.createdAt),
            })),
          );
        }
      };

      const error = (e) => showErrorMsg(e.message);

      getAdminAlerts(success, error);
    }
    loadAlertList();
    return () => {
      cancelled = true;
    };
  }, [visible]);

  const markRead = (alert) =>
    readAdminAlert(alert.id, (updated) =>
      setAlerts((alerts) =>
        alerts.map((a: Alert) => {
          if (a.id === updated.id) {
            return { ...updated, createdAt: new Date(updated.createdAt) };
          }

          return a;
        }),
      ),
    );

  const unread = alerts.some((a) => !a.readAt);

  return (
    <div
      className={classNames('AdminAlerts', { 'AdminAlerts--open': visible })}
    >
      <IconButton
        icon={unread ? 'bell-on' : 'bell'}
        caption="Alerts"
        onClick={() => setVisible(!visible)}
      />
      {visible &&
        (alerts.length ? (
          <RecyclerView className="AdminAlerts__List" itemHeight={39}>
            {alerts.map((a) => (
              <AdminAlert key={a.id} alert={a} markRead={markRead} />
            ))}
          </RecyclerView>
        ) : (
          <div className="AdminAlerts__List">
            <div className="AdminAlerts__Empty">No alerts</div>
          </div>
        ))}
    </div>
  );
};

export default AdminAlerts;
