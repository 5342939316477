import { createNote } from 'api';
import { showInfoMsg } from 'components/core/AlertMessages';
import Button from 'components/core/Button';
import ButtonPanel from 'components/core/ButtonPanel';
import Form from 'components/core/Form';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './styles.scss';
import { TicketNote } from 'models';

interface TicketNoteFormProps {
  noteAdded: () => void;
}

const TicketNoteForm: React.FC<TicketNoteFormProps> = ({
  noteAdded,
}: TicketNoteFormProps) => {
  const [content, setContent] = useState<string>('');
  const [errors, setErrors] = useState<object>({});

  const { locator, scheduleItemId, ticketPurchaseId } = useParams();

  const updateContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  const save = (e: React.MouseEvent<HTMLButtonElement>) => {
    const ticketNote: TicketNote = {
      ticketPurchaseId: parseInt(ticketPurchaseId as string),
      content,
    };

    const success = () => {
      showInfoMsg('Note saved');
      setContent('');
      setErrors({});

      noteAdded();
    };

    const failure = ({ errors }) => {
      setErrors(errors);
    };

    createNote(locator, scheduleItemId, ticketNote, success, failure);
  };

  return (
    <Form className="TicketNoteForm" errors={errors}>
      <div className="FieldSet">
        <div className="FieldSet">
          <div className="Input">
            <textarea
              className="Input__Control"
              id="content"
              name="content"
              placeholder="Add a new note..."
              rows={5}
              value={content}
              onChange={updateContent}
            />
          </div>
        </div>
      </div>

      <hr className="Separator" />

      <ButtonPanel align="right">
        <Button
          caption="Add note"
          enabled={content !== ''}
          onClick={save}
          primary
        />
      </ButtonPanel>
    </Form>
  );
};

export default TicketNoteForm;
