import Page from 'components/core/Page';
import { AppContext, IAppContext } from 'context';
import { atLeast, roleComparison } from 'context/roles';
import { popLoginDestination } from 'localStorage';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

const DefaultRoute = () => {
  const { currentEvent, allEvents, firstRun } =
    useContext<IAppContext>(AppContext);

  if (currentEvent) {
    return false;
  }

  if (firstRun) {
    return (
      <Page>
        <h1 className="Page__Title">No events yet.</h1>
        <p>
          You don't have any events yet. If this seems like a mistake, please
          email{' '}
          <a href="mailto:support@stagepilot.com">support@stagepilot.com</a>.
        </p>
      </Page>
    );
  }

  if (!allEvents || allEvents.length === 0) {
    // We don't have events yet
    return (
      <Page>
        <h1 className="Page__Title" data-testid="DefaultLoadingTitle">
          Loading...
        </h1>
      </Page>
    );
  }

  const redirectBlocklist = ['/', '/logout'];
  const url = popLoginDestination();
  if (url && !redirectBlocklist.includes(url)) {
    return <Navigate to={url} />;
  }

  const event = eventWithHighestRole(allEvents);

  if (atLeast('a', event.roleType)) {
    return <Navigate to={`/event/${event.locator}`} />;
  } else {
    return <Navigate to={`/event/${event.locator}/uploader`} />;
  }
};

const eventWithHighestRole = (events) => {
  const eventSortedByRole = events.sort((e1, e2) =>
    roleComparison(e1.roleType, e2.roleType),
  );

  return eventSortedByRole[0];
};

export default DefaultRoute;
