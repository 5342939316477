import ChangePasswordForm from 'components/ChangePasswordForm';
import UserProfile from 'components/UserProfile';
import React from 'react';

const UserSettings: React.FC = () => {
  return (
    <div className="UserSettings">
      <div className="UserSettings__UserProfile">
        <UserProfile />
      </div>

      <hr className="Separator" />

      <div className="UserSettings__ChangePassword">
        <h4>Change password</h4>
        <ChangePasswordForm />
      </div>
    </div>
  );
};

export default UserSettings;
