import React from 'react';

export const addOrUpdateById = (items, item) => {
  items = items.map((i) => (i.id === item.id ? item : i));
  return items.find((i) => i.id === item.id) ? items : items.concat(item);
};

export const removeById = (items, item) => {
  return items.filter((i) => i.id !== item.id);
};

type LocaleType = string | string[] | undefined;
export const formatDate = (dateStr, options?, locale:LocaleType = undefined) => {
  const formatter = new Intl.DateTimeFormat(locale, {
    timeZone: 'UTC',
    ...options,
  });

  return formatter.format(new Date(dateStr));
};

export const formatLocalDate = (dt) => dt && new Date(dt).toLocaleDateString();

export const parseDate = (dt) => new Date(dt);

export const numberFormatter = (value) => value.toLocaleString();

export const moneyFormatter = (value) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value / 100.0);

export const shortDateFormatter = (dt) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return `${months[dt.getMonth()]} ${dt.getDate()}`;
};

export const shortDateEpochFormatter = (e) => shortDateFormatter(new Date(e));

export const describeTimestamp = (dt: Date, now = new Date()) => {
  if (typeof dt === 'string') dt = new Date(dt);

  const d = (now.valueOf() - dt.valueOf()) / (1000 * 60);
  if (d < 1) return 'Less than a minute ago';
  if (d < 5) return 'Less than 5 minutes ago';
  if (d < 30) return 'Less than 30 minutes ago';
  if (d < 80) return 'About an hour ago';

  return formatDate(dt);
};

export const humanizeTimestamp = (dt:Date, now = new Date()) => {
  return <span title={formatDate(dt)}>{describeTimestamp(dt, now)}</span>;
};

export const lowerCaseFirstLetter = (s) => {
  return s.charAt(0).toLowerCase() + s.slice(1);
};
