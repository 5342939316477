import Button from 'components/core/Button';
import Modal, { ModalButtons } from 'components/core/Modal';
import React from 'react';

interface NewPackagesWarningProps {
  open: boolean;
  packageNames: string[];
  onCancel: () => void;
  onConfirm: () => void;
}

const NewPackagesWarning: React.FC<NewPackagesWarningProps> = ({
  open,
  packageNames,
  onCancel,
  onConfirm,
}) => {
  const packageList = packageNames.map((name) => <li key={name}>{name}</li>);
  const packageCount = packageNames.length;
  const packageNoun = packageCount === 1 ? 'package' : 'packages';
  const thisNoun = packageCount === 1 ? 'this' : 'these';

  const createButtonTitle = `Create ${packageCount} ${packageNoun}`;

  return (
    <Modal
      className="NewPackagesWarning"
      open={open}
      title={`New ticket ${packageNoun} found`}
      darkHeader
    >
      <>
        Your CSV includes {packageCount} new ticket {packageNoun}. We can add{' '}
        {thisNoun} to your check in app, or you can cancel the import and update
        your CSV.
        <h3>New {packageNoun}:</h3>
        <ul>{packageList}</ul>
        <ModalButtons>
          <Button caption="Cancel import" onClick={onCancel} />
          <Button primary caption={createButtonTitle} onClick={onConfirm} />
        </ModalButtons>
      </>
    </Modal>
  );
};

export default NewPackagesWarning;
