import React from 'react';

export const ValidationErrors = ({ errors }) => {
  if (!errors || Object.keys(errors).length === 0) return null;

  return (
    <div className="ErrorMessage ErrorMessage--validation">
      {Object.entries(errors).map((error, i) => {
        const field = error[0],
          messages = error[1];

        return ValidationError({
          field: field,
          messages: [messages].flat(),
          key: `error${i}`,
        });
      })}
    </div>
  );
};

export const ValidationError = ({ field, messages, key }) => {
  return (
    <p className="ErrorMessage__Message" key={key}>
      <span className="ErrorMessage__Field">{field}</span>{' '}
      <span className="ErrorMessage__Message">
        {[messages].flat().join(', ')}.
      </span>
    </p>
  );
};
