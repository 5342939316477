import React from 'react';

export default function FooteLinks() {
  return (
    <span className="FullPageForm__Footer--right">
      <a href="https://stagepilot.com/legal/privacy" target="_blank">
        Privacy Policy
      </a>
      {' | '}
      <a href="https://www.stagepilot.com/legal/terms-of-use" target="_blank">
        Terms and Conditions
      </a>
    </span>
  );
}
