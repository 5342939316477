import React from 'react';
import classNames from 'classnames';
import emptyIcon from 'images/icons/dashboard-empty.svg';
import './style.scss';

const DashboardEmptyCard = ({ className = '' }) => {
  const classnames = classNames('DashboardEmptyCard', className);

  return (
    <div className={classnames}>
      <img className="DashboardEmptyCard__Icon" src={emptyIcon} alt="Empty!" />
      <div className="DashboardEmptyCard__Message">
        Nothing to report in
        <br />
        this date range
      </div>
    </div>
  );
};

export default DashboardEmptyCard;
