export interface Event {
  id?: number;
  name: string;
  locator: string;
  userId?: number;
  searchable?: boolean;
  description: string;
  askEmail?: boolean;
  privacyPolicyUrl?: string;
  termsOfServiceUrl?: string;
  offerDiscount?: boolean;
  offerProducts?: boolean;
  showProductsList?: boolean;
  showFutureSchedule?: boolean;
  faceDetectionEnabled?: boolean;
}

export const newEvent = (): Event => {
  return {
    name: '',
    description: '',
    locator: '',
    privacyPolicyUrl: '',
    termsOfServiceUrl: '',
    askEmail: true,
    offerDiscount: false,
    offerProducts: false,
    showProductsList: false,
    showFutureSchedule: false,
    faceDetectionEnabled: false,
  };
};
