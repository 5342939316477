import classNames from 'classnames';
import React from 'react';

interface FilePickerProps {
  button?: any;
  caption?: string;
  multiple?: boolean;
  onPick?: (files: any) => void;
}
const FilePicker: React.FC<FilePickerProps> = ({
  caption,
  onPick,
  button,
  multiple = false,
}: FilePickerProps) => (
  <label className={classNames('FilePicker', { 'FilePicker--button': button })}>
    {caption}
    <input
      type="file"
      multiple={multiple}
      style={{ display: 'none' }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onPick?.(e.target.files);
        e.target.value = '';
      }}
    />
  </label>
);

export default FilePicker;
